import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { FilterType } from '../../contexts/FilterContext'
import { Filter } from '../../types'
import Tag from '../Tag'

type Props = {
  filter: Filter<FilterType>
  onPress: (id: string) => void
}

const FilterBadge = ({ filter, onPress }: Props) => {
  const { i18n } = useLanguage()

  const noFilter = () => (
    <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeRegular - 1 }}>
      {i18n.t('NO_TICKET_FILTER')}
    </IM.Text>
  )

  return (
    <IM.View key={filter.id}>
      <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular - 1 }}>{filter.name}</IM.Text>
      <IM.View style={styles.filterContainer}>
        {filter.elements.length === 0 ? (
          <>{noFilter()}</>
        ) : (
          filter.elements.map(el => {
            return <Tag key={el.id} id={el.id} name={el.id} active={el.active} style={styles.tag} onPress={onPress} />
          })
        )}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  filterContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 10,
  },
  tag: { marginVertical: 3 },
})

export default FilterBadge
