import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Button, StyleSheet } from 'react-native'

import './NotFoundScreen.scss'

import { useNavigation } from '@react-navigation/native'

import LogoDark from '../../assets/img/Logo_Infominds_dark.svg'
import LogoLight from '../../assets/img/Logo_Infominds_light.svg'
import { ThemeColorExpanded } from '../../types'

export default function NotFoundScreen() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const originalWidth = 633
  const originalHeight = 309

  return (
    <IM.View style={[styles.container, { backgroundColor: theme.background.default }]}>
      <IM.View style={styles.logoContainer}>
        {colorScheme === 'dark' ? (
          // @ts-ignore okk
          <LogoDark width="100%" height="100%" viewBox={`0 0 ${originalWidth} ${originalHeight}`} />
        ) : (
          // @ts-ignore okk
          <LogoLight width="100%" height="100%" viewBox={`0 0 ${originalWidth} ${originalHeight}`} />
        )}
      </IM.View>
      <IM.View style={styles.textContainer}>
        <IM.Text style={styles.title}>{i18n.t('PAGE_NOT_FOUND')}</IM.Text>
        <IM.View style={styles.descriptionContainer}>
          <IM.Text style={styles.description}>{i18n.t('PAGE_NOT_FOUND_DESCRIPTION')}</IM.Text>
        </IM.View>
      </IM.View>
      <IM.View style={styles.buttonContainer}>
        <Button
          title="Home"
          color="#343b47"
          onPress={() => navigation.navigate('BottomTab', { screen: 'TicketsStack', params: { screen: 'Tickets' } })}
        />
        <Button title="Go Back" color={theme.general.info} onPress={() => history.back()} />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  base: {
    width: 200,
    aspectRatio: 2.055,
  },
  buttonContainer: {
    marginTop: 40,
    flexDirection: 'row',
    // @ts-ignore: ok on web
    width: '30vw',
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    fontSize: 17,
  },
  descriptionContainer: {
    marginTop: 8,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  logoContainer: { alignItems: 'center', justifyContent: 'center', paddingBottom: 14 },
  textContainer: {
    minWidth: 440,
    width: '70%',
    alignItems: 'center',
    marginTop: 24,
    textAlign: 'center',
  },
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
    fontSize: 24,
  },
})
