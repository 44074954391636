import { IM, StringUtils, useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import isEqual from 'lodash/isEqual'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import DeleteButton from '../../components/DeleteButton'
import useRequest from '../../components/Infominds/hooks/useRequest'
import BooleanInput from '../../components/input/BooleanInput'
import DateInput, { convertDateToInputFormat } from '../../components/input/DateInput'
import EmailInputGroup from '../../components/input/EmailInputGroup'
import PhoneInputGroup from '../../components/input/PhoneInputGroup'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import GenderSelector from '../../components/selectors/GenderSelector'
import LanguageSelector from '../../components/selectors/LanguageSelector'
import { EDIT_CONTACT_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import useForm from '../../hooks/useForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, Gender, UploadStatus } from '../../types'
import appUtils from '../../utils/appUtils'

type Edit = {
  mode: 'edit'
  contact: Contact
  addressId?: never
}

type Creation = {
  mode: 'creation'
  contact?: never
  addressId: string
}

type Props = (Edit | Creation) & EditOrCreateViewProps

const ContactEditOrCreateView = ({ mode, contact, addressId, disabled, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { request: create, loading: loadingCreate } = useRequest(api.createCustomerContact)
  const { request: edit, loading: loadingEdit } = useRequest(api.editCustomerContact)
  const { request: deleteContact, loading: deleting } = useRequest(api.deleteCustomerContact)

  const initialData = useRef(contact)
  const equalData = useRef(false)

  const alert = useAlert()
  const { error } = useForm()
  const { i18n, language } = useLanguage()

  const [state, setState] = useState<Contact | undefined>(contact)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')
  const [deleteRequested, setDeleteRequested] = useState(false)

  const refreshContact = useEvent({ key: EDIT_CONTACT_DATA_EVENT_KEY })

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    let equal = error

    if (!error) {
      equalData.current = isEqual(initialData.current, state)

      if (mode === 'edit') {
        equal = equalData.current
      } else {
        equal = state === undefined || JSON.stringify(state) === '{}'
      }

      if (equal === false && state?.firstname === undefined && state?.surname === undefined) {
        updateUploadStatus('mandatoryMissing')
        return
      }
    }

    if (!equal) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, error])

  useEffect(() => {
    if (mode === 'edit' ? loadingEdit === 'catched' || deleting === 'catched' : loadingCreate === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = mode === 'edit' ? loadingEdit === false && deleting === false : loadingCreate === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      refreshContact.emit()
    }
  }, [loadingCreate, loadingEdit, deleting, deleteRequested])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const handleDeleteContact = () => {
    alert.alert(i18n.t('DELETE_CONTACT'), StringUtils.stringValueReplacer(i18n.t('DELETE_DESCRIPTION'), getDescription(initialData.current)), [
      {
        text: i18n.t('DELETE'),
        onPress: () => {
          setDeleteRequested(true)
          updateUploadStatus('uploading')
          // @ts-ignore improve data provider ts
          deleteContact({ id: initialData.current.id })
        },
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        onPress: () => {
          return
        },
        style: 'cancel',
      },
    ])
  }

  const getDescription = (element: Contact | undefined) => {
    if (!element) return ''

    let toRet = element.title ?? ''
    const name = element.firstname ?? ''
    const surname = element.surname ?? ''

    if (toRet.length !== 0) {
      toRet += ' ' + name
    } else {
      toRet = name
    }

    if (toRet.length !== 0) {
      toRet += ' ' + surname
    } else {
      toRet = surname
    }

    return toRet
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    const description = getDescription(state)

    if (mode === 'edit') {
      edit({
        ...state,
        description: description,
      })
    } else {
      create({
        ...state,
        addressId: addressId,
        inactive: Object.keys(state).find(key => key === 'inactive') === undefined ? false : true,
        gender: Object.keys(state).find(key => key === 'gender') === undefined ? Gender.None : state.gender,
        description: description,
      })
    }
  }

  const handleChangeText = (newVal: Partial<Contact>) => {
    if (waitingUpload === 'uploading') return

    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const dateValue = useMemo(() => (state?.dateofbirth ? convertDateToInputFormat(new Date(state.dateofbirth), language) : ''), [state, language])

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: !disabled,
  }

  return (
    <ScrollViewForm>
      {((disabled && state?.title !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('CONTACT_TITLE')}
          value={state?.title ?? ''}
          onChangeText={text => handleChangeText({ title: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      {((disabled && state?.firstname !== undefined) || !disabled) && (
        <TextInput
          title={state?.firstname === undefined && state?.surname === undefined ? i18n.t('NAME') + ' *' : i18n.t('NAME')}
          value={state?.firstname ?? ''}
          onChangeText={text => handleChangeText({ firstname: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      {((disabled && state?.surname !== undefined) || !disabled) && (
        <TextInput
          title={state?.surname === undefined && state?.firstname === undefined ? i18n.t('SURNAME') + ' *' : i18n.t('SURNAME')}
          value={state?.surname ?? ''}
          onChangeText={text => handleChangeText({ surname: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      {((disabled && state?.department !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('SECTOR')}
          value={state?.department ?? ''}
          onChangeText={text => handleChangeText({ department: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      {((disabled && state?.position !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('LOCATION')}
          value={state?.position ?? ''}
          onChangeText={text => handleChangeText({ position: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      <PhoneInputGroup
        title={i18n.t('PHONE')}
        addValueText={i18n.t('ADD_PHONE_NUMBER')}
        values={state?.phone}
        handleChange={newVal => handleChangeText({ phone: newVal })}
        editable={!disabled}
        disableFastAction={state?.inactive}
        onActionPress={
          disabled
            ? id => {
                const selected = state?.phone?.at(id)
                selected && appUtils.openPhone(selected.number, alert, i18n)
              }
            : undefined
        }
        getTypeRequest={api.getPhoneTypes}
      />
      <EmailInputGroup
        title={i18n.t('EMAIL')}
        addValueText={i18n.t('ADD_EMAIL_ADDRESS')}
        values={state?.email}
        handleChange={newVal => handleChangeText({ email: newVal })}
        editable={!disabled}
        disableFastAction={state?.inactive}
        onActionPress={
          disabled
            ? id => {
                const selected = state?.email?.at(id)
                selected && appUtils.openEmail(alert, i18n.t('OPEN_EMAIL_ERROR'), selected.address)
              }
            : undefined
        }
        getTypeRequest={api.getEmailTypes}
      />
      <PhoneInputGroup
        title={i18n.t('FAX')}
        addValueText={i18n.t('ADD_FAX_NUMBER')}
        values={state?.fax}
        handleChange={newVal => handleChangeText({ fax: newVal })}
        editable={!disabled}
        getTypeRequest={api.getFaxTypes}
      />
      {((disabled && state?.dateofbirth !== undefined) || !disabled) && (
        <DateInput
          title={i18n.t('BIRTHDAY')}
          value={dateValue}
          onChangeDate={date =>
            // Some weird issue in the comparison when we set {dateofbirth: undefined} in an object which does not have that key
            !(state?.dateofbirth === undefined && date === undefined) && handleChangeText({ dateofbirth: date ? date.toISOString() : undefined })
          }
          maximumDate={new Date()}
          {...commonProps}
        />
      )}
      {((disabled && state?.gender !== Gender.None) || !disabled) && (
        <GenderSelector
          value={state?.gender}
          onChange={gender => handleChangeText({ gender: gender === Gender.None ? undefined : gender })}
          {...commonProps}
        />
      )}
      {((disabled && state?.language !== undefined) || !disabled) && (
        <LanguageSelector
          id={state?.language}
          onChange={text => handleChangeText({ language: text === undefined ? undefined : text.id })}
          {...commonProps}
        />
      )}
      <BooleanInput
        title={i18n.t('INACTIVE')}
        value={state?.inactive ?? false}
        onValueChange={() => handleChangeText({ inactive: !state?.inactive })}
        disabled={disabled}
        spacing={commonProps.spacing}
      />
      {mode === 'edit' && !disabled && (
        <IM.View {...commonProps}>
          <DeleteButton title={i18n.t('DELETE_CONTACT')} onPress={handleDeleteContact} />
        </IM.View>
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(ContactEditOrCreateView)
