import { IM } from '@infominds/react-native-components'
import React from 'react'

import useLayout from '../../hooks/useLayout'

interface Props {
  size?: 'small' | 'big'
}

export default function SkeletonCard({ size = 'big' }: Props) {
  const { isSmallDevice } = useLayout()

  return (
    <IM.Skeleton.Container height={size === 'big' ? 74 : 60} width="100%">
      <IM.Skeleton.Rect x="0" y="0" rx="7" ry="7" width="64" height={size === 'big' ? 74 : 60} />
      <IM.Skeleton.Rect x="84" y="14" rx="5" ry="5" width={isSmallDevice ? '30%' : '10%'} height="12" />
      <IM.Skeleton.Rect x="84" y="33" rx="5" ry="5" width={isSmallDevice ? '50%' : '18%'} height="12" />
      {size === 'big' && <IM.Skeleton.Rect x="84" y="51" rx="5" ry="5" width={isSmallDevice ? '65%' : '30%'} height="12" />}
    </IM.Skeleton.Container>
  )
}
