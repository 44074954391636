import { IM, IMLayout, IMStyle, SpacingProps, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { ActivityIndicator, Keyboard, Platform, StyleSheet } from 'react-native'

import CONSTANTS from '../../../constants/Constants'
import { TEXT_INPUT_POP_UP_CLOSE_EVENT_KEY } from '../../../constants/EmitterKeys'
import { LoadingType, ThemeColorExpanded } from '../../../types'
import { utils } from '../../../utils/utils'
import Pressable from '../../Infominds/Pressable'
import { BaseTextInput, BaseTextInputProps } from '../../input/baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from '../../input/baseTextInput/contexts/BaseTextInputContext'
import MultiSelectionItem from '../../MultiSelectionItem'
import ScrollViewData from '../../ScrollViewData'
import Text from '../../Text'

export type BaseInputSelectorProps<T> = Omit<BaseTextInputProps, 'onChangeText'> &
  BaseTextInputProviderProps & {
    spacing?: SpacingProps
    title?: string
    disableApiSearch?: boolean
    popUpHeight?: number
    popUpPosition?: 'top' | 'bottom'
    alreadyPresentMessage?: string
    searchResult?: T[]
    searchLoading?: LoadingType
    maxItems?: number
    popUpOffset?: number
    renderSearchItem?: (item: T, isFirst: boolean, onPressCallback: (id: string, value: string) => void) => ReactNode
    onSearchStart?: (text: string | undefined) => void
    onResetSearch?: () => void
    onTestInput?: (input: string) => boolean
    onDataEntered?: (data: string[]) => void
    onPress?: () => void
  }

export default function BaseInputSelector<T extends { id: string }>({
  title,
  editable = true,
  error,
  disableFocus,
  loading,
  spacing,
  popUpHeight = 200,
  searchLoading,
  searchResult,
  disableApiSearch,
  alreadyPresentMessage,
  maxItems,
  popUpPosition = 'bottom',
  popUpOffset = 0,
  ...textInputProps
}: BaseInputSelectorProps<T>) {
  const { onDataEntered, onTestInput, onSearchStart, renderSearchItem, onResetSearch } = textInputProps

  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const componentId = useRef(utils.generateUuid())

  const [isValid, setIsValid] = useState(false)
  const [popUpOpen, setPopUpOpen] = useState(false)
  const [text, setText] = useState<string | undefined>(undefined)
  const [width, setWidth] = useState<number | undefined>(undefined)
  const [height, setHeight] = useState<number | undefined>(undefined)
  const [isAlreadyPresent, setAlreadyPreset] = useState(false)
  const [enteredData, setEnteredData] = useState<{ id: string; value: string }[]>(
    textInputProps.value ? [{ id: textInputProps.value, value: textInputProps.value }] : []
  )
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined)

  const { emit } = useEvent<string | undefined>({ key: TEXT_INPUT_POP_UP_CLOSE_EVENT_KEY }, id => {
    if (id === undefined || id !== componentId.current) {
      setPopUpOpen(false)
    }
  })

  useEffect(() => {
    if (!editable) setPopUpOpen(false)
  }, [editable])

  useEffect(() => {
    if (!popUpOpen) {
      editable === false && setText(undefined)
      onSearchStart?.(undefined)
      return
    }

    const id = setTimeout(() => {
      onSearchStart?.(text)
    }, CONSTANTS.searchDeferredTimeout)

    timerRef.current = id

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [popUpOpen, text])

  useEffect(() => {
    if (disableApiSearch !== true) {
      if (text) {
        handleOpen()
      } else {
        onResetSearch?.()
      }
    }

    if (onTestInput === undefined) {
      setIsValid(text !== undefined)
    } else {
      if (text && onTestInput?.(text)) {
        if (enteredData.find(data => data.id === text) === undefined) {
          setIsValid(true)
        } else {
          setAlreadyPreset(true)
        }
      } else {
        setIsValid(false)
        setAlreadyPreset(false)
      }
    }
  }, [text, enteredData])

  useEffect(() => {
    const toRet: string[] = []

    enteredData.forEach(data => toRet.push(data.id))

    onDataEntered?.(toRet)
  }, [enteredData])

  const handleOpen = () => {
    emit(componentId.current)
    setPopUpOpen(true)
  }

  const handleRemove = (idToRemove: string) => setEnteredData(prev => prev.filter(el => el.id !== idToRemove))

  const handlePress = (id: string, value: string) => {
    setText(undefined)
    setPopUpOpen(false)
    Keyboard.dismiss()

    const clone = cloneDeep(enteredData)
    const found = clone.filter(el => el.id === id)

    if (found.length === 0) {
      setEnteredData([...clone, { id, value }])
    }
  }

  return (
    <IM.View spacing={spacing}>
      {title && <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{title}</IM.Text>}
      <IM.View style={[IMLayout.flex.row, styles.chipContainer]}>
        {enteredData.map(data => (
          <MultiSelectionItem key={data.id} item={data} value={data.value} onRemove={({ id }) => handleRemove(id)} disabled={!editable} />
        ))}
      </IM.View>
      {(maxItems === undefined || enteredData.length < maxItems) && (
        <BaseTextInputProvider
          editable={editable}
          error={error || isAlreadyPresent}
          loading={loading}
          disableFocus={disableFocus}
          onBlur={() => Platform.OS !== 'web' && Platform.OS !== 'android' && emit(undefined)}
          onFocus={() => {
            onSearchStart?.(undefined)
            handleOpen()
          }}>
          <BaseTextInput
            {...textInputProps}
            disableBorderRadius={popUpOpen ? popUpPosition : undefined}
            value={text}
            onChangeText={setText}
            onWidth={setWidth}
            onHeight={setHeight}>
            <BaseTextInput.RightIcon disableBorder={popUpOpen ? popUpPosition : undefined}>
              <Pressable disabled={!editable || !isValid || isAlreadyPresent} onPress={() => text && handlePress(text, text)}>
                <IM.Text style={{ color: editable && isValid && !isAlreadyPresent ? theme.text.detail : theme.button.disabledBackground }}>
                  {i18n.t('ADD')}
                </IM.Text>
              </Pressable>
            </BaseTextInput.RightIcon>
          </BaseTextInput>
        </BaseTextInputProvider>
      )}
      {popUpOpen && (
        <IM.View
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            backgroundColor: theme.inputBox.background.active,
            width,
            height: popUpHeight,
            borderBottomLeftRadius: popUpPosition === 'bottom' ? IMLayout.borderRadius : undefined,
            borderBottomRightRadius: popUpPosition === 'bottom' ? IMLayout.borderRadius : undefined,
            borderTopLeftRadius: popUpPosition === 'top' ? IMLayout.borderRadius : undefined,
            borderTopRightRadius: popUpPosition === 'top' ? IMLayout.borderRadius : undefined,
            position: 'absolute',
            bottom: popUpPosition === 'bottom' ? -popUpHeight + (Platform.OS === 'web' ? 0 : 9) - popUpOffset : (height ?? 0) - 2 + popUpOffset,
            borderWidth: 1,
            borderColor: theme.general.info,
            borderTopColor: popUpPosition === 'bottom' ? theme.inputBox.border.active : undefined,
            borderBottomColor: popUpPosition === 'top' ? theme.inputBox.border.active : undefined,
            zIndex: 1000,
          }}>
          <ScrollViewData
            nestedScrollEnabled
            contentContainerStyle={styles.popUpScrollView}
            disableHideKeyboardOnScroll
            keyboardShouldPersistTaps="always">
            {searchLoading === false && searchResult !== undefined ? (
              <>
                {searchResult?.length === 0 && (
                  <IM.View spacing={['horizontal', 'top']}>
                    <Text secondary>{i18n.t('NO_RESULT_FOUND')}</Text>
                  </IM.View>
                )}
                {searchResult?.map((item, index) => renderSearchItem?.(item, index === 0, handlePress))}
              </>
            ) : (
              <IM.View style={{ marginTop: IMLayout.verticalMargin }}>
                <ActivityIndicator color={theme.general.info} />
              </IM.View>
            )}
          </ScrollViewData>
        </IM.View>
      )}
      {isAlreadyPresent && alreadyPresentMessage && <Text style={[{ color: theme.general.error }, styles.error]}>{alreadyPresentMessage}</Text>}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  chipContainer: {
    flexWrap: 'wrap',
    marginBottom: 3,
  },
  error: {
    marginTop: 2,
  },
  noChipContainer: {
    marginVertical: 3,
  },
  popUpScrollView: {
    padding: 0,
  },
})
