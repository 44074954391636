import { IM, IMLayout, IMStyle, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import DynamicView from '../../../components/Infominds/DynamicView'
import TextWithIcon from '../../../components/Infominds/TextWithIcon'
import Text from '../../../components/Text'
import TimeUtils from '../../../utils/TimeUtils'
import TicketIcon from '../../ticketList/components/TicketIcon'

interface Props {
  type: 'planning' | 'other'
  activity: Activity
  style?: StyleProp<ViewStyle>
  forceLayout?: false | 'small' | 'medium' | 'large'
}

export default function ActivityListCardTitle({ type, activity, style, forceLayout }: Props) {
  const { language } = useLanguage()
  const { theme } = useTheme()
  const { isSmallDevice } = useDimensions()

  const planDate = useMemo(() => (activity.planDate ? TimeUtils.format(activity.planDate, language) : undefined), [activity, language])
  const startTime = useMemo(() => (activity.planStartTime ? TimeUtils.formatSeconds(activity.planStartTime, undefined, true) : undefined), [activity])

  return (
    <DynamicView separatorVertical={forceLayout === 'small' ? false : !isSmallDevice} style={style} forceLayout={forceLayout}>
      <TicketIcon activityType={activity.activityTypeType} spacing="right" />
      <IM.View style={IMLayout.flex.f1}>
        <Text>{activity.code}</Text>
        <Text style={{ fontSize: IMStyle.typography.fontSizeRegular - 2 }}>{activity.ticketCode}</Text>
        <TextWithIcon icon={['fal', 'calendar-plus']} iconColor={theme.text.detail} style={{ marginTop: 0.5 * IMLayout.verticalMargin }}>
          {TimeUtils.format(activity.date, language)}
        </TextWithIcon>
        {type === 'planning' && (planDate !== undefined || startTime !== undefined) && (
          <TextWithIcon spacing="top" icon={['fal', 'calendar-range']} iconColor={theme.text.detail}>
            {`${planDate ? planDate : ''}${startTime ? '\n' : ''}${startTime ? startTime : ''}`}
          </TextWithIcon>
        )}
      </IM.View>
    </DynamicView>
  )
}
