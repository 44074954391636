import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import ActivityReportCard from '../../../cards/activity/ActivityReportCard'
import ActivitySignatureCard from '../../../cards/activity/ActivitySignatureCard'
import TextWithIcon from '../../../components/Infominds/TextWithIcon'
import { ScreenCardsHeaderShadows } from '../../../components/screen/constants/constants'
import Text from '../../../components/Text'
import { closeActivityStyles } from '../../../constants/Constants'
import useCloseActivity from '../../../hooks/useCloseActivity'
import useReport from '../../../hooks/useReport'
import useSignature from '../../../hooks/useSignature'
import { ThemeColorExpanded } from '../../../types'
import { CloseActivityViewProps } from '../../activity/ActivityCloseView'

const TicketCloseActivityStep4View = memo(function TicketCloseActivityStep4View({ ...others }: CloseActivityViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { closeType } = useCloseActivity()
  const { initialContact, changeContact, setSignature } = useSignature()
  const { enabled: enabledReport, setEnabled: setEnabledReport, initial, setValue } = useReport()

  return (
    <>
      <IM.View spacing="top" />
      <IM.View
        style={[
          closeActivityStyles.header,
          ScreenCardsHeaderShadows,
          styles.container,
          {
            backgroundColor: theme.closing.fourth,
          },
        ]}>
        <Text style={[{ fontSize: IMStyle.typography.fontSizeMedium }, styles.text]}>{i18n.t('STEP_4')}</Text>
        {closeType === undefined ? (
          <TextWithIcon icon={['fas', 'circle-exclamation']} color={theme.general.error} viewStyle={styles.error} spacing="bottom">
            {i18n.t('COMPLETE_STEP_1')}
          </TextWithIcon>
        ) : (
          <>
            <Text secondary style={styles.text}>
              {i18n.t('STEP_4_DESCRIPTION')}
            </Text>
            <IM.View spacing="vertical">
              <ActivityReportCard
                activityId={others.activityId}
                spacing="bottom"
                value={enabledReport}
                onValueChange={() => setEnabledReport(prev => !prev)}
                editable={enabledReport}
                initialEmail={initial}
                onEmailEntered={setValue}
              />
              {Platform.OS !== 'web' && (
                <IM.View style={styles.signatureCardContainer}>
                  <ActivitySignatureCard
                    spacing="bottom"
                    initialContactName={initialContact?.id}
                    addressId={others.addressId}
                    handleChangeContact={id => changeContact(id ? { id } : undefined)}
                    handleChangeSignature={setSignature}
                  />
                </IM.View>
              )}
            </IM.View>
          </>
        )}
      </IM.View>
    </>
  )
})

export default TicketCloseActivityStep4View

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      native: {
        height: '100%',
      },
      web: {
        flexGrow: 1,
      },
    }),
  },
  error: {
    marginTop: 3,
  },
  signatureCardContainer: {
    zIndex: -1,
  },
  text: { zIndex: -2 },
})
