import { IM, IMLayout, useAlert, useDimensions, useEvent, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import groupBy from 'lodash/groupBy'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Animated, SectionListRenderItemInfo, StyleSheet } from 'react-native'
import { useRecoilValue } from 'recoil'

import api from '../../apis/apiCalls'
import { ActivitySparePart } from '../../apis/types/apiResponseTypes'
import ArticleSparePartShoppingCartCard from '../../cards/activity/ArticleSparePartShoppingCartCard'
import DoneButton from '../../components/DoneButton'
import SectionList from '../../components/SectionList'
import SkeletonCard from '../../components/skeleton/SkeletonCard'
import Text from '../../components/Text'
import { ACTIVITY_SPARE_PARTS_DONE_BUTTON_ID, ACTIVITY_SPARE_PARTS_REMOVAL_DONE_BUTTON_ID } from '../../constants/ButtonIds'
import { EDIT_ACTIVITY_SPARE_PARTS_EVENT_KEY } from '../../constants/EmitterKeys'
import { FormProvider } from '../../contexts/FormContext'
import useUserSettings from '../../hooks/useUserSettings'
import { ListSection, PartDto, SparePartDirection, SparePartShoppingCartRef } from '../../types'
import appUtils from '../../utils/appUtils'
import { invoiceTypeAtom, ticketArticleAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'

const buttonAnimationValue = new Animated.Value(0)

interface Props {
  parts: PartDto[] | undefined
  activityId: string
  direction: SparePartDirection
  onUploadDone?: (value: boolean) => void
}

const ActivitySparePartsShoppingCartView = forwardRef(
  ({ parts, activityId, direction, onUploadDone }: Props, ref: ForwardedRef<SparePartShoppingCartRef>) => {
    useImperativeHandle(ref, () => ({
      upload: () => handleUpload(),
    }))

    const { alert } = useAlert()
    const { i18n } = useLanguage()
    const { userSettings } = useUserSettings()
    const navigation = useNavigation()
    const { isSmallDevice } = useDimensions()

    const invoices = useRecoilValue(invoiceTypeAtom)
    const articles = useRecoilValue(ticketArticleAtom)

    const [loading, setLoading] = useState(false)

    const { emit } = useEvent({ key: EDIT_ACTIVITY_SPARE_PARTS_EVENT_KEY })

    useEffect(() => {
      // Some client has experiences double requests, therefore we try this way
      loading && handleUpload(true)
    }, [loading])

    const handleUpload = (local = false) => {
      if (!parts) return onUploadDone?.(true)

      const requests: unknown[] = []

      parts.forEach(part => {
        const toUpdate = {
          direction: part.direction,
          id: part.id,
          activityId,
          articleId: part.articleId,
          serialnumberId: part.serialnumberId,
          invoicingTypeId: part.invoicingTypeId,
          serialnumberIdParent: part.serialnumberIdParent,
          date: part.date,
          price: part.pickedPrice === part.price ? undefined : part.pickedPrice,
          quantity: part.quantity,
          description: part.newDescription ? part.newDescription : part.description,
          depositId: part.depositId,
        }

        if (part.status === 'edited') {
          requests.push(api.editActivitySpareParts(toUpdate as unknown as ActivitySparePart))
        } else if (part.status === 'deleted') {
          requests.push(api.deleteActivitySpareParts({ id: part.id }))
        } else if (part.status === 'new' || part.isDummy) {
          if (part.isDummy) {
            requests.push(
              api.createActivitySpareParts({
                ...toUpdate,
                id: undefined,
                articleId: undefined,
                serialnumber: part.serialnumber,
                articlecode: part.articleCode,
                depositId: userSettings?.depositId,
              } as unknown as Omit<ActivitySparePart, 'id'>)
            )
          } else {
            requests.push(api.createActivitySpareParts(toUpdate as unknown as Omit<ActivitySparePart, 'id'>))
          }
        }
      })

      Promise.all(requests)
        .catch(err => alert(i18n.t('API_CATCH_TITLE'), appUtils.getBackendErrorMessage(err)))
        .finally(() => {
          emit()
          onUploadDone?.(true)
          if (local) {
            navigation.goBack()
            setTimeout(() => setLoading(false), 250)
          }
        })
    }

    const renderItem = ({ item }: SectionListRenderItemInfo<PartDto, ListSection<PartDto>>) => {
      if (!invoices || !articles) return <></>

      return (
        <ArticleSparePartShoppingCartCard
          dto={item}
          spacing={['horizontal', 'bottom']}
          invoiceTypes={invoices}
          ticketArticles={articles}
          activityId={activityId}
          direction={direction}
        />
      )
    }

    const identifier = (elem: PartDto) =>
      elem.id +
      elem.articleId +
      elem.articleSearchtext +
      (elem.quantity ?? 0).toString() +
      (elem.price ?? 0).toString() +
      (elem.pickedPrice ?? 0).toString() +
      elem.date +
      (elem.serialnumber ?? '') +
      (elem.isDummy ? 'dummy' : '') +
      (elem.invoicingTypeId ?? '')

    const loadingList = articles === undefined || invoices === undefined ? 'reloading' : false

    const data: ListSection<PartDto>[] = useMemo(() => {
      const displayData: ListSection<PartDto>[] = []

      if (parts?.length && loadingList === false) {
        const groups = groupBy(parts, part => part.serialnumberParent)

        Object.keys(groups).forEach(key => {
          if (groups[key]) {
            displayData.push({
              title:
                key === 'undefined'
                  ? i18n.t('NO_TICKET_ARTICLE')
                  : ticketUtils.getArticleSn({
                      articleSearchtext: groups[key][0].articleSearchtextParent ?? '',
                      serialnumber: groups[key][0].serialnumberParent,
                      serialnumberNumberManufactor: groups[key][0].serialnumberNumberManufactorParent,
                    }),
              data: groups[key],
            })
          }
        })
      }

      return displayData.sort((a, b) => (a.title && b.title ? a.title?.localeCompare(b.title) : 0))
    }, [parts, loadingList])

    const marginVertical = isSmallDevice ? IMLayout.verticalMargin : 0

    return (
      <>
        <FormProvider>
          {!isSmallDevice && (
            <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: 2 * IMLayout.horizontalMargin }}>
              <Text style={styles.title}>{i18n.t('SHOPPING_CART')}</Text>
            </IM.View>
          )}
          <SectionList
            sections={data}
            loading={loadingList}
            noDataIcon={['fal', direction === 'Installation' ? 'cart-plus' : 'cart-minus']}
            noDataMessage={i18n.t('EMPTY_SHOPPING_CART')}
            renderItem={renderItem}
            closeEndThreshold={90}
            hideButtonId={direction === 'Installation' ? ACTIVITY_SPARE_PARTS_DONE_BUTTON_ID : ACTIVITY_SPARE_PARTS_REMOVAL_DONE_BUTTON_ID}
            keyExtractor={identifier}
            contentContainerStyle={[styles.list, { marginVertical }]}
            skeletonComponent={<SkeletonCard />}
            skeletonTopSpacing
            keyboardShouldPersistTaps="never"
          />
        </FormProvider>
        {!isSmallDevice && (
          <DoneButton
            id={direction === 'Installation' ? ACTIVITY_SPARE_PARTS_DONE_BUTTON_ID : ACTIVITY_SPARE_PARTS_REMOVAL_DONE_BUTTON_ID}
            value={buttonAnimationValue}
            disabled={loadingList !== false || loading || (parts && !ticketUtils.isShoppingCartEdited(parts))}
            forceShow={parts && ticketUtils.isShoppingCartEdited(parts)}
            loading={loadingList !== false || loading ? 'reloading' : false}
            onPress={() => setLoading(true)}
          />
        )}
      </>
    )
  }
)

export default memo(ActivitySparePartsShoppingCartView)

const styles = StyleSheet.create({
  list: { marginHorizontal: IMLayout.horizontalMargin, paddingBottom: 2 * IMLayout.horizontalMargin },
  title: { fontSize: 18, fontWeight: '500' },
})
