import { IM } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { Animated, StyleSheet } from 'react-native'

import { Ticket } from '../../apis/types/apiResponseTypes'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import MapMarker from '../../components/map/components/MapMarker'
import Map from '../../components/map/Map'
import TicketCarousel from '../../components/TicketCarousel'
import TravelTimeButton from '../../components/TravelTimeButton'
import CONSTANTS from '../../constants/Constants'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useLayout from '../../hooks/useLayout'
import { TicketStackParamList } from '../../navigation/types'
import { LoadingType } from '../../types'
import TicketListMap from './TicketListMap'

type Props = {
  tickets: (string | Ticket)[]
  loading: LoadingType
  buttonAnimationValue: Animated.Value
  reloadTickets: () => void
  hideAddButton: (hide: boolean) => void
  onCreate?: () => void
}

const HEIGHT = 242

export default function TicketListMapView({ tickets, loading, buttonAnimationValue, reloadTickets, onCreate }: Props) {
  const { isSmallDevice } = useLayout()
  const { isOnline } = useDataProvider()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const ticketsWithoutHeaders = useMemo(() => tickets.filter(ticket => typeof ticket !== 'string') as Ticket[], [tickets])

  const mapMarker = (item: Ticket, selected: boolean) => <MapMarker activityType={item.activityType} selected={selected} />

  return (
    <>
      <Map
        items={ticketsWithoutHeaders}
        mapMarkerRenderItem={mapMarker}
        centerButtonBottom={isSmallDevice ? HEIGHT : 10}
        mapPadding={
          isSmallDevice
            ? {
                bottom: HEIGHT,
                top: 0,
                left: 0,
                right: 0,
              }
            : undefined
        }
        onSelectedMarkerPressed={ticket => navigation.navigate('TicketDetail', { ticketId: ticket.id, ticketCode: ticket.code })}
        alignChildren={isSmallDevice ? 'bottom' : 'left'}
        mapRenderPopUp={item => {
          return item.code + '\n' + item.customer + (item.shippingAddress ? `\n${item.shippingAddress}` : '') + `\n${item.location}`
        }}>
        <TicketCarousel tickets={ticketsWithoutHeaders} height={HEIGHT} loading={loading} />
        {!isSmallDevice && (
          <IM.View style={styles.largeDeviceListView}>
            <TicketListMap
              tickets={tickets}
              loading={loading}
              reloadTickets={reloadTickets}
              showSmallDeviceCards
              buttonAnimationValue={buttonAnimationValue}
              onCreate={onCreate}
            />
          </IM.View>
        )}
      </Map>
      {isSmallDevice && loading === false && isOnline && (
        <>
          <AnimatedButton id="MapButton" value={buttonAnimationValue} icon={['fal', 'plus']} onPress={onCreate} />
          <TravelTimeButton id="MapButton" value={buttonAnimationValue} bottom={CONSTANTS.secondButtonBottom} />
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  largeDeviceListView: {
    width: '30%',
  },
})
