import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo, TextStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { AdditionalFieldDefinitionValue } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_ADDITIONAL_FIELDS_DEFINITION_VALUES } from '../../constants/Keys'
import { AddFieldValue, ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  definitionId: string
  title: string
  titleFontWeight?: TextStyle['fontWeight']
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: AddFieldValue) => void
}

export default function AddFieldSelector({ title, titleFontWeight, definitionId, id, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState(CONSTANTS.noSelectionId)
  const { item, loadItem, loading } = useControlledLoader(api.getAddFieldsDefinitionValues, {
    id: REQUEST_ADDITIONAL_FIELDS_DEFINITION_VALUES + definitionId,
  })

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    loading === false && item && item.forEach(el => el.code === id && setSelectedId(el.id))
  }, [loading, item])

  const noSelectionEntry: AdditionalFieldDefinitionValue = {
    id: CONSTANTS.noSelectionId,
    additionalfieldDefinitionId: '',
    code: '',
    description: i18n.t('NO_SELECTION'),
  }

  const refresh = () => loadItem({ additionalfieldDefinitionId: definitionId })

  const render = (
    { item: el }: SectionListRenderItemInfo<AdditionalFieldDefinitionValue, ListSection<AdditionalFieldDefinitionValue>>,
    onPress?: () => void
  ) => {
    return <RadioPressable onPress={() => onPress?.()} selected={el.id === selectedId} text={el.description} />
  }

  const handleOnChange = (newValue: AdditionalFieldDefinitionValue | undefined) => {
    setSelectedId(newValue?.id ?? CONSTANTS.noSelectionId)
    // Additional field POST api needs the selection code to work properly
    onChange({ definitionId: definitionId, value: newValue?.id === CONSTANTS.noSelectionId || newValue?.id === undefined ? '' : newValue?.code })
  }

  return (
    <SelectInput
      data={appUtils.filter(item ? [noSelectionEntry, ...item] : [noSelectionEntry], search, ['description'])}
      value={item?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={title}
      screenTitle={title}
      titleFontWeight={titleFontWeight}
      noDataMessage={i18n.t('NO_VALUE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={val => val.description}
      deferredTimeout={0}
      disableLoadAfterMount
      disableBackdropOpacity
      {...props}
    />
  )
}
