import { IM, useDimensions, useTheme } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleSheet } from 'react-native'

import HeaderIcon from './HeaderIcon'
import SearchIcon from './SearchIcon'
import { DetailHeaderType } from './types'

export interface DataManagementIconGroupProps {
  alignment?: 'left' | 'right'
  disableSearch?: boolean
  tabletSearchComponent?: ReactNode
  disableFilter?: boolean
  tabletFilterComponent?: ReactNode
  highlightFilter?: boolean
  extraIcon?: ReactNode
  disabled?: boolean
}

export default function DataManagementIconGroup({
  alignment = 'right',
  disableSearch,
  disableFilter,
  tabletSearchComponent,
  tabletFilterComponent,
  highlightFilter,
  extraIcon,
  disabled,
}: DataManagementIconGroupProps) {
  const { isSmallDevice } = useDimensions()
  const { theme } = useTheme()

  return (
    <IM.View style={styles.container}>
      {isSmallDevice ? (
        <>
          {extraIcon}
          {!disableSearch && <SearchIcon disabled={disabled} />}
          {!disableFilter && (
            <HeaderIcon
              icon={['fal', 'sliders']}
              size={19}
              type={DetailHeaderType.FILTER}
              highlight={highlightFilter}
              disabled={disabled}
              disabledColor={theme.text.detail}
            />
          )}
        </>
      ) : (
        <>
          {alignment === 'right' ? (
            <>
              {!disableFilter && tabletFilterComponent}
              {!disableSearch && tabletSearchComponent}
              {extraIcon}
            </>
          ) : (
            <>
              {!disableSearch && tabletSearchComponent}
              {!disableFilter && tabletFilterComponent}
              {extraIcon}
            </>
          )}
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },
})
