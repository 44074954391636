import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../types'

interface Props {
  iconSize?: number
}

export default function Offline({ iconSize = 26 }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  return (
    <>
      <IM.Icon icon={['fas', 'cloud-slash']} color={theme.general.warn} size={iconSize} />
      <IM.Text
        style={{
          fontSize: IMStyle.typography.fontSizeRegular - 2,
          marginTop: IMLayout.horizontalMargin / 2,
          fontWeight: IMStyle.typography.fontWeightMedium,
          color: theme.general.warn,
        }}>
        {i18n.t('NO_INTERNET')}
      </IM.Text>
    </>
  )
}
