import { IM, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../types'

export interface CardContentContainerProps {
  open?: boolean
  disableDivider?: boolean
}

// TODO fix. separator (first view) should only be visible if header exists and even then only if needed / wanted
export default function CardContentContainer({ children, open, disableDivider = false }: PropsWithChildren<CardContentContainerProps>) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const renderChild = () => {
    return (
      <>
        {!disableDivider && <IM.View style={[styles.height, { backgroundColor: theme.separator }]} spacing="vertical" spacingType="margin" />}
        {children}
      </>
    )
  }

  return <>{open === undefined ? renderChild() : <>{open && renderChild()}</>}</>
}

const styles = StyleSheet.create({
  height: {
    height: 1,
  },
})
