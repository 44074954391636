import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'
import Error from './Error'

interface Props {
  description: string
  error?: boolean
  errorMessage?: string
  icon?: IconProp
  iconSize?: number
  spacing?: SpacingProps
}

export default function NoEntry({ description, icon, error, errorMessage, spacing = 'all', iconSize = 26 }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View spacing={spacing} style={styles.noDocument}>
      {error ? (
        <Error message={errorMessage} style={styles.errorIcon} />
      ) : (
        <>
          <IM.Icon icon={icon ?? ['fal', 'file-slash']} color={theme.text.detail} size={iconSize} />
          <IM.Text secondary style={[styles.text, { fontSize: IMStyle.typography.fontSizeRegular - 2, marginTop: IMLayout.horizontalMargin / 2 }]}>
            {description}
          </IM.Text>
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  noDocument: {
    alignItems: 'center',
    flex: 1,
  },
  errorIcon: {
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
})
