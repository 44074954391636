import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import ReactModal from 'react-modal'
import { StyleSheet } from 'react-native'

import Pressable from '../components/Infominds/Pressable'
import PasswordInput from '../components/input/PasswordInput'
import LoadingIcon from '../components/LoadingIcon'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'
import { VaultModalBaseProps } from './VaultModal'

export default function VaultModalBase({ error, isShown, loading, password, pswInputRef, onClose, setPassword, checkPassword }: VaultModalBaseProps) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  return (
    <ReactModal
      isOpen={isShown}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'transparent',
        },
        content: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          background: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}`,
          border: 0,
          inset: '0px',
        },
      }}>
      <IM.View style={styles.centeredView}>
        <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme) }]}>
          <IM.View spacing="bottom">
            <IM.Text style={styles.title}>{i18n.t('VAULT_MANAGEMENT')}</IM.Text>
          </IM.View>
          <IM.View spacing="bottom">
            <IM.Text>{i18n.t('VAULT_INSERT_PASSWORD')}</IM.Text>
          </IM.View>
          <PasswordInput
            ref={pswInputRef}
            placeholder={i18n.t('MASTER_PASSWORD')}
            value={password}
            onChangeText={setPassword}
            spacing="bottom"
            error={error}
            disableFocus
            focusOnMount
            editable={!loading}
            onSubmitEditing={checkPassword}
          />
          <IM.View style={styles.buttonsContainer}>
            <Pressable spacing="right" onPress={onClose}>
              <IM.Text style={[styles.text, { color: loading ? theme.text.detail : theme.text.default }]}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
            </Pressable>
            <IM.View style={styles.okContainer}>
              {loading ? (
                <LoadingIcon />
              ) : (
                <Pressable style={styles.okButton} onPress={checkPassword} disabled={loading}>
                  <IM.Text style={[styles.text, { color: loading ? theme.text.detail : theme.text.default }]}>{i18n.t('OK').toUpperCase()}</IM.Text>
                </Pressable>
              )}
            </IM.View>
          </IM.View>
        </IM.View>
      </IM.View>
    </ReactModal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalView: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingTop: 28,
    paddingBottom: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '90%',
    maxWidth: 400,
  },
  okButton: {
    paddingHorizontal: 14,
  },
  okContainer: {
    width: 48,
    height: 34,
    justifyContent: 'center',
  },
  title: {
    fontSize: IMStyle.typography.fontSizeMedium + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avoidingView: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})
