import { IMStyle, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'

import { ThemeColorExpanded } from '../../../types'
import Text from '../../Text'

interface Props {
  message: string
}

const BaseTextInputError = memo(function BaseTextInputError({ message }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return <Text style={{ color: theme.general.error, fontSize: IMStyle.typography.fontSizeRegular - 2 }}>{message}</Text>
})

export default BaseTextInputError
