import { IM, IMLayout, useOrientation, useTheme } from '@infominds/react-native-components'
import React, { Fragment, PropsWithChildren, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, Pressable, StyleProp, ViewStyle } from 'react-native'
import MapView, { EdgePadding, LatLng, Marker } from 'react-native-maps'

import { useDevicePosition } from '../../contexts/GeoLocationContext'
import { ThemeColorExpanded } from '../../types'
import GeoLocationUtils from '../../utils/GeoLocationUtils'
import { CenterButton } from './components/CenterButton'
import { MyLocationButtonIOS } from './components/MyLocationButtonIOS'
import { MapProvider } from './context/MapContext'
import DarkMapStyle from './style/DarkMapStyle.json'

type RadixLatLng = { mapLat?: number; mapLong?: number }
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
type MapItemPrototype = LatLng | RadixLatLng | void

export type MapProps<T> = {
  alignChildren?: 'left' | 'bottom'
  style?: StyleProp<ViewStyle>
  items?: T[]
  mapPadding?: EdgePadding
  hideMyLocationButton?: boolean
  disableMapTouch?: boolean
  delta?: number
  hideCenterButton?: boolean
  centerButtonBottom?: number
  mapMarkerRenderItem?: (item: T, isSelected: boolean) => ReactElement
  mapMarkerKeyExtractor?: (item: T) => string
  mapRenderPopUp?: (item: T) => string
  onSelectedMarkerPressed?: (item: T) => void
  onMapPress?: () => void
}

/**
 * Map Component
 * @param children are displayed on small device below map and on large device on the left side of the map
 */
export default function Map<T extends MapItemPrototype>({
  children,
  alignChildren = 'bottom',
  style,
  items,
  hideMyLocationButton,
  disableMapTouch,
  mapPadding,
  delta = 0.01,
  hideCenterButton = false,
  centerButtonBottom,
  mapRenderPopUp,
  mapMarkerRenderItem,
  mapMarkerKeyExtractor,
  onSelectedMarkerPressed,
  onMapPress,
}: PropsWithChildren<MapProps<T>>) {
  const location = useDevicePosition()
  const mapRef = useRef<MapView | null>(null)
  const { landscape } = useOrientation()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const [centered, setCentered] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [selectedItem, setSelectedItem] = useState<T | null>(items?.length ? items[0] : null)

  const itemsWithLocation = useMemo(() => items?.filter(item => GeoLocationUtils.isValidLocation(getItemLocation(item))) ?? [], [items])
  const initialRegion = useMemo(() => {
    const itemLocation = items?.length ? getItemLocation(items[0]) : undefined
    if (GeoLocationUtils.isValidLocation(itemLocation)) return itemLocation
    if (GeoLocationUtils.isValidLocation(location)) return location
    return undefined
  }, [])

  useEffect(() => {
    if (initialRegion) {
      moveMapToLocation(initialRegion)
    } else {
      // When no initial region is provided, map is centered to user location
      location && moveMapToLocation(location)
    }
  }, [landscape, mapLoaded, location])

  function getItemLocation(item: T) {
    if (hasLatLng(item)) return { latitude: item.latitude, longitude: item.longitude } as LatLng
    if (hasMapLatLong(item)) return { latitude: item.mapLat, longitude: item.mapLong } as LatLng
    return undefined
  }

  function moveMapToItem(item: T) {
    const loc = getItemLocation(item)
    loc && moveMapToLocation(loc)
  }

  function moveMapToLocation(newLocation: LatLng, userCentered = false) {
    if (!newLocation || (!newLocation.latitude && !newLocation.longitude)) return

    if (userCentered) {
      setCentered(false)
    } else {
      setCentered(true)
    }

    mapRef.current?.animateToRegion(
      {
        latitude: newLocation.latitude,
        longitude: newLocation.longitude,
        latitudeDelta: delta,
        longitudeDelta: delta,
      },
      1000
    )
  }

  function handleMarkerPressed(item: T) {
    if (item === selectedItem) {
      if (onSelectedMarkerPressed) onSelectedMarkerPressed(item)
      return
    }
    setSelectedItem(item)
  }

  return (
    <MapProvider selectedItem={selectedItem} moveMapToLocation={moveMapToLocation} moveMapToItem={moveMapToItem} setSelectedItem={setSelectedItem}>
      <IM.View style={[IMLayout.flex.f1, alignChildren === 'left' && IMLayout.flex.row, { backgroundColor: theme.background.default }]}>
        {alignChildren === 'left' && children}
        <IM.View style={[IMLayout.flex.f1, style]}>
          <Pressable style={IMLayout.flex.f1} onPress={onMapPress}>
            <IM.View style={IMLayout.flex.f1} pointerEvents={disableMapTouch ? 'none' : 'auto'}>
              {!!location && !hideMyLocationButton && <MyLocationButtonIOS onChange={() => moveMapToLocation(location, true)} />}
              {!hideCenterButton && selectedItem && getItemLocation(selectedItem) && !centered && (
                <CenterButton bottom={centerButtonBottom} onChange={() => selectedItem && moveMapToItem(selectedItem)} />
              )}
              <MapView
                ref={mapRef}
                customMapStyle={colorScheme === 'dark' ? DarkMapStyle : []}
                style={IMLayout.flex.f1}
                userInterfaceStyle={colorScheme}
                showsUserLocation
                showsMyLocationButton={!hideMyLocationButton}
                toolbarEnabled={false}
                mapPadding={mapPadding}
                onMapLoaded={() => setMapLoaded(true)}
                onPanDrag={() => setCentered(false)}
                initialRegion={{
                  latitude: initialRegion?.latitude ?? 0,
                  longitude: initialRegion?.longitude ?? 0,
                  latitudeDelta: 0.01,
                  longitudeDelta: 0.01,
                }}>
                <>
                  {itemsWithLocation?.map((item, index) => {
                    const loc = getItemLocation(item)

                    if (loc === undefined || mapMarkerRenderItem === undefined) return <IM.View key={index} />

                    return (
                      <Fragment key={mapMarkerKeyExtractor ? mapMarkerKeyExtractor(item) : `MapMarker${index}`}>
                        <Marker
                          coordinate={loc}
                          title={Platform.OS === 'web' && mapRenderPopUp ? mapRenderPopUp(item) : undefined}
                          // tracksViewChanges={Platform.OS === 'ios'} // on android this prop causes high cpu usage and slows down app. https://github.com/react-native-maps/react-native-maps/issues/4700
                          onPress={() => handleMarkerPressed(item)}
                          zIndex={item === selectedItem ? 1000 : 0} // 1000 is needed for some unknown reason
                        >
                          {/* TODO make infominds custom Marker work on web */}
                          {Platform.OS !== 'web' && <>{mapMarkerRenderItem(item, item === selectedItem)}</>}
                        </Marker>
                      </Fragment>
                    )
                  })}
                </>
              </MapView>
            </IM.View>
          </Pressable>
          {alignChildren === 'bottom' && children}
        </IM.View>
      </IM.View>
    </MapProvider>
  )
}

function hasLatLng(item: MapItemPrototype): item is LatLng {
  return !!item && !!((item as LatLng).latitude || !!(item as LatLng).longitude)
}

function hasMapLatLong(item: MapItemPrototype): item is RadixLatLng {
  return !!item && !!((item as RadixLatLng).mapLat || !!(item as RadixLatLng).mapLong)
}
