import { IM, IMLayout, StringUtils, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList } from 'react-native'
import DeviceInfo from 'react-native-device-info'

import api from '../../apis/apiCalls'
import Button from '../../components/Button'
import Synchronizer from '../../components/synchronization/Synchronizer'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useSynchronization from '../../dataProvider/hooks/useSynchronization'
import { ApiResource, DataProviderStateActions } from '../../dataProvider/types'
import { SynchronisationDetail } from '../../types'
import { ExceptionUtils } from '../../utils/ExceptionUtils'
import SyncProgressView from './SyncProgressView'

export type SyncEntryState = 'busy' | 'done' | 'error'
export type SyncEntry = {
  state: SyncEntryState
  error?: string
  details: SynchronisationDetail[]
  resource: ApiResource | null
}

export type DownSynchronizationViewProps = {
  onDone: () => void
  onAbort: () => void
}

export default function DownSynchronizationView({ onDone, onAbort }: DownSynchronizationViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const alert = useAlert()
  const { init, onDownSyncComplete: onSyncComplete, dataSyncManager } = useSynchronization()
  const { enabled, lastSyncDate, dispatch } = useDataProvider()

  const [syncInfo, setSyncInfo] = useState<SyncEntry[]>([])
  const resourcesToSync = useMemo(() => syncInfo.filter(sy => sy.state !== 'done').length, [syncInfo])
  const allDoneButWithError = useMemo(() => {
    const anyBusy = !!syncInfo.find(sy => sy.state === 'busy')
    return !anyBusy && !!syncInfo.find(s => s.state === 'error')
  }, [syncInfo])

  const [initBusy, setInitBusy] = useState(false)
  const deviceId = useMemo(() => DeviceInfo.getUniqueIdSync(), [])

  useEffect(() => {
    if (resourcesToSync > 0 || !syncInfo.length) return
    onSyncComplete(true)
    onDone()
  }, [syncInfo, resourcesToSync])

  useEffect(() => {
    if (!allDoneButWithError) return
    dispatch({ type: DataProviderStateActions.UpdateSyncState, payload: { syncState: 'error' } })
    alert.alert(i18n.t('INFO'), i18n.t('DOWN_SYNC_FAILED_ALERT'), [
      {
        text: i18n.t('GO_OFFLINE'),
        onPress: () => {
          onSyncComplete(true)
          onDone()
        },
      },
      {
        text: i18n.t('CANCEL'),
        onPress: onAbort,
      },
    ])
  }, [allDoneButWithError])

  useEffect(() => {
    const abortController = new AbortController()
    handleGoOffline(abortController).catch(error => {
      if (!abortController?.signal?.aborted) {
        dispatch({ type: DataProviderStateActions.UpdateSyncState, payload: { syncState: 'error' } })
        alert.alert(i18n.t('ERROR'), StringUtils.stringValueReplacer(i18n.t('SYNC_ERROR'), ExceptionUtils.exceptionToString(error)))
      }
    })

    return () => abortController.abort()
  }, [])

  async function handleGoOffline(abortController: AbortController) {
    setInitBusy(true)
    setSyncInfo([])
    try {
      const fullSync = !enabled || !lastSyncDate
      await init(fullSync)
      const result = await api.getSynchronizationInfo({ deviceId: deviceId, reset: fullSync }, abortController)
      const mappedResult =
        result?.synchronisationDetails
          .filter(detail => !!detail.dataCount || detail.fullSync)
          .reduce<SyncEntry[]>((syncEntries, detail) => {
            const resource = dataSyncManager.GetResourceBySyncType(detail.type) ?? null
            const foundEntry = syncEntries.find(sy => sy.resource === resource)
            if (foundEntry) {
              foundEntry.details.push(detail)
            } else {
              syncEntries.push({ state: 'busy', details: [detail], resource })
            }
            return syncEntries
          }, []) ?? []
      setSyncInfo(mappedResult)
    } finally {
      setInitBusy(false)
    }
  }

  const syncProgress = useMemo(() => {
    return syncInfo?.filter(s => s.state === 'done').length ?? 0
  }, [syncInfo])

  return (
    <IM.View style={IMLayout.flex.f1} spacing={'top'}>
      <FlatList
        style={IMLayout.flex.f1}
        data={syncInfo}
        keyExtractor={item => `SyncDetail${item.resource?.id ?? item.details.at(0)?.type ?? 'missing'}`}
        ListHeaderComponent={
          <SyncProgressView
            text={initBusy || !syncInfo?.length ? i18n.t('INIT_SYNC') : i18n.t('SYNC_ACTIVE')}
            busy={!!syncInfo?.length}
            currentProgress={syncProgress}
            totalProgress={syncInfo?.length}
          />
        }
        renderItem={({ item }) => (
          <Synchronizer
            entry={item}
            deviceId={deviceId}
            spacing={['bottom', 'horizontal']}
            setState={state => {
              setSyncInfo(prev => prev.map(p => (p.resource === item.resource ? { ...p, state: state } : p)))
            }}
          />
        )}
      />
      <IM.View spacing={'all'}>
        <Button color={theme.error} title={i18n.t('CANCEL')} onPress={onAbort} />
      </IM.View>
    </IM.View>
  )
}
