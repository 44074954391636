import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'

import PressableTextIcon from './Infominds/PressableTextIcon'

interface Props {
  title: string
  color?: string
  icon?: IconProp
  iconSize?: number
  disabled?: boolean
  fade?: number
  spacing?: SpacingProps
  onPress: () => void
}

export default function FormButton({ title, color, icon, disabled, iconSize, spacing, fade = 0.93, onPress }: Props) {
  const { theme } = useTheme()
  const buttonColor = disabled ? theme.text.detail : color

  return (
    <IM.View spacing={spacing}>
      <PressableTextIcon
        icon={icon}
        disabled={disabled}
        color={buttonColor}
        iconSize={iconSize}
        containerStyle={[
          {
            backgroundColor: Color(buttonColor).fade(fade).toString(),
            borderRadius: IMLayout.borderRadius,
          },
        ]}
        onPress={onPress}>
        {title.toUpperCase()}
      </PressableTextIcon>
    </IM.View>
  )
}
