import { IM, IMLayout, StringUtils, useAlert, useLanguage, useToastMessage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'

import PendingRequestCard from '../../cards/dataProvider/PendingRequestCard'
import RefreshControl from '../../components/Infominds/RefreshControl'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useSynchronization from '../../dataProvider/hooks/useSynchronization'
import { DataProviderStateActions, PendingRequest } from '../../dataProvider/types'
import { ExceptionUtils } from '../../utils/ExceptionUtils'

export type UpSynchronizationViewProps = {
  onDone: () => void
  sync: boolean
  pendingRequests: PendingRequest[]
  loadingPendingRequests: boolean
  loadPendingRequests: (request: unknown) => void
}

export default function UpSynchronizationView({
  onDone,
  sync,
  pendingRequests,
  loadingPendingRequests,
  loadPendingRequests,
}: UpSynchronizationViewProps) {
  const { i18n } = useLanguage()
  const { dispatch } = useDataProvider()
  const { processPendingRequests, deletePendingRequest } = useSynchronization()
  const [busy, setBusy] = useState(false)
  const toast = useToastMessage({ placement: 'top', normalColor: '#FF5858' })
  const alert = useAlert()

  useEffect(() => {
    if (!sync) return
    processRequest()
  }, [sync])

  useEffect(() => {
    if (!pendingRequests.length && sync) {
      handleUpSyncComplete()
    }
  }, [sync, pendingRequests])

  function processRequest() {
    setBusy(true)
    processPendingRequests()
      .then(result => {
        if (result.upSyncResult) {
          handleUpSyncComplete()
        } else {
          toast.show(StringUtils.stringValueReplacer(i18n.t('DATA_SYNC_ERROR'), result.failedRequestCount))
          loadPendingRequests({})
        }
      })
      .catch(e => {
        dispatch({ type: DataProviderStateActions.UpdateSyncState, payload: { syncState: 'error' } })
        alert.alert(StringUtils.stringValueReplacer(i18n.t('SYNC_ERROR')), ExceptionUtils.exceptionToString(e))
      })
      .finally(() => setBusy(false))
  }

  function handleUpSyncComplete() {
    onDone()
  }

  function onDeleteRequest(request: PendingRequest) {
    deletePendingRequest(request)
      .catch(console.error)
      .finally(() => loadPendingRequests({}))
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlatList
        data={pendingRequests}
        keyExtractor={item => `PendingRequest${item.id}`}
        renderItem={({ item }) => (
          <IM.View spacing={'horizontal'}>
            <PendingRequestCard
              busy={busy}
              pendingRequest={item}
              spacing={['horizontal', 'bottom']}
              onRetry={processRequest}
              onDelete={() => onDeleteRequest(item)}
            />
          </IM.View>
        )}
        refreshControl={<RefreshControl refreshing={loadingPendingRequests && !pendingRequests?.length} onRefresh={() => loadPendingRequests({})} />}
        ListHeaderComponent={
          <IM.View spacing={'vertical'} style={styles.headerView}>
            <IM.Text secondary>{i18n.t('DATA_TO_SYNC')}</IM.Text>
          </IM.View>
        }
        initialNumToRender={10}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  headerView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
