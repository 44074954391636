import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { IM, ModalController, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleSheet } from 'react-native'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'

import { ModalScreenRef } from '../types'
import appUtils from '../utils/appUtils'

interface Props<T> extends PropsWithChildren {
  childRef?: React.RefObject<ModalScreenRef>
  controller: ModalController<T>
  backdropOpacity?: number
}

export default function BaseServiceModal<T>({ children, controller, backdropOpacity, childRef }: Props<T>) {
  const { colorScheme } = useTheme()

  const handleClose = () => (childRef ? childRef.current?.handleGoBack() : controller.close())

  return (
    <IM.Modal
      isVisible={controller.isShown}
      onBackButtonPress={handleClose}
      onBackdropPress={handleClose}
      backdropOpacity={backdropOpacity ?? appUtils.getModalOpacity(colorScheme)}
      useNativeDriver
      useNativeDriverForBackdrop
      style={styles.modal}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <IM.AlertProvider>
          <BottomSheetModalProvider>{children}</BottomSheetModalProvider>
        </IM.AlertProvider>
      </SafeAreaProvider>
    </IM.Modal>
  )
}

const styles = StyleSheet.create({
  modal: { backgroundColor: 'transparent' },
})
