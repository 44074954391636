import { StringUtils, useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import { useCallback, useEffect, useRef, useState } from 'react'

import { ActivityTime, TravelTime } from '../apis/types/apiResponseTypes'
import { REFRESH_TICKET_LIST_TIMES_EVENT_KEY, REFRESH_TRAVEL_TIME_BUTTON_EVENT_KEY } from '../constants/EmitterKeys'
import { TimeButtonRef, TravelTimeState } from '../types'
import TimeUtils from '../utils/TimeUtils'
import useActivityTimeApi from './useActivityTimeApi'
import useTravelTimeApi from './useTravelTimeApi'
import useUserSettings from './useUserSettings'

export default function useTravelTimeManager(buttonRef?: React.RefObject<TimeButtonRef>) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()
  const [type, setType] = useState<TravelTimeState>('init')
  const manualStart = useRef(false)

  const { state, time, load, create, edit } = useTravelTimeApi()
  const { state: stateActivity, times: actTimes, load: loadActivity, edit: editActTimes } = useActivityTimeApi()

  useEvent({ key: REFRESH_TRAVEL_TIME_BUTTON_EVENT_KEY }, () => check())
  const { emit } = useEvent({ key: REFRESH_TICKET_LIST_TIMES_EVENT_KEY })

  useEffect(() => {
    check()
  }, [])

  useEffect(() => {
    const activityTime = actTimes?.at(0)

    if (!(state === 'loaded' && stateActivity === 'loaded')) return

    switch (type) {
      case 'init': {
        break
      }
      case 'checking': {
        if (time && time.openTime) {
          setType('started')
        } else {
          setType('init')
        }

        break
      }
      case 'checking-start': {
        if (activityTime === undefined || activityTime.openTime === false) {
          start()
        } else {
          alert(i18n.t('TIME_RECORDING'), StringUtils.stringValueReplacer(i18n.t('TIME_RECORDING_DESCRIPTION_TRAVEL_TIME'), activityTime.activity), [
            {
              text: i18n.t('NO'),
              style: 'destructive',
              onPress: () => setType('init'),
            },
            {
              text: i18n.t('YES'),
              style: 'default',
              onPress: () => stopActivity(activityTime),
            },
          ])
        }

        break
      }
      case 'checking-stop': {
        if (time && time.openTime) {
          stop(time)
        } else {
          setType('stopped')
        }

        return
      }
      case 'starting': {
        if (time && time.openTime) {
          setType('started')
        } else {
          setType('init')
        }
        break
      }
      case 'started': {
        emit()
        break
      }
      case 'stopping': {
        if (time && time.openTime) {
          setType('started')
        } else {
          setType('stopped')
        }
        break
      }
      case 'stopping-activity-time': {
        if (activityTime === undefined || activityTime.openTime === false) {
          start()
        } else {
          setType('init')
        }
        break
      }
      case 'stopped': {
        emit()
        break
      }
      case 'catched': {
        if (time && time.openTime) {
          buttonRef?.current?.reset('started')
          setType('started')
        } else {
          buttonRef?.current?.reset('stopped')
          setType('stopped')
        }
        break
      }
    }
  }, [state, stateActivity, type, time, actTimes])

  const check = useCallback((newType: TravelTimeState = 'checking') => {
    load()
    loadActivity()
    setType(newType)
  }, [])

  const start = useCallback(() => {
    if (userSettings && userSettings.employeeId) {
      create({
        date: TimeUtils.resetTimeOfISOString(new Date().toISOString()),
        from: TimeUtils.getCurrentSeconds(),
        employeeId: userSettings.employeeId,
      } as TravelTime & {
        employeeId: string
      })
    }

    manualStart.current = true
    setType('starting')
  }, [userSettings])

  const stop = useCallback((currentTime: TravelTime, newType: TravelTimeState = 'stopping') => {
    edit({ ...currentTime, dateUntil: TimeUtils.resetTimeOfISOString(new Date().toISOString()), until: TimeUtils.getCurrentSeconds() })
    setType(newType)
  }, [])

  const stopActivity = useCallback((currentTime: ActivityTime) => {
    editActTimes({ ...currentTime, until: TimeUtils.getCurrentSeconds() })
    setType('stopping-activity-time')
  }, [])

  return {
    start: () => check('checking-start'),
    stop: () => check('checking-stop'),
    type,
    time,
    activityTimes: actTimes?.filter(actTime => actTime.openTime),
  }
}
