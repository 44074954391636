import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, TextProps, ViewStyle } from 'react-native'

import Pressable from './Infominds/Pressable'
import RadioButton from './RadioButton'
import Text from './Text'

interface Props {
  text: string
  description?: string
  selected: boolean
  disabled?: boolean
  numberOfLines?: TextProps['numberOfLines']
  onPress: () => void
  style?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
}

export default function RadioPressable({ selected, disabled, text, style, description, containerStyle, numberOfLines, onPress }: Props) {
  return (
    <Pressable spacing="horizontal" style={[styles.pressable, style]} containerStyle={containerStyle} onPress={onPress} disabled={disabled}>
      <IM.View style={[IMLayout.flex.row, styles.view]}>
        <RadioButton selected={selected} disabled={disabled} />
        <Text secondary={disabled} style={IMLayout.flex.f1} numberOfLines={numberOfLines}>
          {text}
        </Text>
      </IM.View>
      {description && (
        <IM.View spacing="top" style={styles.description}>
          <Text secondary numberOfLines={numberOfLines}>
            {description}
          </Text>
        </IM.View>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  pressable: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 14,
    paddingBottom: 14,
    flexWrap: 'wrap',
  },
  view: { alignItems: 'center' },
  description: { width: '100%' },
})
