import { IM, IMLayout, useEvent, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Animated, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import AdditionalFieldsCard from '../../cards/common/AdditionalFieldsCard'
import ClassificationCard from '../../cards/common/ClassificationCard'
import MessagesCard from '../../cards/common/MessagesCard'
import NotesCard from '../../cards/common/NotesCard'
import TipCard from '../../cards/common/TipCard'
import CustomerDestinationIdCard from '../../cards/customer/CustomerDestinationIdCard'
import CustomerDetailIdCard from '../../cards/customer/CustomerDetailIdCard'
import TicketDetailActivityCard from '../../cards/ticketDetail/TicketDetailActivityCard'
import TicketDetailArticleCard from '../../cards/ticketDetail/TicketDetailArticleCard'
import TicketDetailCustomerContactIdCard from '../../cards/ticketDetail/TicketDetailCustomerContactIdCard'
import TicketDetailDescriptionCard from '../../cards/ticketDetail/TicketDetailDescriptionCard'
import TicketDetailInfoCard from '../../cards/ticketDetail/TicketDetailInfoCard'
import InfoboxButton from '../../components/InfoboxButton'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import DynamicView from '../../components/Infominds/DynamicView'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import KeyboardAware from '../../components/KeyboardAware'
import ScrollViewData from '../../components/ScrollViewData'
import { TICKET_DETAIL_INFOBOX_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY, REFRESH_TICKET_DETAIL_EVENT_KEY, REFRESH_TICKET_LIST_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_CUSTOMER, REQUEST_INFOBOX_FILES, REQUEST_TICKET } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useLayout, { ForceLayoutType } from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { TicketStackParamList } from '../../navigation/types'
import customerUtils from '../../utils/customerUtils'

interface Props {
  ticketId: string
  ticketCode: string
  animatedValue: Animated.Value
  isPast: boolean
  forceLayout?: ForceLayoutType
}

export default function TicketDetailView({ ticketId, ticketCode, animatedValue, isPast = false, forceLayout }: Props) {
  const { i18n } = useLanguage()
  const { isOnline } = useDataProvider()
  const { userSettings } = useUserSettings()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  useEvent({ key: REFRESH_TICKET_DETAIL_EVENT_KEY }, () => refresh())
  const { emit: refreshTicketList } = useEvent({ key: REFRESH_TICKET_LIST_EVENT_KEY })
  useEvent({ key: REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY }, () => refreshInfobox())

  const [activityId, setActivityId] = useState<string | undefined>()

  const {
    item: tickets,
    loadItem: loadTicket,
    loading: loadingTicket,
    setItem: setTickets,
  } = useControlledLoader(api.getTicket, { id: REQUEST_TICKET })
  const { item: customer, loadItem: loadCustomer, loading: loadingCustomers } = useControlledLoader(api.getCustomer, { id: REQUEST_CUSTOMER })
  const {
    item: files,
    loadItem: loadFiles,
    loading: loadingFiles,
  } = useControlledLoader(api.getInfoboxFiles, {
    id: REQUEST_INFOBOX_FILES,
  })

  useEffect(() => {
    refresh()
  }, [ticketId])

  useEffect(() => {
    if (!tickets || customer) return

    loadCustomer({ id: tickets.at(0)?.customerId })
  }, [tickets, customer])

  const refresh = () => {
    loadTicket({ id: ticketId })
    refreshInfobox()
  }

  const refreshInfobox = () => {
    loadFiles({ infoboxTyp: 'Ticket', id: ticketId })
  }

  const ticket = tickets?.at(0)
  const customerBlocked = customerUtils.isBlocked(customer?.at(0))

  return (
    <>
      <KeyboardAware>
        <ScrollViewData
          refresh={refresh}
          buttonId={TICKET_DETAIL_INFOBOX_ID}
          loading={loadingTicket === false && ticket !== undefined ? false : loadingTicket}
          keyboardShouldPersistTaps="always">
          {ticket && (
            <>
              {!!ticket.tip && <TipCard tip={ticket.tip} />}
              <TicketDetailInfoCard ticket={ticket} spacing={ticket.tip !== '' || ticket.tip !== undefined ? 'top' : 'none'} onlyRead={isPast} />
              <DynamicView forceLayout={forceLayout}>
                <IM.View style={IMLayout.flex.f4}>
                  <CustomerDetailIdCard
                    customerId={ticket.customerId}
                    spacing="top"
                    style={styles.grow}
                    onPress={customerPressed =>
                      navigation.navigate('TicketCustomerStack', {
                        screen: 'CustomerDetail',
                        params: {
                          customerId: customerPressed.id,
                          customerName: customerPressed.description,
                          customerNumber: customerPressed.number,
                          blocked: customerUtils.isBlocked(customerPressed).toString(),
                          vat: customerPressed.isoVatnumber && customerUtils.getVat(customerPressed.isoVatnumber, i18n),
                        },
                      })
                    }
                  />
                </IM.View>
                <IM.View style={IMLayout.flex.f4}>
                  <TicketDetailCustomerContactIdCard
                    changeable={!isPast && userSettings?.allowChangeShippingAddressAndContactOfTicket}
                    ticketId={ticket.id}
                    addressId={ticket.addressId}
                    contactId={ticket.contactId}
                    spacing={['top', isSmallDevice ? 'none' : 'left']}
                    style={styles.grow}
                    onChangedContactId={contactId => {
                      //@ts-ignore: ok
                      setTickets(prev => [{ ...prev[0], contactId }])
                    }}
                  />
                </IM.View>
                <IM.View style={IMLayout.flex.f4}>
                  <CustomerDestinationIdCard
                    changeable={!isPast && userSettings?.allowChangeShippingAddressAndContactOfTicket}
                    ticketId={ticket.id}
                    customerId={ticket.customerId}
                    addressId={ticket.shippingAddressId}
                    spacing={['top', isSmallDevice ? 'none' : 'left']}
                    style={styles.grow}
                    onChangedAddressId={addressId => {
                      refreshTicketList()
                      //@ts-ignore: ok
                      setTickets(prev => [{ ...prev[0], shippingAddressId: addressId }])
                    }}
                  />
                </IM.View>
              </DynamicView>
              <TicketDetailActivityCard
                ticketId={ticket.id}
                ticketCode={ticket.code}
                spacing="top"
                isPast={isPast}
                forceLayout={forceLayout}
                onFoundFirstWorkingActivity={setActivityId}
              />
              <TicketDetailDescriptionCard
                description={ticket.description}
                tecDescription={ticket.technicalDescription}
                customerDescription={ticket.customerDescription}
                spacing="top"
              />
              <TicketDetailArticleCard
                ticketId={ticket.id}
                customerId={ticket.customerId}
                activityId={activityId}
                shippingAddressId={ticket.shippingAddressId} // Questo non viene aggiornato
                spacing="top"
                onlyRead={isPast}
                forceLayout={forceLayout}
              />
              <DynamicView forceLayout={forceLayout}>
                <IM.View style={IMLayout.flex.f6}>
                  <AdditionalFieldsCard id={ticket.documentId} type="Ticket" spacing="top" style={styles.grow} onlyRead={isPast} />
                </IM.View>
                <IM.View style={IMLayout.flex.f6}>
                  <ClassificationCard
                    id={ticket.id}
                    type="Ticket"
                    spacing={isSmallDevice ? 'top' : ['left', 'top']}
                    style={styles.grow}
                    onlyRead={isPast}
                  />
                </IM.View>
              </DynamicView>
              <NotesCard
                id={ticketId}
                type="Ticket"
                code={ticketCode}
                description={tickets?.at(0)?.description}
                onlyRead={isPast}
                forceLayout={forceLayout}
                spacing="top"
              />
              <MessagesCard id={ticketId} type="Ticket" onlyRead={isPast} spacing="top" />
            </>
          )}
        </ScrollViewData>
      </KeyboardAware>
      {isOnline && (
        <AnimatedButton
          id={TICKET_DETAIL_INFOBOX_ID}
          value={animatedValue}
          icon={['fal', 'lock']}
          iconSize={20}
          bottom={CONSTANTS.secondButtonBottom}
          loadingIcon={loadingCustomers}
          onPress={() => {
            ticket &&
              navigation.navigate('TicketAccessDataStack', {
                screen: 'AccessData',
                params: {
                  customerId: ticket.customerId,
                  customerName: ticket.customer,
                  blocked: customerBlocked.toString(),
                },
              })
          }}
        />
      )}
      <InfoboxButton
        id={TICKET_DETAIL_INFOBOX_ID}
        value={animatedValue}
        loading={loadingFiles}
        onPress={() => {
          navigation.navigate('TicketInfoboxStack', {
            screen: 'InfoboxFolders',
            params: {
              id: ticketId,
              type: 'Ticket',
              subTitle: ticketCode,
            },
          })
        }}
        text={files?.length.toString() ?? 'NaN'}
      />
    </>
  )
}

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
  },
})
