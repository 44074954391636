import { IM, IMStyle } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { TextStyle } from 'react-native'

export interface DefaultBaseTextInputTitleProps {
  title?: string
  details?: string
  detailsNumberOfLines?: number
  fontWeight?: TextStyle['fontWeight']
}

const DefaultBaseTextInputTitle = memo(function DefaultBaseTextInputTitle({
  title,
  details,
  detailsNumberOfLines,
  fontWeight,
}: DefaultBaseTextInputTitleProps) {
  return (
    <>
      <IM.Text style={{ fontWeight: fontWeight ?? IMStyle.typography.fontWeightMedium }}>{title}</IM.Text>
      {details && (
        <IM.Text secondary numberOfLines={detailsNumberOfLines}>
          {details}
        </IM.Text>
      )}
    </>
  )
})

export default DefaultBaseTextInputTitle
