import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { FilterType } from '../../contexts/FilterContext'
import { Filter } from '../../types'
import FilterBadge from './FilterBadge'
import FilterDate from './FilterDate'

type Props = {
  filters: Filter<FilterType>[]
  onPress: (id: string, filer: Filter<FilterType>, value?: string) => void
}

const FilterSection = ({ filters, onPress }: Props) => {
  const { i18n } = useLanguage()

  const noFilter = () => (
    <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeRegular - 1 }}>
      {i18n.t('NO_TICKET_FILTER')}
    </IM.Text>
  )

  return (
    <IM.View spacing="top">
      <IM.View style={styles.titleContainer}>
        <IM.Icon icon={['fal', 'filter']} spacing="right" size={15} />
        <IM.Text style={styles.title}>{i18n.t('FILTERS')}</IM.Text>
      </IM.View>
      <IM.View spacing="top">
        {filters.length === 0 && noFilter()}
        {filters.map(filter => {
          if (filter.id === FilterType.PlanDate) {
            return <FilterDate key={filter.id} filter={filter} onPress={(id, date) => onPress(id, filter, date)} />
          } else {
            return <FilterBadge key={filter.id} filter={filter} onPress={id => onPress(id, filter)} />
          }
        })}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default FilterSection
