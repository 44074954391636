import { IM, IMLayout, StringUtils, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { FlatList } from 'react-native'

import DataProviderResourceCard from '../../cards/dataProvider/DataProviderResourceCard'
import StorageSpaceCard from '../../cards/synchronization/StorageSpaceCard'
import RefreshControl from '../../components/Infominds/RefreshControl'
import { DataProviderSettings } from '../../dataProvider/DataProviderSettings'
import useSynchronization from '../../dataProvider/hooks/useSynchronization'
import { DataProviderStorageResource } from '../../dataProvider/types'
import { LoadingType } from '../../types'

export type UpSynchronizationViewProps = {
  resources: DataProviderStorageResource[]
  loadingResources: LoadingType
  loadResources: (request: unknown) => void
}

const IgnoreResources: string[] = [DataProviderSettings.PendingRequestsResourceKey, DataProviderSettings.IdPairResourceKey]
export default function SynchronizationResourcesView({ resources, loadingResources, loadResources }: UpSynchronizationViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const { dataStorage } = useSynchronization()
  const filteredResources = useMemo(
    () =>
      resources
        ?.filter(r => !IgnoreResources.includes(r.resource) && r.count > 0)
        .sort((a, b) => StringUtils.compareStringsForSort(a.resource, b.resource)) ?? [],
    [resources]
  )
  const totalBytes = useMemo(() => Utils.sum(resources, q => q.size ?? 0), [resources])

  function clearResource(item: DataProviderStorageResource) {
    dataStorage
      .DeleteAll(item.resource)
      .catch(console.error)
      .finally(() => loadResources({}))
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlatList
        style={IMLayout.flex.f1}
        data={filteredResources}
        keyExtractor={item => `SyncResources${item.resource}`}
        renderItem={({ item }) => (
          <DataProviderResourceCard
            resource={item.resource}
            count={item.count}
            spacing={['bottom']}
            style={{ marginHorizontal: IMLayout.horizontalMargin * 2 }}
            onLongPress={__DEV__ ? () => clearResource(item) : undefined}
          />
        )}
        refreshControl={<RefreshControl refreshing={loadingResources === 'reloading'} onRefresh={() => loadResources({})} />}
        ListHeaderComponent={
          <StorageSpaceCard
            spacing={['bottom']}
            style={{ marginHorizontal: IMLayout.horizontalMargin * 2, marginTop: IMLayout.verticalMargin * 2 }}
            additionalStorageInfo={[{ name: i18n.t('OFFLINE_DATA'), totalBytes: totalBytes, color: theme.randomColors[5] }]}
          />
        }
      />
    </IM.View>
  )
}
