import { IMStyle, useDimensions, useEvent, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import { AccessData } from '../../apis/types/apiResponseTypes'
import AccessCredentials from '../../components/AccessCredentials'
import AccessInfo from '../../components/AccessInfo'
import Card from '../../components/card/Card'
import AccessDataCardHeader from '../../components/card/headers/AccessDataCardHeader'
import AccessDataLeftElement from '../../components/card/left/AccessDataLeftElement'
import { ACCESS_DATA_CARD_SELECTION_EVENT_KEY } from '../../constants/EmitterKeys'
import { ThemeColorExpanded } from '../../types'
import { accessDataSelectedIdAtom } from '../../utils/stateManager'

interface Props {
  data: AccessData
  onTabletPress: (levelAccessGranted: boolean, groupAccessGranted: boolean, color: string) => void
  onEdit: () => void
}

const AccessDataCard = ({ data, onTabletPress, onEdit }: Props) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useDimensions()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)
  const [selectedId, setSelectedId] = useRecoilState(accessDataSelectedIdAtom)

  const { emit: handleCardHighlight } = useEvent<string>({ key: ACCESS_DATA_CARD_SELECTION_EVENT_KEY }, id => {
    setSelectedId(id)
    setSelected(id === data.id)
  })

  useEffect(() => {
    setSelected(selectedId === data.id)
  }, [])

  useEffect(() => {
    if (!isSmallDevice) setOpen(false)
  }, [isSmallDevice])

  const levelAccessGranted = data.userAccesslevel >= data.accesslevel
  const groupAccessGranted = data.accessdatagroupBlocked === false
  const accessGranted = levelAccessGranted && groupAccessGranted
  const accessColor = data.inactive ? theme.access.inactive : accessGranted ? theme.access.granted : theme.access.denied

  const handlePress = () => {
    if (isSmallDevice) {
      setOpen(prev => !prev)
    } else {
      onTabletPress(levelAccessGranted, groupAccessGranted, accessColor)
      handleCardHighlight(data.id)
    }
  }

  return (
    <Card onPress={handlePress} selected={!isSmallDevice && selected}>
      <Card.Left>
        <AccessDataLeftElement color={accessColor} />
      </Card.Left>
      <Card.Header style={styles.header}>
        <AccessDataCardHeader data={data} accessGranted={accessGranted} color={accessColor} onEdit={onEdit} />
      </Card.Header>
      <Card.Content open={open}>
        <AccessCredentials
          open={open}
          data={data}
          color={accessColor}
          levelAccessGranted={levelAccessGranted}
          groupAccessGranted={groupAccessGranted}
        />
      </Card.Content>
      {(data.note !== '' || open) && (
        <Card.Content>
          <AccessInfo open={open} data={data} levelAccessGranted={levelAccessGranted} groupAccessGranted={groupAccessGranted} />
        </Card.Content>
      )}
    </Card>
  )
}

const styles = StyleSheet.create({
  header: { flexDirection: 'row', flex: 1, alignItems: 'center' },
})

export const accessDataCardStyle = StyleSheet.create({
  title: { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular },
})

export default AccessDataCard
