import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import Tag from '../../../components/Tag'
import Text from '../../../components/Text'
import { closeActivityStyles } from '../../../constants/Constants'
import useCloseActivity from '../../../hooks/useCloseActivity'
import { CloseType, ThemeColorExpanded } from '../../../types'
import { CloseActivityViewProps } from '../../activity/ActivityCloseView'
import TicketCloseActivityStep2View from './TicketCloseActivityStep2View'

const buttonValues = Object.values(CloseType).map(el => el)

const TicketCloseActivityStep1View = memo(function TicketCloseActivityStep1View({ ...others }: CloseActivityViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { closeType, setCloseType } = useCloseActivity()

  return (
    <IM.View
      style={[
        closeActivityStyles.header,
        styles.container,
        {
          backgroundColor: theme.closing.first,
        },
      ]}>
      <Text style={{ fontSize: IMStyle.typography.fontSizeMedium }}>{i18n.t('STEP_1')}</Text>
      <Text secondary>{i18n.t('STEP_1_DESCRIPTION')}</Text>
      <IM.View style={[IMLayout.flex.row, styles.buttonContainer]} spacing="bottom">
        {buttonValues.map(el => {
          return <Tag key={el} id={el} active={closeType === el} name={i18n.t(el)} onPress={() => setCloseType(el)} style={styles.tag} />
        })}
      </IM.View>
      <TicketCloseActivityStep2View {...others} />
    </IM.View>
  )
})

export default TicketCloseActivityStep1View

const styles = StyleSheet.create({
  buttonContainer: {
    flexWrap: 'wrap',
  },
  container: {
    borderTopRightRadius: 0,
    ...Platform.select({
      native: {
        height: '100%',
      },
      web: {
        flexGrow: 1,
      },
    }),
    zIndex: 1,
  },
  tag: {
    marginTop: 8,
  },
})
