import { IM } from '@infominds/react-native-components'
import React from 'react'

export default function SkeletonAccessData() {
  return (
    <IM.Skeleton.Container height={108}>
      {/* Lock icon */}
      <IM.Skeleton.Circle cx="30" cy="28" r="15" />

      {/* Left indicator */}
      <IM.Skeleton.Rect x="0" y="4" rx="4" ry="4" width="6" height="103" />

      {/* Header first line */}
      <IM.Skeleton.Rect x="54" y="11" rx="0" ry="0" width="14" height="12" />
      <IM.Skeleton.Rect x="80" y="14" rx="0" ry="0" width="140" height="6" />

      {/* Header second line */}
      <IM.Skeleton.Rect x="54" y="30" rx="0" ry="0" width="14" height="12" />
      <IM.Skeleton.Rect x="80" y="33" rx="0" ry="0" width="140" height="6" />

      {/* Edit button
      <Rect x="273" y="20" rx="0" ry="0" width="60" height="15" /> */}

      {/* Divider */}
      {/* <Rect x="14" y="55" rx="0" ry="0" width="320" height="2" /> */}

      {/* Note */}
      <IM.Skeleton.Rect x="14" y="64" rx="0" ry="0" width="160" height="6" />
      <IM.Skeleton.Rect x="14" y="79" rx="0" ry="0" width="130" height="6" />
      <IM.Skeleton.Rect x="14" y="94" rx="0" ry="0" width="100" height="6" />
    </IM.Skeleton.Container>
  )
}
