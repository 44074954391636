import { StringUtils, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { memo, useEffect, useState } from 'react'

import { getApi } from '../../apis/apiCalls'
import { REQUEST_ACCESS_DATA_LEVEL } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useForm from '../../hooks/useForm'
import useVault from '../../hooks/useVault'
import VaultModal from '../../modals/VaultModal'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import TextInput, { TextInputProps } from './TextInput'

type AccessLevelInputProps = TextInputProps & {
  customerId: string
  accessDataGroupId?: string
}

const MIN_LEVEL = 0
const MAX_LEVEL = 99

const AccessLevelInput = memo(function AccessLevelInput({
  customerId,
  accessDataGroupId,
  onChangeText,
  editable,
  ...props
}: Omit<AccessLevelInputProps, 'value'>) {
  const { sessionId } = useVault()
  const { client } = useDataProvider()
  const modalController = useModalController()
  const {
    item: response,
    loadItem: getLevel,
    loading,
  } = useControlledLoader(getApi(client).getAccessDataLevel, {
    id: REQUEST_ACCESS_DATA_LEVEL,
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        modalController.show()
        return 'vault-locked'
      } else {
        return 'vault-unlocked'
      }
    },
  })

  const { i18n } = useLanguage()
  const { error, setError } = useForm()

  const [string, setString] = useState('')
  let currentMaxLevel = MAX_LEVEL

  useEffect(() => {
    if (!isNaN(parseInt(string, 10))) {
      onChangeText?.(string)
    }
  }, [string])

  useEffect(() => {
    fetch()
  }, [customerId, accessDataGroupId])

  useEffect(() => {
    response && response.data && setString(response.data.toString())
  }, [response])

  const fetch = (id?: string) => {
    getLevel({
      VaultSessionId: id ?? sessionId,
      customerId: customerId,
      accessdatagroupId: accessDataGroupId === undefined || accessDataGroupId === '-1' ? undefined : accessDataGroupId,
    })
  }

  const manageLimits = (input: string) => {
    const numb = parseInt(input, 10)

    if (isNaN(numb)) {
      setError(true)
      setString('')
      return
    }

    if (numb > currentMaxLevel) {
      setError(true)
    } else if (numb < MIN_LEVEL) {
      setError(true)
    } else {
      setError(false)
    }

    return setString(numb.toString())
  }

  let detailMessage: string | undefined

  if (loading === false) {
    if (response && response.data) {
      if (response.data <= MIN_LEVEL) {
        detailMessage = StringUtils.stringValueReplacer(i18n.t('ACCESS_LEVEL_DETAILS'), MIN_LEVEL, MAX_LEVEL)
      } else {
        detailMessage = StringUtils.stringValueReplacer(i18n.t('ACCESS_LEVEL_DETAILS'), MIN_LEVEL, response.data)
        currentMaxLevel = response.data
      }
    } else {
      detailMessage = undefined
    }
  }

  return (
    <>
      <TextInput
        value={string}
        details={detailMessage}
        onChangeText={manageLimits}
        loading={loading !== false}
        editable={editable && loading === false}
        error={error}
        {...props}
      />
      <VaultModal controller={modalController} onAccept={fetch} />
    </>
  )
})

export default AccessLevelInput
