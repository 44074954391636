import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

interface Props {
  style?: StyleProp<ViewStyle>
  enableTableStyle?: boolean
}

export default function NavigationIcon({ style, enableTableStyle = false }: Props) {
  const { theme } = useTheme()

  return <IM.Icon icon={['fal', 'chevron-right']} style={[enableTableStyle && styles.icon, style]} color={theme.text.detail} />
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: 8,
  },
})
