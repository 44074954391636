import { IM, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleSheet } from 'react-native'

import PressableIcon from '../components/Infominds/PressableIcon'
import useUserSettings from '../hooks/useUserSettings'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'

interface Props {
  controller: ModalController
  onPress: (id: 'contact' | 'destination') => void
}

export default function CreateContactOrDestinationModal({ controller, ...others }: Props) {
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const { onPress } = others

  const handleClose = () => controller.close()

  return (
    <IM.Modal
      statusBarTranslucent
      isVisible={controller.isShown}
      backdropOpacity={appUtils.getModalOpacity(colorScheme)}
      propagateSwipe
      swipeDirection={[]}
      style={styles.modal}
      hideModalContentWhileAnimating
      onBackButtonPress={handleClose}
      onBackdropPress={handleClose}
      modalWidthLargeDevice={400}
      alignLargeDevice="center"
      modalStyle={styles.modalStyle}>
      <IM.View
        style={[
          styles.centeredView,
          Platform.select({
            native: {
              flex: 1,
              backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100}`,
            },
          }),
        ]}>
        <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme) }]}>
          <IM.View spacing="bottom" style={styles.header}>
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular + 2, fontWeight: IMStyle.typography.fontWeightMedium }}>
              {i18n.t('CREATE')}
            </IM.Text>
            <PressableIcon icon={['fal', 'times']} size={22} onPress={handleClose} />
          </IM.View>
          <IM.Card
            spacing="top"
            head={{ backGroundColor: theme.header.main.background, icon: ['fal', 'contact-book'] }}
            defaultContent={{
              texts: [
                {
                  text: i18n.t('CONTACT'),
                  textStyle: { fontSize: IMStyle.typography.fontSizeRegular - 2 },
                  viewStyle: { flex: 1, justifyContent: 'center' },
                  secondary: !userSettings?.allowContactCreation,
                },
              ],
            }}
            disabled={!userSettings?.allowContactCreation}
            onPress={() => onPress('contact')}
            style={styles.align}
          />
          <IM.Card
            spacing="top"
            head={{ backGroundColor: theme.header.main.background, icon: ['fal', 'location-dot'] }}
            defaultContent={{
              texts: [
                {
                  text: i18n.t('DESTINATIONS'),
                  textStyle: { fontSize: IMStyle.typography.fontSizeMedium - 2 },
                  viewStyle: { flex: 1, justifyContent: 'center' },
                  secondary: !userSettings?.allowShippingAddressCreation,
                },
              ],
            }}
            disabled={!userSettings?.allowShippingAddressCreation}
            onPress={() => onPress('destination')}
            style={styles.align}
          />
        </IM.View>
      </IM.View>
    </IM.Modal>
  )
}

const styles = StyleSheet.create({
  modal: { margin: 0 },
  centeredView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  modalView: {
    borderRadius: 20,
    padding: 35,
    paddingTop: 18,
    width: '100%',
    maxWidth: 400,
  },
  modalStyle: { backgroundColor: 'transparent' },
  align: {
    ...Platform.select({ web: { alignItems: 'center' } }),
  },
})
