import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'
import PressableIcon from './Infominds/PressableIcon'

interface Props<T> {
  item: T
  value?: string
  disabled?: boolean
  onRemove: (item: T) => void
}

export default function MultiSelectionItem<T extends { id: string }>({ value, item, disabled, onRemove }: Props<T>) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={[styles.container, { backgroundColor: theme.chip.background }]}>
      <IM.Text style={{ color: disabled ? theme.chip.icon.disabled : theme.text.default }}>{value ?? item.id}</IM.Text>
      <PressableIcon
        color={theme.chip.icon.enabled}
        disabled={disabled}
        disabledColor={theme.chip.icon.disabled}
        icon={['fal', 'times']}
        size={15}
        onPress={() => onRemove(item)}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: IMLayout.borderRadius,
    paddingLeft: 10,
    height: 28,
    marginVertical: 3,
    marginRight: 6,
  },
})
