import {
  ActivityEmail,
  ActivityEmployee,
  ActivityState,
  ActivityType,
  AddFieldValue,
  AdditionalField,
  AdditionalFieldType,
  ArticleSheet,
  ArticleType,
  AvailabilityControlType,
  Characteristic,
  Classification,
  ClassificationValue,
  CounterKindType,
  CreateSerialNumberType,
  CustomerLanguage,
  Description,
  FieldType,
  FolderRight,
  Gender,
  InfoboxFolderVisibilityType,
  InfoboxType,
  IntrastatMovingType,
  MeasurementUnit,
  MonitoringType,
  PasswordComplexity,
  Price,
  PriorityType,
  ReadingType,
  SerialNumberState,
  SMNoteType,
  SparePartDirection,
  State,
  TicketClassification,
} from '../../types'

export interface ApiError {
  Code: number
  Message: string
}

export interface Company {
  id: string
  description: string
}

export interface UserSettings {
  employeeId?: string
  employeeFirstname?: string
  employeeLastname?: string
  language?: string
  depositId?: string
  isVaultActive?: boolean
  hasVaultAccess?: boolean
  vaultOpenDuration?: number
  allowContactCreation: boolean
  allowShippingAddressCreation: boolean
  showOnlyActivitiesWithStateEditingIntoTicketDetail: boolean
  showOnlyOwnActivitiesIntoTicketDetail: boolean
  allowMultipleCompletionActivity: boolean
  considerationDeliveryaddressOnMultipleCompletionActivity: boolean
  allowChangeActivityStateFromEditingInPlanning: boolean
  allowChangeTimeOfHistoricalActivity: boolean
  allowChangeSavedTimeOfActivity: boolean
  allowChangeShippingAddressAndContactOfTicket: boolean
  allowChangeCounterType: boolean
  allowCreateAndChangePlanningTimes: boolean
  showDateOnRegistrationOfSpareParts: boolean
  showPriceOnRegistrationOfSpareParts: boolean
  googleMapsApiKey?: string // No way to change the api key at runtime https://github.com/react-native-maps/react-native-maps/issues/3649#issuecomment-749726387
  showOnlyTheActivityTimesToBilledOnSummary: boolean
  mandatoryInputOfTheValueDuringCounterReading: boolean
  startsTheActivityTimeAsSoonAsTheActivityIsOpened: boolean
  allowEditingCustomerDescriptionOfActivity: boolean
  allowChangePriceOfSpareParts: boolean
  displayStartAndEndOfTimesOnSummary: boolean
  allowAddingAndChangingTicketArticle: boolean
  mandatoryInputContactOnClosingTheActivityWithSignature: boolean
  abortOnClosingActivityIfAdditionalFieldsWithMandatoryInputHaveNoValue: boolean
  isEmployeeTravelTimeActive: boolean
  allowCreationOfNewActivityForExistingTicket: boolean
  allowModifyTicketPriority: boolean
  allowModifyActivityAndTicketState: boolean
  allowCreationOfNewSerialnumbers: boolean
  allowCreateTicket: boolean
  allowChangingInvoiceTypeIntoTicketAndActivity: boolean
  offlineSynchronizationOfThePlanningList?: boolean
  planningCalendarShowSaturdayAsWorkDay: boolean
  planningCalendarWorkTimeFrom: number
  planningCalendarWorkTimeUntil: number
  checkAvailabilityOfSpareParts: boolean
  allowChangingSerialNumberLocation: boolean
}

export interface Customer {
  id: string
  companyId: string
  addressId: string
  description: string
  town?: string
  province?: string
  country?: string
  postalCode?: string
  street?: string
  streetnumber?: string
  number: number
  phone?: Phone[]
  fax?: Phone[]
  email?: Email[]
  url?: string
  language?: string
  isoVatnumber?: IsoVatnumber
  agentName?: string
  mapLat: number
  mapLong: number
  inactive: boolean
  openingHours?: string
  lockedSince?: string
}

export type Contact = {
  id: string
  companyId: string
  addressId: string
  department?: string
  position?: string
  title?: string
  firstname?: string
  surname?: string
  description: string
  phone?: Phone[]
  fax?: Phone[]
  email?: Email[]
  dateofbirth?: string
  inactive: boolean
  gender: Gender
  language?: string
  createdate?: string
  updatedate?: string
}

export type Destination = {
  id: string
  customerId: string
  companyId?: string
  defaultShippingAddress?: boolean
  addressId?: string
  description: string
  optional?: string
  salutation?: string
  letterSalutation?: string
  street?: string
  streetnumber?: string
  postalCode?: string
  town?: string
  province?: string
  country?: string
  phone?: Phone[]
  fax?: Phone[]
  email?: Email[]
  emailPEC?: string
  url?: string
  language?: string
  isoVatnumber?: IsoVatnumber
  taxnumber?: string
  freighterId?: string
  note?: string
  mapLat?: number
  mapLong?: number
  inactive: boolean
  lockedSince?: string
  openingHours?: string
}

export interface Email {
  type: string
  address: string
  description: string
}

export interface Phone {
  type: string
  number: string
  description: string
}

export interface IsoVatnumber {
  isocode?: string
  vatNumber?: string
}

export interface VaultResponse<T> {
  vaultState: 'Inactiv' | 'SessionOpen' | 'SessionClosed'
  data: T | null
}

export interface SerialNumberBase {
  id: string
  number: string
}

export interface SerialNumber extends SerialNumberBase {
  inactive: boolean
  numberManufactor?: string
  location?: string
  article: SerialNumberArticle
  blocked?: string //Date
  warrantySupplier: string //Date
}

export interface SerialNumberArticle {
  id: string
  companyId: string
  code: string
  searchtext: string
  barcode: string
  measurementUnitId?: string
  measurementUnitCode?: string
  model: string
  isSerialnumberActive: boolean
  isSerialnumberOnlyForHistory: boolean
  isLottoSerialnumber: boolean
  createSerialnumber: CreateSerialNumberType
  serialnumberPrefix?: string
  articletype: ArticleType
  producer?: string
  inactive: boolean
  blocked?: string
  isDiscontinued: boolean
  isStockmovement: boolean
  warrantyCustomer?: string
  minimumOrderQuantitySales?: number
  priceUnit?: number
  intrastatTypeOfMovement: IntrastatMovingType
  intrastatOriginCountry?: string
  intrastatGroup?: string
  intrastatConversion?: number
  articleDescriptionNumber?: number
  orderDescriptionNumber?: number
  offerDescriptionNumber?: number
  descriptionNumberDeliveryNote?: number
  descriptionNumberInvoice?: number
  descriptionNumberPurchaseOrder?: number
  descriptionNumberCatalog?: number
  availabilityControl: AvailabilityControlType
  grafikid?: string
  descriptions: Description[]
  measurementUnits: MeasurementUnit[]
  prices: Price[]
  articlesheets: ArticleSheet[]
}

export interface AccessData {
  id: string
  accessdatagroupId?: string
  accessdatagroup?: string
  accessdatagroupBlocked?: boolean
  note: string
  note2: string
  noteWithAccesslevel: string
  user?: string
  accesslevel: number
  userAccesslevel: number
  passwordAvailable: boolean
  email?: string
  url?: string
  lastModification: string
  lastModificationUserId: string
  lastModificationUser?: string
  serialnumberId?: string
  serialnumber?: string
  inactive: boolean
  otpActive: boolean
}

export interface Group {
  id: string
  code: string
  description?: string
  inactive: boolean
}

export interface Otp {
  otp: string
  remainingSeconds: number
}

export interface PasswordGenerationDefaults {
  length: number
  passwordComplexity: PasswordComplexity
}

export interface TicketPostResponse {
  id: string
  activityId: string
  warning: string
}

export interface Ticket {
  id: string
  customerId: string
  documentId: string
  addressId: string
  code: string
  documentDate: string
  dateTimeForOrder: string
  planDateTimeForOrder: string
  location: string
  planDate?: string
  planStartTime?: number
  planEndTime?: number
  customer: string
  customerLocation?: string
  shippingAddressId?: string
  shippingAddress?: string
  shippingAddressLocation?: string
  contactId?: string
  contact?: string
  description: string
  customerDescription?: string
  technicalDescription?: string
  priorityId?: string
  priority: number
  priorityType: PriorityType
  priorityCode: string | undefined
  priorityDescription: string | undefined
  priorityColorHex: string
  priorityColorInt: number
  stateId?: string
  state: State
  stateCode: string
  stateDescription: string
  employeeId?: string
  employee?: string
  employeeIdResponsible?: string
  employeeResponsible?: string
  activityEmployees: ActivityEmployee[]
  invoicingTypeId?: string
  invoicingType?: string
  activityType?: ActivityType
  articleId?: string
  article?: string
  articleDescription?: string
  articleModel?: string
  serialnumberId?: string
  serialnumber?: string
  serialnumberManufacturerNumber?: string
  serialnumberLocation?: string
  countOfActivityTimes?: number
  countOfExternalComments?: number
  areaClassificationId?: string
  area?: string
  mapLat?: number
  mapLong?: number
  tip?: string
  team?: string
  maintenanceType?: string
  // offline
  hasUnSyncedCompletedActivities?: boolean
  smTicketType?: SMTicketType
}

export enum SMTicketType {
  None = 'None',
  InElaboration = 'InElaboration',
}

export interface TicketArticle {
  id: string
  ticketId: string
  customerId: string
  addressId: string
  articleId: string
  contactId?: string
  articleCode: string
  articleSearchtext: string
  articleDescription?: string
  articleModel?: string
  serialnumberId?: string
  serialnumber?: string
  serialnumberLocation?: string
  serialnumberNumberManufactor?: string
  serialnumberTonerDeliveryAutomatically?: boolean
  serialnumberMonitoring?: MonitoringType
  serialnumberMonitoringState: SerialNumberState
  serialnumberMonitoringLastDate?: string
  serialnumberCustomerWarranty?: string
  serialnumberCustomerWarrantyStart?: string
  serialnumberCustomerWarrantyEnd?: string
  serialnumberOutgoingDate?: string
  contracts: Contract[]
  thumbImageAsPNG?: string
  grafikId?: string
}

export interface TicketRegistry {
  id: string
  code: string
  description: string
}

export type Contract = {
  id: string
  code?: string
  description?: string
  contractType?: string
  validFrom?: string
  validUntil?: string
  terminatedOn?: string
  isDone?: boolean
  isAutomaticRenewal: boolean
  serialnumberId?: string
  customerId?: string
  blocked?: string
}

export type ClassificationRequest = {
  id: string
  fkId: string
  classifications: Classification[]
}

export type TicketClassificationRequest = {
  type: string
  fkId: string
  classifications: TicketClassification[]
}

export type PostClassification = {
  fkId: string
  classifications: ClassificationValue[]
}

export type PostAdditionalField = {
  fkId: string
  additionalfields: AddFieldValue[]
}

export type PatchInfoboxFile = {
  id: string
  infoboxTyp: InfoboxType
  filename?: string
  note?: string
}

export type PatchTicketArticle = {
  id: string
  articleId: string
  serialnumberId: string
  serialnumber?: string
  contactId: string
}

export type PostTicketArticle = {
  ticketId: string
  articleId: string
  serialnumberId: string
  serialnumber?: string
}

export type TicketClassificationDefinition = {
  id: string
  code: string
  description: string
  isMandatory: boolean
}

export type ClassificationDefinitionValue = {
  id: string
  classificationDefinitionId: string
  code: string
  description: string
  inactive: boolean
}

export type StateResponse = {
  id: string
  code: string
  description: string
  state: State
  isDefault: boolean
}

export type TicketPriorityResponse = {
  id: string
  code: string
  description: string
  color: string
}

export type AdditionalFieldResponse = {
  fkId: string
  type: AdditionalFieldType
  additionalfields: AdditionalField[]
}

export type AdditionalFieldDefinition = {
  id: string
  code: string
  description: string
  type: AdditionalFieldType
  fieldType: FieldType
  fieldSpecification?: string
  isMandatory: boolean
  fieldDecimal: number
  fieldLength: number
}

export type AdditionalFieldDefinitionValue = {
  id: string
  additionalfieldDefinitionId: string
  code: string
  description: string
}

export type SerialNumberCounter = {
  id: string
  ticketId: string
  serialnumberId: string
  serialnumber: string
  serialnumberNumberManufactor?: string
  serialnumberLocation: string
  articleId: string
  articleCode: string
  articleSearchtext: string
  description: string
  note?: string
  acv?: number
  position: number
  monitoring: number
  counterType: string
  counterKindType: CounterKindType
  counterTypeSequence?: number
  counterTypeDecimalPlaces?: number
  counterTypeNote?: string
  calculationFormula?: string
  contract: boolean
  movementActivityId?: string
  movementRadingType: ReadingType
  movementDate?: string
  movementValue?: number
  movementNote?: string
}

export type SerialNumberAccessory = {
  id: string
  articleId: string
  article: string
  model?: string
  serialnumber: string
  description: string
  quantity: number
  installationDate: string
}

export type SerialNumberTicket = {
  id: string
  documentId: string
  code: string
  documentDate: string
  description?: string
  state: State
  stateCode: string
}

export interface Activity extends TicketActivity {
  location: string
  town?: string
  customerTown?: string
  shippingAddressTown?: string
  planDate?: string
  planStartTime?: number
  planEndTime?: number
  ticketCode: string
  customer: string
  customerLocation?: string
  shippingAddress?: string
  shippingAddressLocation?: string
  ticketDescription: string
  maintenanceType?: string
  ticketArticles: ActivityTicketArticle[]
  articleDescription?: string
  serialnumber?: string
  serialnumberManufacturerNumber?: string
  serialnumberLocation?: string
  smActivityType?: SMActivityType
}
export enum SMActivityType {
  None = 'None',
  InPlanning = 'InPlanning',
}

export type ActivityTicketArticle = {
  id: string
  ticketId: string
  position: number
  articleId: string
  article: string
  articleDescription: string
  articleModel: string
  serialnumberId: string
  serialnumber: string
  serialnumberManufacturerNumber: string
  serialnumberLocation: string
}

export interface TicketActivity {
  id: string
  ticketId: string
  ticketDocumentId: string
  date: string
  code: string
  stateId?: string
  state: ActivityState
  stateCode: string
  stateDescription: string
  employeeId?: string
  employee?: string
  employeeIdResponsible?: string
  employeeResponsible?: string
  invoiceTypeId?: string
  invoiceType?: string
  activityType: string
  activityTypeId: string
  activityTypeCode: string
  customerDescription?: string
  customerLanguage: CustomerLanguage
  technicalDescription?: string
  nextActivityDescription?: string
  unitUp: boolean
  unitUpDateTime?: string
  customerId: string
  customerTicketActivityEmail?: string
  contactId?: string
  contact?: string
  contactEmail?: string
  shippingAddressId?: string
  reportEmails?: string[]
  signatureImageData?: string
  signatureName?: string
  nextActivityTechnicalDescription?: string
  nextActivityEmployeeId?: string
  addressId: string
  contactFirstname?: string
  contactSurname?: string
  activityTypeType: ActivityType
  teamId?: string
  team?: string
  areaId?: string
  area?: string
  sparePartInternalPurchaseOrderRegisterId?: string
  sparePartInternalPurchaseOrderId?: string
  tip?: string
}

export interface ActivitySparePart {
  id: string
  activityId: string
  depositId: string
  depositCode?: string
  depositDescription?: string
  stockQuantity?: number
  direction: SparePartDirection
  date: string
  description?: string
  measurementUnitId: string
  measurementUnit: string
  quantity?: number
  price?: number
  articleId: string
  articleCode: string
  articleSearchtext: string
  articleDescription: string
  articleModel: string
  serialnumberId?: string
  serialnumber?: string
  serialnumberLocation?: string
  serialnumberNumberManufactor?: string
  serialnumberOutgoingDate?: string
  serialnumberWarrantyCustomerUntil?: string
  measurementDecimals?: number
  invoicingTypeId?: string
  invoicingType?: string
  isSerialnumberActive: boolean
  isLottoSerialnumberActive: boolean
  isWarehouseMovementActive: boolean
  serialnumberIdParent?: string
  serialnumberParent?: string
  serialnumberNumberManufactorParent?: string
  articleSearchtextParent?: string
}

export interface SparePartDepot {
  id: string
  code: string
  description: string
  location?: string
  isStockpositionActive: boolean
  inactive: boolean
  blocked?: string
}

export interface ActivityEmailResponse {
  activityId: string
  emails: ActivityEmail[]
}

export interface PlanningTime {
  id: string
  ticketId: string
  ticketDocumentId: string
  ticketCode: string
  ticketDescription: string
  activityId: string
  activityCode: string
  activityType: ActivityType
  ticketState: ActivityState
  activityState: ActivityState
  employeeId: string
  employee: string
  date: string
  from: number
  until?: number
  priorityType: PriorityType
  priorityColorHex: string
  priorityColorInt: number
  modifyActivity: boolean
  appointmentColorHex: string
}

export interface Note {
  id: string
  companyId: string
  fkid: string
  employeeId: string
  employee: string
  date: string
  subject?: string
  note: string
  noteNoRtf?: string
  notegroupId: string
  notegroup: string
  contactId?: string
  contact?: string
  appointment?: string
  ok?: boolean
  doneOn?: string
}

export interface NoteGroup {
  id: string
  code: string
  description: string
}

export interface Message {
  id: string
  fkId: string
  type: SMNoteType
  contact?: {
    id: string
    code: string
    description: string
  }
  employee?: {
    id: string
    code: string
    description: string
  }
  date: string
  note: string
  noteNoHtml: string
  readOn?: string
  sentOn?: string
  external: boolean
  htmlContainImages: boolean
  infoboxFileCount?: number
}

export interface SMNote {
  id: string
  companyId: string
  fkId: string
  employeeId: string
  employee: string
  date: string
  subject?: string
  note: string
  noteNoRtf: string
  notegroupId: string
  notegroup: string
  contactId?: string
  contact?: string
  appointment?: string
  ok?: boolean
  doneOn?: string
  type: SMNoteType
}

export interface Employee {
  id: string
  code: string
  firstName: string
  lastName: string
  language?: string
  function?: string
  email?: Email[]
  phone?: Phone[]
  company?: string
  dateOfBirth?: string
  recruitmentDate?: string
  optional?: string
  street?: string
  streetnumber?: string
  postalCode?: string
  town?: string
  province?: string
  country?: string
  isActiveForActivityTimes: boolean
  isActiveForFollowupVisit: boolean
  isActiveForPlanning: boolean
  isDefaultForPlanning: boolean
  teams: { id: string; code: string }[]
}

export interface Team {
  id: string
  code: string
  description: string
  employees?: Employee[]
}

export interface ActivityTypeGet {
  id: string
  code: string
  description: string
  activityType: ActivityType
  isDefault: boolean
}

export interface Language {
  id: string
  description: string
}

export interface Country {
  id: string
  code: string
  description: string
}

export interface Report {
  id: string
  code: string
  description: string
}

export interface PostTicketReport {
  id: string
  ticketId: string
  reportId: string
  employeeId?: string
  email?: string
}

export interface PostActivityReport {
  /** @param id it has no value when the app is online. It is here only because it is needed by data provider */
  id: string
  activityId: string
  emails: string[]
}

export interface Quality {
  id: string
  serialnumberId: string
  serialnumber: string
  serialnumberManufactor?: string
  articleDescription: string
  activityId: string
  characteristics: Characteristic[]
}

export interface Article {
  id: string
  code: string
  searchtext: string
  description?: string
  price?: number
  graficId?: string
}

export interface ArticleSparePart {
  /** @param id it has no value when the app is online. It is here only because it is needed by data provider */
  id: string
  articleId: string
  articleCode: string
  articleBarcode: string
  articleModel?: string
  articleSearchtext: string
  articleDescription?: string
  isWarehouseMovementActive: boolean
  isSerialnumberActive: boolean
  isLottoSerialnumberActive: boolean
  measurementUnitId?: string
  measurementUnit?: string
  measurementDecimals?: number
  stockQuantity?: number
  serialnumberId?: string
  serialnumber?: string
  depositId: string
  depositCode?: string
  depositDescription?: string
  ticketId: string
  inStock?: boolean
  installationDate?: string
  removalDate?: string
  isDummy?: boolean
  preCalcQuantity?: number
}

export type EmailType = {
  type: string
  description: string
}

export type PhoneType = EmailType
export type FaxType = EmailType

export interface Folder {
  id: string
  number: number
  description: string
  type: number
  order: number
  right: FolderRight
  visibilities?: InfoboxFolderVisibility[]
}

export interface InfoboxFolderVisibility {
  type: InfoboxFolderVisibilityType
  right: FolderRight
}

export interface FolderWithCount extends Folder {
  files: number
}

export interface File {
  id: string
  createUserId: string
  filename: string
  extension: string
  date: string
  external?: boolean
  folderNumber: number
  description?: string
  sizeKB: number
  thumbImageAsPNG?: string
  thumbImageAsPNGError: boolean
  canDelete: boolean
  note?: string
}

export interface HighResFile extends File {
  doctype: number
  origin: number
  externalFileId: string | null
  data: string
}

export interface PostFile {
  id: string
  txdokid: string
  filename: string
  extension: string
}

export interface PostTicketResponse {
  ticketId: string
  activityId: string
  documentId: string
  warning?: string
}

export interface InvoiceType {
  id: string
  code: string
  description?: string
  isDefault: boolean
  isForManualTimeRecording: boolean
}

export interface ActivityTime {
  id: string
  date: string
  from: number
  until?: number
  openTime?: boolean
  activityId: string
  ticketId: string
  activity: string
  period?: number
  employeeId: string
  employee: string
  invoicingTypeId?: string
  invoicingType?: string
  toBilled: boolean
}

export interface TravelTime {
  id: string
  date: string
  from: number
  employeeId: string
  dateUntil?: string
  until?: number
  openTime?: boolean
  duration?: number
}

export interface PurchaseOrder {
  id: string
  ticketId: string
  date: string
  code: string
  articleCode: string
  articleSearchtext: string
  quantity: number
  measurementUnitId: string
  measurementUnit: string
  note?: string
}

export interface Company {
  id: string
  description: string
}

export interface QualityList {
  id: string
  activityId: string
  ticketId: string
  ticketCode: string
  serialnumber: string
  serialnumberManufactor: string
  serialnumberSortkey: number
  customerId: string
  customer: string
  shippingAddressId: string
  shippingAddress: string
  articleId: string
  articleDescription: string
  location: string
  graficId?: string
}

export interface RadixProtocol {
  command: string
  destination: string
  text: string
}
