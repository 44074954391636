import { useEvent, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import api, { getApi } from '../../apis/apiCalls'
import { PutClassificationRequest } from '../../apis/types/apiRequestTypes'
import { TicketClassificationRequest } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import NoEntry from '../../components/NoEntry'
import ScrollViewForm from '../../components/ScrollViewForm'
import ClassificationSelector from '../../components/selectors/ClassificationSelector'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import {
  Classification,
  ClassificationType,
  ClassificationValue,
  EditOrCreateViewProps,
  EditOrCreateViewRef,
  TicketClassification,
  UploadStatus,
} from '../../types'
import appUtils from '../../utils/appUtils'

type Props = EditOrCreateViewProps & {
  id: string
  type: ClassificationType
  classification: TicketClassification[]
}

const EditClassificationView = ({ id, type, classification, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const initialData = useRef(classification)

  const { i18n } = useLanguage()
  const { client } = useDataProvider()
  const [state, setState] = useState(classification)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  function editClassification(body: PutClassificationRequest, abortController?: AbortController | undefined) {
    if (type === 'Ticket') return api.editTicketClassification(body as TicketClassificationRequest, abortController)
    return getApi(client).editClassification(body, abortController)
  }

  const { request: edit, loading: loadingEdit } = useRequest(editClassification)
  const { emit } = useEvent({ key: appUtils.classificationChangeEvent(type) })

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'

    if (!isEqual(initialData.current, state)) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state])

  useEffect(() => {
    if (loadingEdit === 'catched') {
      updateUploadStatus('waiting')
      return
    }

    const loadingDone = loadingEdit === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      emit()
    }
  }, [loadingEdit])

  const isEqual = (a: Classification[], b: Classification[]) => {
    let toRet = true

    a.forEach(aEl => {
      b.forEach(bEl => {
        if (aEl.id === bEl.id) {
          if (aEl.classificationId !== bEl.classificationId) {
            toRet = false
          }
        }
      })
    })

    return toRet
  }

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const prepareForUpload = (obj: Classification[]) => {
    const clone = cloneDeep(obj)

    const toRet: PutClassificationRequest = {
      fkId: id,
      type: type,
      classifications: [],
    }

    clone.forEach(el =>
      toRet.classifications.push(
        el.classificationId
          ? {
              id: el.id,
              classificationId: el.classificationId,
              classificationDefinitionId: el.definitionId,
            }
          : el.id
            ? { id: el.id }
            : // eslint-disable-next-line no-constant-condition
              {
                  classificationId: el.classificationId,
                  classificationDefinitionId: el.definitionId,
                }
              ? { id: el.id }
              : {
                  classificationId: el.classificationId,
                  classificationDefinitionId: el.definitionId,
                }
      )
    )

    return toRet
  }

  const upload = () => {
    updateUploadStatus('uploading')

    edit(prepareForUpload(state))
  }

  const handleChange = (val: ClassificationValue) => {
    if (waitingUpload === 'uploading') return

    setState(prev => {
      const toRet = prev.map(elm => {
        if (elm.definitionId === val.classificationDefinitionId) {
          return { ...elm, classificationId: val.classificationId }
        } else {
          return elm
        }
      })

      return toRet
    })
  }

  return (
    <ScrollViewForm>
      {classification.length === 0 && <NoEntry description={i18n.t('NO_CLASSIFICATION')} />}
      {classification.map(el => (
        <ClassificationSelector
          key={el.definitionId}
          definitionId={el.definitionId}
          title={el.definitionDescription}
          id={el.classificationCode}
          onChange={handleChange}
          spacing="vertical"
          isMandatory={el.definitionIsMandatory}
        />
      ))}
    </ScrollViewForm>
  )
}

export default forwardRef(EditClassificationView)
