import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import EmployeeBadge from '../../components/EmployeeBadge'
import Error from '../../components/Error'
import Text from '../../components/Text'
import { SETTINGS_BORDER_RADIUS } from '../../constants/Constants'
import useUserInfo from '../../hooks/useUserInfo'
import useUserSettings from '../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../types'

export default function UserCard() {
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { name, surname, picture, loading } = useUserInfo()

  return (
    <>
      {userSettings?.employeeId && (
        <>
          <IM.View
            spacing="vertical"
            style={[
              styles.row,
              styles.employee,
              {
                backgroundColor: theme.settings.badge.background,
              },
            ]}>
            {loading === false && name && surname ? (
              <IM.View style={[IMLayout.flex.row, styles.employeeBadgeContainer]}>
                <EmployeeBadge base64={picture} size={76} showName={false} id={userSettings.employeeId} name={`${name} ${surname}`} spacing="right" />
                <IM.View spacing="left">
                  <Text style={{ fontSize: IMStyle.typography.fontSizeMedium }}>{name}</Text>
                  <Text style={{ fontSize: IMStyle.typography.fontSizeMedium, fontWeight: IMStyle.typography.fontWeightBold }}>{surname}</Text>
                </IM.View>
              </IM.View>
            ) : (
              <IM.View style={IMLayout.flex.f1}>
                {loading === 'catched' ? (
                  <IM.View spacing="horizontal">
                    <Error />
                  </IM.View>
                ) : (
                  <IM.LoadingSpinner isVisible size="small" />
                )}
              </IM.View>
            )}
          </IM.View>
          <IM.View spacing="top" />
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  employee: { borderRadius: SETTINGS_BORDER_RADIUS, minHeight: 100, alignItems: 'center' },
  employeeBadgeContainer: { alignItems: 'center', marginLeft: 12 },
  row: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
})
