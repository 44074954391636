import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Employee } from '../../apis/types/apiResponseTypes'
import { REQUEST_EMPLOYEE } from '../../constants/Keys'
import useUserSettings from '../../hooks/useUserSettings'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: string
  mandatory?: boolean
  editable?: boolean
  spacing?: SpacingProps
  type?: 'planning' | 'followUp' | 'time'
  disableBackdropOpacity?: boolean
  onChange: (value: Employee | undefined) => void
  onDefaultFound: (id: string) => void
}

export default function EmployeeSelector({ id, type, mandatory, disableBackdropOpacity, onChange, onDefaultFound, ...props }: Props) {
  const { i18n } = useLanguage()

  const { userSettings } = useUserSettings()
  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  const { item, loadItem, loading } = useControlledLoader(api.getEmployee, { id: REQUEST_EMPLOYEE })

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    let defaultId: string | undefined

    if (loading === false && item) {
      type === 'planning' &&
        item.forEach(el => {
          if (el.isDefaultForPlanning) {
            defaultId = el.id
          }
        })

      if (defaultId === undefined && id !== undefined) {
        item.forEach(el => {
          if (el.id === id) {
            defaultId = id
          }
        })
      }

      if (defaultId === undefined && userSettings?.employeeId !== undefined) {
        item.forEach(el => {
          if (el.id === userSettings?.employeeId) {
            defaultId = userSettings?.employeeId
          }
        })
      }

      setSelectedId(defaultId)
      defaultId && onDefaultFound(defaultId)
    }
  }, [loading, item])

  const refresh = () => loadItem({})

  const getDescription = (data: Employee) => `${data.firstName} ${data.lastName} (${data.code})`

  const handleOnChange = (newValue: Employee | undefined) => {
    setSelectedId(newValue?.id)
    onChange(newValue)
  }

  const render = ({ item: data }: SectionListRenderItemInfo<Employee, ListSection<Employee>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={data.id === selectedId} text={getDescription(data)} />
  }

  const manageType = (elements: Employee[] | undefined) => {
    return elements?.filter(element => {
      switch (type) {
        case 'followUp':
          return element.isActiveForFollowupVisit
        case 'planning':
          return element.isActiveForPlanning
        case 'time':
          return element.isActiveForActivityTimes
        default:
          return true
      }
    })
  }

  const dataFilteredByType = manageType(item)

  return (
    <SelectInput
      data={appUtils.filter(dataFilteredByType ?? [], search, ['firstName', 'lastName', 'code'])}
      value={dataFilteredByType?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={i18n.t('EMPLOYEE') + (mandatory ? ' *' : '')}
      screenTitle={i18n.t('EMPLOYEES')}
      noDataMessage={i18n.t('NO_EMPLOYEE_FOUND')}
      disableReset
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={getDescription}
      deferredTimeout={0}
      disableLoadAfterMount
      disableFastInputScrollView
      disableBackdropOpacity={disableBackdropOpacity}
      {...props}
    />
  )
}
