import { IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { CheckboxOptionType, Col, Row } from 'antd'
import React, { memo, useEffect, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import { Employee } from '../../apis/types/apiResponseTypes'
import { LoadingType, ThemeColorExpanded } from '../../types'
import { plannerSelectedEmployeeAtom } from '../../utils/stateManager'
import Text from '../Text'
import PlannerCheckboxGroup from './PlannerCheckboxGroup'

type Props = {
  employees: Employee[] | undefined
  loadingEmployees: LoadingType
}

const PlannerResourceFilterEmployee = ({ employees, loadingEmployees }: Props) => {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [selectedEmployees, setSelectedEmployees] = useRecoilState(plannerSelectedEmployeeAtom(sessionKey))

  useEffect(() => {
    if (selectedEmployees === undefined && employees !== undefined && loadingEmployees === false) {
      setSelectedEmployees(employees.map(employee => employee.id))
    }
  }, [selectedEmployees, employees, loadingEmployees])

  const employeeOptions: CheckboxOptionType<string>[] = useMemo(
    () =>
      (employees ?? []).map(employee => ({
        id: employee.id,
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        style: { color: theme.text.default },
      })),
    [employees]
  )

  return (
    <Col>
      <Row style={styles.subtitle}>
        <Text style={styles.subtitleText}>{i18n.t('EMPLOYEES')}</Text>
      </Row>
      <PlannerCheckboxGroup options={employeeOptions} selected={selectedEmployees} setSelected={val => setSelectedEmployees(val)} />
    </Col>
  )
}

export default memo(PlannerResourceFilterEmployee)

const styles = StyleSheet.create({
  subtitle: { paddingBottom: 4 },
  subtitleText: { fontWeight: IMStyle.typography.fontWeightMedium },
})
