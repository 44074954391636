import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { AccessData } from '../../../apis/types/apiResponseTypes'
import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import PressableTextIcon from '../../Infominds/PressableTextIcon'
import TextWithIcon from '../../Infominds/TextWithIcon'

interface Props {
  accessGranted: boolean
  color: string
  data: AccessData
  onEdit: () => void
}

const AccessDataCardHeader = ({ accessGranted, color, data, onEdit }: Props) => {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { isOnline } = useDataProvider()

  return (
    <IM.View style={styles.titleContainer}>
      <IM.View spacing="right" style={styles.icon}>
        <IM.Icon icon={['fas', accessGranted ? 'unlock' : 'lock']} size={24} color={color} />
      </IM.View>

      <IM.View style={styles.container}>
        <IM.View spacing="left" style={IMLayout.flex.f1}>
          {data.email && (
            <TextWithIcon icon={['fal', 'envelope']} style={IMLayout.flex.f1} viewStyle={IMLayout.flex.f1}>
              {data.email}
            </TextWithIcon>
          )}
          {data.user && (
            <TextWithIcon icon={['fal', 'user']} style={IMLayout.flex.f1} viewStyle={IMLayout.flex.f1}>
              {data.user}
            </TextWithIcon>
          )}
        </IM.View>
        <IM.View>
          {accessGranted && isOnline === true && (
            <PressableTextIcon
              onPress={onEdit}
              secondary={true}
              icon={['fal', 'chevron-right']}
              alignIcon="right"
              iconSize={14}
              iconColor={theme.text.detail}
              style={styles.text}>
              {i18n.t('EDIT')}
            </PressableTextIcon>
          )}
        </IM.View>
      </IM.View>
    </IM.View>
  )
}

export default AccessDataCardHeader

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
  icon: {
    padding: 4,
  },
})
