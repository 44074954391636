import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { StackActions, useNavigation } from '@react-navigation/native'
import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useMemo, useRef } from 'react'
import { FlatList, StyleSheet } from 'react-native'

import Pressable from '../../components/Infominds/Pressable'
import useMenu from '../../hooks/useMenu'
import useUserSettings from '../../hooks/useUserSettings'
import BottomSheetModal from '../../modals/BottomSheetModal'
import { AllBottomTabRoutesParamList, TabNavigationScreen } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import navigationUtils from '../../utils/navigationUtils'

const MoreScreen = () => {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const pressedButton = useRef(false)
  const { menuItems } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { tabs, maxDynamicElements, previousRoute } = useMenu<keyof AllBottomTabRoutesParamList>()

  const data = menuItems && navigationUtils.sortTabs<keyof AllBottomTabRoutesParamList>(tabs, maxDynamicElements, menuItems, 'allWithoutFixed')

  const dataFormatted = useMemo(() => {
    const clone = cloneDeep(data)

    if (clone) {
      while (clone.length % 4 !== 0) {
        // @ts-ignore ok!
        clone.push(undefined)
      }
    }

    return clone
  }, [data])

  const Item = useCallback(
    ({ item }: { item: TabNavigationScreen<keyof AllBottomTabRoutesParamList> | undefined }) => {
      if (!item) {
        return (
          <Pressable style={styles.itemContainer} containerStyle={styles.pressableContainer} disabled>
            <IM.View
              style={[
                styles.item,
                {
                  backgroundColor: theme.moreSheet.background,
                  borderColor: theme.moreSheet.background,
                },
              ]}
            />
          </Pressable>
        )
      }

      const isSelected = item.name === (previousRoute ?? '')

      return (
        <Pressable
          style={styles.itemContainer}
          containerStyle={styles.pressableContainer}
          onPress={() => {
            pressedButton.current = true
            // @ts-ignore Main screen does not have a parameter to pass
            navigation.navigate(item.name)
          }}>
          <IM.View
            style={[
              styles.item,
              {
                backgroundColor: theme.moreSheet.navigationElement.background,
                borderColor: isSelected ? theme.moreSheet.navigationElement.selected : theme.moreSheet.navigationElement.background,
              },
            ]}>
            <IM.Icon icon={item.icon} size={item.iconSize ?? 0 * 1.4} color={isSelected ? theme.moreSheet.navigationElement.selected : undefined} />
          </IM.View>
          <IM.Text numberOfLines={1} style={[styles.text, { color: isSelected ? theme.moreSheet.navigationElement.selected : theme.text.default }]}>
            {item.title}
          </IM.Text>
        </Pressable>
      )
    },
    [previousRoute, theme]
  )

  return (
    <BottomSheetModal
      onDismiss={() => {
        pressedButton.current === false && navigation.goBack()
        pressedButton.current = false
      }}>
      <IM.View style={styles.contentContainer}>
        <Pressable
          onPress={() => {
            pressedButton.current = true
            navigation.dispatch(StackActions.replace('EditNavigation'))
          }}>
          <IM.Text>{i18n.t('REORDER')}</IM.Text>
        </Pressable>
      </IM.View>
      <FlatList
        data={dataFormatted}
        renderItem={Item}
        numColumns={4}
        horizontal={false}
        contentContainerStyle={styles.listContainer}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    </BottomSheetModal>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: 24,
    alignItems: 'flex-end',
  },
  pressableContainer: { flex: 1 },
  listContainer: {
    margin: 8,
  },
  item: {
    width: 64,
    borderWidth: 1,
    aspectRatio: 1,
    borderRadius: IMLayout.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  itemContainer: {
    flex: 1,
    borderRadius: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: { flex: 1, textAlign: 'center', textAlignVertical: 'center', fontSize: IMStyle.typography.fontSizeRegular - 1 },
})

export default MoreScreen
