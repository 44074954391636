import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { ActivityTypeGet } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_TICKET_ACTIVITY_TYPES } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  mandatory?: boolean
  onChange: (value: ActivityTypeGet | undefined) => void
  onDefaultFound: (id: string) => void
}

export default function ActivityTypeSelector({ id, mandatory, onChange, onDefaultFound, ...props }: Props) {
  const { i18n } = useLanguage()

  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState(id ?? CONSTANTS.noSelectionId)

  const { item: data, loadItem, loading } = useControlledLoader(api.getActivityType, { id: REQUEST_TICKET_ACTIVITY_TYPES })

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (id) return

    if (loading === false && data && data.length !== 0) {
      const defaultValue = data.filter(el => el.isDefault)

      if (defaultValue && defaultValue.length === 1) {
        setSelectedId(defaultValue[0].id)
        onDefaultFound(defaultValue[0].id)
      }
    }
  }, [id, loading, data])

  const refresh = () => loadItem({})

  const render = ({ item }: SectionListRenderItemInfo<ActivityTypeGet, ListSection<ActivityTypeGet>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.id === selectedId} text={ticketUtils.composeActivityString(item)} />
  }

  const handleOnChange = (newValue: ActivityTypeGet | undefined) => {
    setSelectedId(newValue?.id ?? CONSTANTS.noSelectionId)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  return (
    <SelectInput
      data={appUtils.filter(data ?? [], search, ['description', 'code', { searchStringCompositor: ticketUtils.composeActivityString }])}
      value={data?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={refresh}
      title={i18n.t('ACTIVITY_TYPE') + (mandatory ? ' *' : '')}
      screenTitle={i18n.t('ACTIVITY_TYPES')}
      noDataMessage={i18n.t('NO_ACTIVITY_TYPE')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={ticketUtils.composeActivityString}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      disableReset
      disableBackdropOpacity
      {...props}
    />
  )
}
