import { IMLayout, SpacingProps, useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, SectionListRenderItemInfo, StyleProp, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import CONSTANTS from '../../constants/Constants'
import { EDIT_CONTACT_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_CUSTOMER_CONTACTS } from '../../constants/Keys'
import ContactEditOrCreateModal from '../../modals/customer/ContactEditOrCreateModal'
import ScreenSelectModal from '../../modals/ScreenSelectModal'
import { TicketStackParamList } from '../../navigation/types'
import { ContactEditOrCreateParams, ListSection, LoadingType } from '../../types'
import CustomerContactCard from './CustomerContactCard'

export type CustomerContactIdCardProps = {
  addressId: string
  contactId: string | undefined
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
  showGender?: boolean
}

interface Props {
  noElement: Contact
  error?: boolean
  loading?: LoadingType
  onChange?: (value: Contact) => void
}

export default function CustomerContactIdCard({
  loading,
  error,
  addressId,
  contactId,
  spacing,
  style,
  changeable,
  noElement,
  showGender,
  onChange,
}: CustomerContactIdCardProps & Props) {
  const cardElemLoaded = useRef(false)
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const editOrCreateModalController = useModalController<ContactEditOrCreateParams>()

  const {
    item,
    loadItem,
    loading: loadingContacts,
    allDataLoaded,
    loadMore,
  } = useInfiniteLoader(api.getCustomerContacts, {
    chuckSize: CONSTANTS.defaultChuckSize,
    id: REQUEST_CUSTOMER_CONTACTS,
  })

  const [search, setSearch] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [contact, setContact] = useState<Contact | undefined>(undefined)

  useEvent({ key: EDIT_CONTACT_DATA_EVENT_KEY }, () => {
    refresh(undefined, true)
  })

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (!contactId) cardElemLoaded.current = true

    if (cardElemLoaded.current === false) {
      if (loadingContacts === false) {
        cardElemLoaded.current = true
        setContact(item.find(el => el.id === contactId))
      }
    }
  }, [loadingContacts, item])

  const refresh = (searchStr?: string, refreshState = false) => {
    if (isVisible) {
      loadItem({ id: undefined, addressId: addressId, searchtext: searchStr })
    } else {
      if (refreshState) cardElemLoaded.current = false
      contactId && loadItem({ id: contactId, addressId: addressId, searchtext: searchStr })
    }
  }

  const renderItem = ({ item: renderContact }: SectionListRenderItemInfo<Contact, ListSection<Contact>>, onPress?: () => void) => {
    const isFirst = item && item.length > 0 && renderContact.id === noElement.id
    const isLast = item && item.length > 0 && renderContact.id === item[item.length - 1].id

    return (
      <CustomerContactCard
        contact={renderContact}
        onPress={onPress}
        hideButtons
        selected={error ? false : contact === undefined ? renderContact.id === noElement.id : renderContact.id === contact.id}
        spacing={['horizontal', isFirst ? 'vertical' : isLast ? 'none' : 'bottom']}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginBottom: isLast ? 3 * IMLayout.verticalMargin : 0 }}
      />
    )
  }

  const handleChangeContact = (newContact: Contact | undefined) => {
    if (!newContact) return

    onChange?.(newContact)
    setContact(newContact.id === noElement.id ? undefined : newContact)
  }

  return (
    <>
      <CustomerContactCard
        contact={contact}
        leftText={i18n.t('CONTACT')}
        disabled={contact === undefined || contact.id === noElement.id}
        disableIcon
        enableChange={changeable}
        loading={loading === false ? !isVisible && addressId !== undefined && loadingContacts : loading}
        onChange={() => setIsVisible(true)}
        showGender={showGender}
        onPress={
          !error && contact !== undefined
            ? () => {
                const data: ContactEditOrCreateParams = { contact: contact, addressId: contact.addressId }

                isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                  ? navigation.navigate('TicketCustomerStack', { screen: 'CustomerDetailContactEditOrCreate', params: data })
                  : editOrCreateModalController.show(data)
              }
            : undefined
        }
        style={style}
        spacing={spacing}
      />
      <ScreenSelectModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        loading={loadingContacts}
        screenTitle={i18n.t('CONTACTS')}
        noDataMessage={i18n.t('NO_CONTACT_FOUND')}
        data={search === '' ? [noElement, ...item] : item}
        allDataLoaded={allDataLoaded}
        deferredTimeout={CONSTANTS.searchDeferredTimeout}
        onSearchChange={text => {
          setSearch(text)
          refresh(text)
        }}
        onLoadMore={loadMore}
        onChange={handleChangeContact}
        refresh={refresh}
        renderItem={renderItem}
      />
      <ContactEditOrCreateModal controller={editOrCreateModalController} />
    </>
  )
}
