import { AlertContextProps, StringUtils } from '@infominds/react-native-components'
import { Linking } from 'react-native'
import uuid from 'react-uuid'

export const utils = {
  generateUuid() {
    return uuid()
  },
  openMaps: (lat: number, lng: number, alert: AlertContextProps, errorMessage: string) => {
    Linking.openURL(`https://maps.google.com/?q=${lat},${lng}`).catch(err => alert.alert(StringUtils.stringValueReplacer(errorMessage, err)))
  },
  openMapsWithQuery: (address: string, alert: AlertContextProps, errorMessage: string) => {
    Linking.openURL(`https://maps.google.com/?q=${address}`).catch(err => alert.alert(StringUtils.stringValueReplacer(errorMessage, err)))
  },
}
