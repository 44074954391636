/* eslint-disable react-native/no-inline-styles */

import { IM, IMLayout, IMStyle, ModalController, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Platform, StyleSheet } from 'react-native'
import CountryFlag from 'react-native-country-flag'

import Pressable from '../components/Infominds/Pressable'
import TextInput from '../components/input/TextInput'
import KeyboardAware from '../components/KeyboardAware'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'

export type EditElementModalType = { value: string | undefined; title: string; id?: string; flag?: string }

interface Props {
  controller: ModalController<EditElementModalType>
  onClose: (newValue: string | undefined, id: string | undefined) => void
}

export default function EditElementModal({ controller, onClose }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const initial = useRef(controller.data?.value)
  const [text, setText] = useState(controller.data?.value)

  useEffect(() => {
    if (controller.isShown) {
      initial.current = controller.data?.value
      setText(controller.data?.value)
    }
  }, [controller.isShown])

  const disabled = initial.current === text

  return (
    <Modal
      statusBarTranslucent
      visible={controller.isShown}
      onRequestClose={controller.close}
      hardwareAccelerated={Platform.OS !== 'web'}
      transparent>
      <IM.View
        style={[IMLayout.flex.f1, { justifyContent: 'center', backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}` }]}>
        <KeyboardAware style={styles.avoidingView}>
          <IM.View
            style={[
              styles.modalView,
              {
                width: isSmallDevice ? '100%' : '90%',
                backgroundColor: appUtils.getModalBackground(colorScheme, theme),
                borderColor: theme.error,
              },
            ]}>
            <IM.View spacing="bottom" style={[IMLayout.flex.row, { justifyContent: 'space-between' }]}>
              <IM.Text style={styles.title}>{controller.data?.title}</IM.Text>
              {controller.data?.flag && <CountryFlag isoCode={controller.data?.flag} size={20} />}
            </IM.View>
            <TextInput
              value={text ?? ''}
              onChangeText={val => setText(val === '' ? undefined : val)}
              multiline
              fixMultilineHeight
              numberOfLines={12}
            />
            <IM.View style={styles.buttonsContainer} spacing="vertical">
              <Pressable style={styles.button} onPress={controller.close}>
                <IM.Text style={styles.text}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
              </Pressable>
              <Pressable
                disabled={disabled}
                style={styles.button}
                onPress={() => {
                  controller.close()
                  onClose(text, controller.data?.id)
                }}>
                <IM.Text secondary={disabled} style={[styles.text, !disabled && { color: theme.general.info }]}>
                  {i18n.t('SAVE').toUpperCase()}
                </IM.Text>
              </Pressable>
            </IM.View>
          </IM.View>
        </KeyboardAware>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalView: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingTop: 28,
    paddingBottom: 20,
    maxHeight: '80%',
    alignSelf: 'center',
  },
  button: {
    paddingHorizontal: 14,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: 38,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeMedium + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avoidingView: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})
