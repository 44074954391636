import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Language } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_LANGUAGE } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id: string | undefined
  onChange: (value: Language | undefined) => void
  editable?: boolean
  spacing?: SpacingProps
}

export default function LanguageSelector({ id, onChange, ...props }: Props) {
  const { i18n } = useLanguage()

  const [selectedId, setSelectedId] = useState(id)
  const [search, setSearch] = useState('')

  const { item: data, loadItem, loading } = useControlledLoader(api.getLanguages, { id: REQUEST_LANGUAGE })
  const noSelectionEntry: Language = { id: CONSTANTS.noSelectionId, description: i18n.t('NO_SELECTION') }

  useEffect(() => {
    loadItem()
  }, [])

  const render = ({ item }: SectionListRenderItemInfo<Language, ListSection<Language>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.id === selectedId} text={item.description ?? ''} />
  }

  const handleOnChange = (newValue: Language | undefined) => {
    setSelectedId(newValue?.id)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  return (
    <SelectInput
      data={appUtils.filter(data ? [noSelectionEntry, ...data] : [noSelectionEntry], search, ['description'])}
      value={data?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={loadItem}
      title={i18n.t('LANGUAGE')}
      screenTitle={i18n.t('LANGUAGES')}
      noDataMessage={i18n.t('NO_LANGUAGE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.description}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      disableBackdropOpacity
      {...props}
    />
  )
}
