import { CardButton, IM, IMStyle, SpacingProps, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useEffect, useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Customer } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import Text from '../../components/Text'
import { REQUEST_CUSTOMER } from '../../constants/Keys'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'
import { utils } from '../../utils/utils'

type Props = {
  customerId: string
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  onPress?: (customer: Customer) => void
}

const CustomerDetailIdCard = memo(function CustomerDetailIdCard({ customerId, spacing, style, onPress }: Props) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const { item: customer, loadItem: loadCustomer, loading } = useControlledLoader(api.getCustomer, { id: REQUEST_CUSTOMER })

  useEffect(() => {
    loadCustomer({ id: customerId })
  }, [customerId])

  const customerFound = customer !== undefined && customer.length !== 0
  const blocked = customerFound ? customerUtils.isBlocked(customer.at(0)) : false
  const inactive = customerFound ? customer?.at(0)?.inactive : false
  const phone = customer?.at(0)?.phone?.at(0)
  const buttons: CardButton[] = []
  const street = useMemo(() => (customerFound ? customerUtils.formatAddress(customer[0], 'street') : ''), [customer])
  const location = useMemo(() => (customerFound ? customerUtils.formatAddress(customer[0], 'location') : ''), [customer])

  const handlePhonePress = () => {
    phone && appUtils.openPhone(phone.number, alert, i18n)
  }

  const handleMapPress = () => {
    if (customer === undefined || customer[0] === undefined) return

    if (customer[0].mapLat !== 0 && customer[0].mapLong !== 0) {
      utils.openMaps(customer[0].mapLat, customer[0].mapLong, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    } else {
      const address = `${customerUtils.formatAddress(customer[0], 'street')} - ${customerUtils.formatAddress(customer[0], 'location')}`
      utils.openMapsWithQuery(address, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
    }
  }

  if (phone) {
    buttons.push({
      icon: ['fal', 'phone'],
      ...appUtils.getCardButtonColor(!(inactive === undefined || inactive), theme),
      overrideDisabled: true,
      onPress: inactive === undefined || inactive ? undefined : () => handlePhonePress(),
    })
  }

  if ((street !== '' && location !== '') || (customer && customer[0].mapLat !== 0 && customer[0].mapLong !== 0)) {
    buttons.push({
      icon: ['fal', 'route'],
      ...appUtils.getCardButtonColor(!(inactive === undefined || inactive), theme),
      overrideDisabled: true,
      onPress: inactive === undefined || inactive ? undefined : () => handleMapPress(),
    })
  }

  return (
    <IM.Card
      head={<CardLeftTitle text={i18n.t('CUSTOMER')} />}
      buttons={{ buttons }}
      onPress={() => customer && onPress?.(customer[0])}
      spacing={spacing}
      style={style}>
      {loading !== false ? (
        <>
          <SkeletonText width="70%" />
          <SkeletonText spacing="top" width="100%" />
        </>
      ) : (
        <>
          {customerFound ? (
            <>
              <IM.View spacing="bottom">
                <IM.Text primary style={{ fontSize: IMStyle.typography.fontSizeMedium }}>
                  {customer[0]?.description + (customer[0]?.number ? ` (${customer[0]?.number})` : '')}
                </IM.Text>
              </IM.View>
              {phone && phone.number !== '' && (
                <IM.View spacing="bottom">
                  <Text>{phone?.number}</Text>
                </IM.View>
              )}
              <IM.View spacing={blocked ? 'bottom' : 'none'}>
                {street && <IM.Text>{street}</IM.Text>}
                {location && <IM.Text>{location}</IM.Text>}
              </IM.View>
              {blocked && (
                <IM.View>
                  <TextWithIcon icon={['fas', 'user-slash']} color={theme.general.error} style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>
                    {i18n.t('CUSTOMER_BLOCKED')}
                  </TextWithIcon>
                </IM.View>
              )}
            </>
          ) : (
            <IM.Text secondary>{i18n.t('NO_CUSTOMER_SELECTED')}</IM.Text>
          )}
        </>
      )}
    </IM.Card>
  )
})

export default CustomerDetailIdCard
