import { useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import { TextInputRef, ThemeColorExpanded } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type NoteInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    onSend: () => void
  }

const MessageInput = memo(
  forwardRef(function NoteInput(
    { title, titleFontWeight, details, editable, error, loading, disableFocus, onSend, ...textInputProps }: NoteInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { theme } = useTheme<ThemeColorExpanded>()

    return (
      <BaseTextInputProvider editable={editable} error={error} disableFocus={disableFocus}>
        <BaseTextInput ref={ref} onSubmitEditing={onSend} {...textInputProps}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} />}

          {typeof error !== 'boolean' && error?.value && <BaseTextInput.Error message={error.message} />}
          <BaseTextInput.ExternalIcon>
            {loading ? (
              <BaseTextInputSpinningIcon loading={loading} />
            ) : (
              <PressableIcon
                icon={['fal', 'paper-plane']}
                color={theme.text.default}
                size={17}
                disabled={textInputProps.value === undefined || textInputProps.value === ''}
                onPress={onSend}
                style={[styles.send, { backgroundColor: theme.inputBox.background.active }]}
              />
            )}
          </BaseTextInput.ExternalIcon>
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default MessageInput

const styles = StyleSheet.create({
  send: {
    borderRadius: 50,
    paddingRight: 1,
  },
})
