import { IM, IMLayout, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import { Customer } from '../apis/types/apiResponseTypes'
import useLayout from '../hooks/useLayout'
import EditOpeningHoursModal from '../modals/EditOpeningHoursModal'
import appUtils from '../utils/appUtils'
import customerUtils from '../utils/customerUtils'
import { utils } from '../utils/utils'
import DynamicView, { DynamicViewProps } from './Infominds/DynamicView'
import PressableTextIcon from './Infominds/PressableTextIcon'
import { SCREEN_CONSTANTS } from './screen/constants/constants'
import Text from './Text'

interface Props extends Pick<DynamicViewProps, 'flexLimitLargeDevice'> {
  customer: Customer
}

const ContactInformation = ({ customer, ...others }: Props) => {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const { isSmallDevice } = useLayout()

  const email = customer.email?.at(0)?.address
  const phone = customer.phone?.at(0)?.number
  const address = `${customerUtils.formatAddress(customer, 'street')} - ${customerUtils.formatAddress(customer, 'location')}`

  const [shownMore, setShownMore] = useState(true)

  const controller = useModalController()

  const commonStyle = {
    pressableStyle: { borderRadius: SCREEN_CONSTANTS.headerRadius },
  }

  const showAllData = isSmallDevice ? shownMore : true

  return (
    <>
      <DynamicView style={[{ padding: IMLayout.horizontalMargin }]} {...others}>
        <IM.View style={[IMLayout.flex.f3, styles.info]}>
          <PressableTextIcon
            icon={['fal', 'map-location-dot']}
            iconSize={20}
            onPress={() => {
              if (customer.mapLat === 0 || customer.mapLong === 0) {
                utils.openMapsWithQuery(address, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
              } else {
                utils.openMaps(customer.mapLat, customer.mapLong, alert, i18n.t('OPEN_MAP_LINK_ERROR'))
              }
            }}
            style={[styles.common]}
            {...commonStyle}>
            {address}
          </PressableTextIcon>
        </IM.View>
        {customer?.url && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'globe']}
              iconSize={20}
              disabled={customer.url === undefined}
              onPress={() => customer.url && appUtils.openUrl(customer.url, alert, i18n.t('OPEN_URL_ERROR'))}
              numberOfLines={1}
              style={[styles.common, { color: customer.url === undefined ? theme.text.detail : theme.text.default }]}
              {...commonStyle}>
              {customer.url ?? i18n.t('MISSING_URL')}
            </PressableTextIcon>
          </IM.View>
        )}
        {showAllData && email && email !== '' && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'envelope']}
              iconSize={20}
              disabled={email === undefined}
              onPress={() => email && appUtils.openEmail(alert, i18n.t('OPEN_EMAIL_ERROR'), email)}
              numberOfLines={1}
              style={[styles.common, { color: email === undefined ? theme.text.detail : theme.text.default }]}
              {...commonStyle}>
              {email}
            </PressableTextIcon>
          </IM.View>
        )}
        {showAllData && customer.agentName && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'user']}
              iconSize={20}
              numberOfLines={1}
              style={[styles.common, { color: email === undefined ? theme.text.detail : theme.text.default }]}
              selectable
              {...commonStyle}>
              {customer.agentName}
            </PressableTextIcon>
          </IM.View>
        )}
        {showAllData && phone && phone !== '' && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'phone']}
              iconSize={20}
              disabled={phone === undefined}
              onPress={() => phone && appUtils.openPhone(phone, alert, i18n)}
              numberOfLines={1}
              style={[styles.common, { color: phone === undefined ? theme.text.detail : theme.text.default }]}
              {...commonStyle}>
              {phone}
            </PressableTextIcon>
          </IM.View>
        )}
        {showAllData && (
          <IM.View style={[IMLayout.flex.f3, styles.info]}>
            <PressableTextIcon
              icon={['fal', 'clock']}
              iconSize={20}
              onPress={() => controller.show()}
              style={[styles.common, { color: customer.openingHours === undefined ? theme.text.detail : theme.text.default }]}
              {...commonStyle}>
              {customer.openingHours ?? i18n.t('MISSING_OPENING_HOURS')}
            </PressableTextIcon>
          </IM.View>
        )}
        {isSmallDevice && (
          <IM.View style={[IMLayout.flex.f6, styles.info]}>
            <PressableTextIcon
              icon={['fal', shownMore ? 'chevron-up' : 'chevron-down']}
              onPress={() => setShownMore(prev => !prev)}
              style={styles.button}
              containerStyle={styles.buttonContainer}
              pressableStyle={styles.buttonPressable}
              viewStyle={styles.buttonView}
              hitSlop={14}
              secondary>
              <Text secondary>{shownMore ? i18n.t('LESS') : i18n.t('MORE')}</Text>
            </PressableTextIcon>
          </IM.View>
        )}
      </DynamicView>
      <EditOpeningHoursModal controller={controller} customer={customer} />
    </>
  )
}

const styles = StyleSheet.create({
  common: { width: 0, flexGrow: 1 },
  info: { justifyContent: 'center' },
  button: { padding: 0 },
  buttonContainer: { alignItems: 'center' },
  buttonPressable: { width: 100 },
  buttonView: { alignSelf: 'center' },
})

export default ContactInformation
