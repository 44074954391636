import { IM, IMLayout, SpacingProps, useDimensions, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import DynamicView from '../../components/Infominds/DynamicView'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import QualityInput from '../../components/input/QualityInput'
import QualityCheck, { QualityCheckState } from '../../components/QualityCheck'
import QualityTarget from '../../components/QualityTarget'
import Text from '../../components/Text'
import useLayout from '../../hooks/useLayout'
import useQualityCheck from '../../hooks/useQualityCheck'
import EditElementModal, { EditElementModalType } from '../../modals/EditElementModal'
import { CharacteristicsModified } from '../../types'
import qualityUtils from '../../utils/QualityUtils'

interface Props {
  characteristic: CharacteristicsModified
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

function QualityCheckCard({ characteristic, spacing, style }: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { isPortrait } = useLayout()
  const { isSmallDevice } = useDimensions()
  const { getQualityValue, setQualityNote } = useQualityCheck()
  const controller = useModalController<EditElementModalType>()
  const note = getQualityValue(characteristic.qualityCharacteristicId, characteristic.serialnumberId, 'note')

  const handleState = (value: string | undefined): QualityCheckState => {
    if (
      characteristic.qualityCharacteristicCheck === undefined ||
      characteristic.qualityCharacteristicCheck.targetCompare === false ||
      ((characteristic.qualityCharacteristicFieldTyp === 'Text' || characteristic.qualityCharacteristicFieldTyp === 'Selection') &&
        characteristic.qualityCharacteristicCheck.targetText === undefined)
    ) {
      return undefined
    }

    return qualityUtils.qualityTargetComparisonCheck(characteristic.qualityCharacteristicFieldTyp, value, characteristic.qualityCharacteristicCheck)
  }

  const handleNotePress = () => controller.show({ value: note, title: i18n.t('EDIT_NOTE') })

  const forceLarge = !isSmallDevice && !isPortrait

  return (
    <>
      <IM.Card spacing={spacing} noContentSpacing style={style}>
        <IM.View style={styles.container}>
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
            <QualityCheck
              state={handleState(getQualityValue(characteristic.qualityCharacteristicId, characteristic.serialnumberId, 'value'))}
              spacing="right"
            />
            <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1, styles.header]}>
              <Text>{characteristic.qualityCharacteristicDescription + (characteristic.qualityCharacteristicCheck?.isMandatory ? ' *' : '')}</Text>
              {characteristic.qualityCharacteristicCheck?.targetCompare && (
                <QualityTarget check={characteristic.qualityCharacteristicCheck} type={characteristic.qualityCharacteristicFieldTyp} />
              )}
            </IM.View>
          </IM.View>
          <IM.View style={styles.serialNumber}>
            <TextWithIcon icon={['fal', 'barcode']} secondary>
              {characteristic.serialnumber}
              {characteristic.serialnumberManufacturer ? ` (${characteristic.serialnumberManufacturer})` : ''}
            </TextWithIcon>
          </IM.View>
          <DynamicView forceLayout={forceLarge ? 'large' : 'small'}>
            <Pressable
              // eslint-disable-next-line react-native/no-inline-styles
              style={[IMLayout.flex.f6, { marginTop: 6, marginRight: forceLarge ? 8 : 0 }]}
              onPress={handleNotePress}
              hitSlop={{ top: 24, bottom: 10 }}>
              <TextWithIcon
                icon={['fal', 'note-sticky']}
                secondary={!!note}
                style={[IMLayout.flex.f1, { color: note ? theme.text.detail : theme.text.placeholder }]}
                iconColor={theme.text.detail}>
                {note === undefined || note === '' ? i18n.t('PRESS_TO_ADD_A_NOTE') : note}
              </TextWithIcon>
            </Pressable>
            {/* eslint-disable-next-line react-native/no-inline-styles */}
            <IM.View style={[IMLayout.flex.f6, { marginTop: 6, marginLeft: forceLarge ? 8 : 0 }]}>
              <QualityInput
                type={characteristic.qualityCharacteristicFieldTyp}
                selectorTitle={characteristic.qualityCharacteristicDescription}
                qualityCharacteristicId={characteristic.qualityCharacteristicId}
                qualityCharacteristicDetails={characteristic.qualityCharacteristicDetails}
                qualityCharacteristicDecimal={characteristic.qualityCharacteristicDecimal}
                serialNumberId={characteristic.serialnumberId}
              />
            </IM.View>
          </DynamicView>
        </IM.View>
      </IM.Card>
      <EditElementModal
        controller={controller}
        onClose={newNote => {
          setQualityNote({ characteristicsId: characteristic.qualityCharacteristicId, note: newNote, serialNumberId: characteristic.serialnumberId })
          controller.close()
        }}
      />
    </>
  )
}

export default memo(QualityCheckCard)

const styles = StyleSheet.create({
  container: {
    margin: 6,
    justifyContent: 'center',
  },
  header: {
    justifyContent: 'space-between',
  },
  serialNumber: {
    paddingTop: 2,
  },
})
