import { IMLayout, StringUtils, useAlert, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import {
  Asset,
  BackendAsset,
  MEDIA_FOCUS_EFFECT_EVENT,
  MediaSortingMethod,
  mediaUtils,
  MediaView,
  MediaViewProps,
  useAsset,
} from '@infominds/react-native-media'
import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Platform, StyleProp, useWindowDimensions, ViewStyle } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import api from '../../apis/apiCalls'
import { File } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import {
  INFOBOX_FOLDER_EVENT_KEY,
  INFOBOX_NEW_INFO_EVENT_KEY,
  REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY,
  REFRESH_INFOBOX_EVENT_KEY,
} from '../../constants/EmitterKeys'
import { REQUEST_INFOBOX_FILES_ASSET_VIEW } from '../../constants/Keys'
import useLayout from '../../hooks/useLayout'
import UploadModal, { UploadModalData } from '../../modals/UploadModal'
import { InfoboxStackParamList } from '../../navigation/types'
import { FolderRight, InfoboxInfoEvent, InfoboxType, ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import { infoboxAssetToSyncAtom, mediaSortingMethodAtom } from '../../utils/stateManager'

export interface InfoboxMediaViewImperativeMethods {
  handleUpload: () => void
}

interface Props extends Pick<MediaViewProps, 'contentContainerStyle'> {
  id: string
  infoboxType: InfoboxType
  folderRight: FolderRight
  folderNumber: number
  masterDetailFiles?: File[]
  buttonStyle?: StyleProp<ViewStyle>
}

const InfoboxMediaView = (
  { folderNumber, folderRight, buttonStyle, infoboxType, id, masterDetailFiles, contentContainerStyle }: Props,
  ref: ForwardedRef<InfoboxMediaViewImperativeMethods>
) => {
  useImperativeHandle(ref, () => ({
    handleUpload: () => {
      handleUpload()
    },
  }))

  const {
    item: loadedFiles,
    loadItem: loadFiles,
    loading: loadingFiles,
  } = useControlledLoader(api.getInfoboxFiles, {
    id: REQUEST_INFOBOX_FILES_ASSET_VIEW,
  })

  const isMasterDetail = masterDetailFiles !== undefined

  const { alert } = useAlert()
  const { isSmallDevice } = useLayout()
  const { width } = useWindowDimensions()
  const { i18n, language } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { assets: selectedAsset, set: setSelectedAsset } = useAsset()
  const { url, bearerToken, sessionKey } = useAuthentication()
  const navigation = useNavigation<NavigationProp<InfoboxStackParamList>>()

  const sortingMethod = useRef<MediaSortingMethod>('reverse')
  const uploadModal = useModalController<UploadModalData>()

  const [loading, setLoading] = useState(false)
  const [assetUploaded, setAssetUploaded] = useState(0)
  const [assetToSync, setAssetsToSync] = useRecoilState(infoboxAssetToSyncAtom)
  const displayMethod = useRecoilValue(mediaSortingMethodAtom(sessionKey))

  const { emit } = useEvent({ key: INFOBOX_FOLDER_EVENT_KEY })
  const { emit: refreshInfoboxButtonCounter } = useEvent({ key: REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY })
  const { emit: refreshAnimation } = useEvent({ key: MEDIA_FOCUS_EFFECT_EVENT })
  useEvent({ key: REFRESH_INFOBOX_EVENT_KEY }, () => {
    refresh()
  })
  useEvent<InfoboxInfoEvent>({ key: INFOBOX_NEW_INFO_EVENT_KEY }, data => {
    if (!(data.infoboxTyp === infoboxType)) return

    setSelectedAsset(prev => {
      prev.map(el => {
        if (el.filename === data.filename && el.id === data.id) {
          el.note = data.note
          return el
        }
        return el
      })

      return [...prev]
    })
  })

  useFocusEffect(
    React.useCallback(() => {
      refreshAnimation()
    }, [])
  )

  useEffect(() => {
    handleAssetsChange()
  }, [selectedAsset])

  useEffect(() => {
    refresh()
  }, [infoboxType, id, folderNumber])

  useEffect(() => {
    const asset = masterDetailFiles ?? loadedFiles
    if ((isSmallDevice ? loadingFiles === false : true) && asset) createMediaAssets(asset)
  }, [masterDetailFiles, loadedFiles, loadingFiles])

  useEffect(() => {
    if (assetToSync !== 0 && assetUploaded === assetToSync) {
      reset()
      emit()
      refreshInfoboxButtonCounter()
      refresh()
      setTimeout(() => {
        uploadModal.close()
      }, 1000)
    }
  }, [assetUploaded, assetToSync])

  const refresh = () => {
    if (isMasterDetail) return

    setLoading(true)
    loadFiles({ infoboxTyp: infoboxType, id: id, folderNumber: folderNumber })
  }

  const createMediaAssets = (assets: File[]) => {
    const promises: Promise<Asset>[] = []

    mediaUtils.sortByDate(assets, sortingMethod.current).forEach(file => {
      if (selectedAsset.length !== 0 || selectedAsset.find(elem => elem.origin === 'backend' && elem.id === file.id) === undefined) {
        if (!!file.thumbImageAsPNG || file.thumbImageAsPNGError) {
          promises.push(
            BackendAsset.Create(
              'image',
              file.id,
              file.filename,
              undefined,
              file.thumbImageAsPNG,
              file.sizeKB * 1000,
              file.canDelete,
              new Date(file.date),
              file.note,
              file.thumbImageAsPNGError,
              false
            )
          )
        } else {
          promises.push(
            BackendAsset.Create(
              mediaUtils.getAssetType(file.extension ?? ''),
              file.id,
              file.filename,
              undefined,
              undefined,
              file.sizeKB * 1000,
              file.canDelete,
              new Date(file.date),
              file.note,
              false,
              false
            )
          )
        }
      }
    })

    Promise.all(promises)
      .then(setSelectedAsset)
      .catch(err => console.error('Can not create backend asset', err))
      .finally(() => setLoading(false))
  }

  const handleAssetsChange = () => {
    setAssetUploaded(0)
    setAssetsToSync(mediaUtils.getAssetNumberToSync(selectedAsset))
  }

  const handleUpload = () => {
    console.debug(`Upload preparation result: files to sync ${assetToSync}`)
    upload()
  }

  const reset = () => {
    setAssetsToSync(0)
    setSelectedAsset([])
  }

  function upload() {
    uploadModal.show({ total: assetToSync })

    selectedAsset.forEach(asset => {
      if (asset.origin === 'backend' && asset.markedForRemoval) {
        api
          .deleteInfoboxFile({ id: asset.id, infoboxTyp: infoboxType })
          .catch(err => alert(i18n.t('ERROR'), StringUtils.stringValueReplacer(i18n.t('ERROR_DELETE_ASSET'), appUtils.getBackendErrorMessage(err))))
          .finally(() => setAssetUploaded(prev => prev + 1))
      }

      if (asset.origin !== 'backend') {
        asset
          .getBase64()
          .then(data => {
            if (typeof data === 'string') {
              api
                .postInfoboxFile({
                  file: data,
                  infoboxTyp: infoboxType,
                  filename: asset.filename,
                  id: id,
                  note: asset.note,
                  folderNumber: folderNumber ?? 0,
                })
                .catch(err => {
                  const message = appUtils.getBackendErrorMessage(err)
                  alert(i18n.t('ERROR'), StringUtils.stringValueReplacer(i18n.t('ERROR_CREATE_ASSET'), message))
                })
                .finally(() => setAssetUploaded(prev => prev + 1))
            }
          })
          .catch(err => {
            console.error(`Failed compressing asset ${asset.filename} -`, err)
            alert(i18n.t('ERROR'), StringUtils.stringValueReplacer(i18n.t('ASSET_COMPRESSION_ERROR'), asset.filename))
            setAssetUploaded(prev => prev + 1)
          })
      }
    })
  }

  if (!url || !bearerToken) return <></>

  return (
    <>
      <MediaView
        loading={isMasterDetail === false && loading === true}
        onMarkForRemoval={handleAssetsChange}
        language={language}
        displayMethod={displayMethod}
        sortingMethod={sortingMethod.current}
        folderRight={folderRight === FolderRight.read ? 'read' : 'write'}
        buttonStyle={{
          backgroundColor: theme.header.main.background,
          iconColor: theme.header.main.text.primary,
          disabledBackgroundColor: theme.infobox.disableButtonBackground,
          disabledIconColor: theme.infobox.disableButtonIcon,
        }}
        buttonContainerStyle={[{ padding: IMLayout.horizontalMargin }, buttonStyle]}
        onCameraPress={() => navigation.navigate('InfoboxNoBottomTabCamera')}
        onAssetPress={assetId =>
          navigation.navigate('InfoboxNoBottomTabAsset', { id: assetId, infoboxType, origin: selectedAsset.find(el => el.id === assetId)?.origin })
        }
        onAssetInfo={assetId =>
          navigation.navigate('InfoboxNoBottomTabAssetInfo', {
            id: assetId,
            infoboxType,
            origin: selectedAsset.find(el => el.id === assetId)?.origin,
          })
        }
        contentContainerStyle={
          contentContainerStyle ?? {
            paddingRight: Platform.OS === 'web' && selectedAsset.length !== 0 ? IMLayout.horizontalMargin * 2.5 : undefined,
            paddingHorizontal: IMLayout.horizontalMargin,
            paddingVertical: IMLayout.horizontalMargin - 4,
          }
        }
        estimatedItemSize={width}
        fetchUnmanagedAsset={{
          headers: { Authorization: bearerToken },
          url: assetId => {
            const completeUrl = `${url}/api/common/infoboxfileonly?infoboxTyp=${infoboxType}&infoboxFileId=${assetId}`
            return completeUrl
          },
        }}
      />
      <UploadModal controller={uploadModal} current={assetUploaded} />
    </>
  )
}

export default forwardRef(InfoboxMediaView)
