import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import SkeletonText from './SkeletonText'

const SKELETON_ELEM = 35

interface Props {
  elementStyle?: StyleProp<ViewStyle>
}

export default function SkeletonScrollView({ elementStyle }: Props) {
  return (
    <>
      {Array(SKELETON_ELEM)
        .fill(0)
        .map((_, index) => {
          const isFirst = index === 0
          const marginHorizontal = 2 * IMLayout.horizontalMargin
          const marginTop = isFirst ? 2 * IMLayout.verticalMargin : 0
          const marginBottom = 1.4 * IMLayout.verticalMargin

          return (
            <IM.View
              key={`SkeletonScrollView-${index}`}
              style={[
                {
                  marginHorizontal,
                  marginTop,
                  marginBottom,
                },
                elementStyle,
              ]}>
              <SkeletonText width="100%" />
            </IM.View>
          )
        })}
    </>
  )
}
