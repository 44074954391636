import { IM, IMLayout } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { memo, useState } from 'react'
import { StyleSheet } from 'react-native'

import BlobImage from './BlobImage'
import Error from './Error'

interface Props {
  id: string
}

const TicketArticleGraphic = memo(function TicketArticleGraphic({ id }: Props) {
  const { url } = useAuthentication()

  const [error, setError] = useState(false)
  const [width, setWidth] = useState(0)

  if (!url) return <></>

  const uri = `${url}/api/common/grafic?graficId=${id}`
  return (
    <IM.View style={styles.container} onLayout={e => setWidth(e.nativeEvent.layout.width * 0.9)}>
      {error ? <Error /> : <BlobImage uri={uri} width={width} height={width} onError={setError} style={{ borderRadius: IMLayout.borderRadius }} />}
    </IM.View>
  )
})

export default TicketArticleGraphic

const styles = StyleSheet.create({
  container: {
    marginRight: 8,
  },
})
