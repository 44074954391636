import { IM, IMLayout, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { Message } from '../apis/types/apiResponseTypes'
import MessageHtmlViewerModal from '../modals/common/MessageHtmlViewerModal'
import { TicketStackParamList } from '../navigation/types'
import { ThemeColorExpanded } from '../types'
import TimeUtils from '../utils/TimeUtils'
import EmployeeBadge from './EmployeeBadge'
import PressableIcon from './Infominds/PressableIcon'
import { TriangleCorner } from './TriangularCorner'

interface Props {
  message: Message
  position: 'right' | 'left'
  backgroundColor: string
  selected?: boolean
  deleteDisabled: boolean
  onDeleted: (id: string) => void
}

export default function MessageComponent({ message, backgroundColor, position, selected, deleteDisabled, onDeleted }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const controller = useModalController()

  const author = useMemo(() => message.contact ?? message.employee, [message])

  if (!author) {
    console.error('Missing contact or employee:', JSON.stringify(message, undefined, 2))
    return <></>
  }

  const justifyContent = position === 'right' ? 'flex-end' : 'flex-start'
  const selectedBackgroundColor = colorScheme === 'light' ? '#F5F5F5' : '#2F2F2F'
  const navigateToInfobox = () =>
    navigation.navigate('TicketInfoboxStack', {
      screen: 'InfoboxFolders',
      params: {
        id: message.id,
        type: 'SmNote',
      },
    })

  return (
    <>
      <IM.View
        style={[
          IMLayout.flex.row,
          styles.container,
          { justifyContent },
          selected && { backgroundColor: selectedBackgroundColor, ...styles.messageSelected },
        ]}>
        {position === 'left' && (
          <>
            <EmployeeBadge name={author.description} id={author.id} style={styles.employeeBadge} />
            <TriangleCorner color={backgroundColor} position="left" />
          </>
        )}
        {position === 'right' && selected && (
          <PressableIcon
            icon={['fal', 'trash']}
            color={theme.general.error}
            size={18}
            style={styles.trashCan}
            disabled={deleteDisabled}
            onPress={() => onDeleted(message.id)}
          />
        )}
        <IM.View style={[position === 'right' ? styles.rightChat : styles.leftChat, styles.chatBubble, { backgroundColor: backgroundColor }]}>
          <IM.View style={styles.chatContent}>
            <IM.View style={styles.titleContainer}>
              <IM.Text style={[styles.chatAuthor, { textAlign: position }]}>{author.description}</IM.Text>
              <Pressable style={styles.infoboxPressable} hitSlop={5} onPress={navigateToInfobox}>
                <IM.Icon icon={['fal', 'photo-film-music']} color={theme.text.detail} size={16} />
                {!!message.infoboxFileCount && <IM.Text style={[styles.infoboxText, { color: theme.text.detail }]}>1+</IM.Text>}
              </Pressable>
            </IM.View>
            <IM.Text>{message.noteNoHtml}</IM.Text>
            <IM.View style={styles.chatTimeContainer}>
              {message.htmlContainImages && (
                <Pressable style={styles.showTextContainer} hitSlop={{ top: 4, bottom: 10 }} onPress={() => controller.show()}>
                  <IM.Text style={styles.showText}>{i18n.t('SHOW_FULL_MESSAGE')}</IM.Text>
                </Pressable>
              )}
              <IM.Text secondary style={styles.chatTime}>
                {TimeUtils.formatTime(dayjs(message.date).toDate(), false)}
              </IM.Text>
            </IM.View>
          </IM.View>
        </IM.View>
        {position === 'right' && (
          <>
            <TriangleCorner color={backgroundColor} position="right" />
            <EmployeeBadge name={author.description} id={author.id} style={styles.employeeBadge} />
          </>
        )}
      </IM.View>
      <MessageHtmlViewerModal htmlMessage={message.note} controller={controller} />
    </>
  )
}

const styles = StyleSheet.create({
  messageSelected: { marginHorizontal: 0, paddingHorizontal: 12 },
  container: { marginHorizontal: 12, flex: 1, marginTop: 2 },
  employeeBadge: { alignItems: 'flex-start' },
  leftChat: {
    borderTopLeftRadius: 0,
  },
  rightChat: {
    borderTopRightRadius: 0,
  },
  chatContent: {
    padding: 6,
    paddingLeft: 10,
    paddingRight: 12,
    width: '100%',
  },
  chatBubble: {
    borderRadius: 10,
    flexDirection: 'row',
    maxWidth: '75%',
  },
  chatAuthor: {
    fontWeight: '600',
    paddingBottom: 3,
  },
  chatTimeContainer: { alignItems: 'flex-end', flex: 1, marginTop: 2, marginBottom: -2, marginRight: -5 },
  chatTime: {
    fontSize: 8,
  },
  trashCan: { flex: 1, justifyContent: 'center', alignItems: 'flex-start' },
  infoboxPressable: { marginLeft: 8, flexDirection: 'row', alignItems: 'center' },
  titleContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  showText: { fontSize: 10 },
  showTextContainer: { justifyContent: 'center', alignItems: 'center' },
  infoboxText: { marginLeft: 3, fontSize: 11 },
})
