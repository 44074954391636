import { StringUtils, Utils } from '@infominds/react-native-components'

import { DataProviderCore } from '../DataProviderCore'
import { DataProviderSettings } from '../DataProviderSettings'
import { DataStorage } from '../DataStorage'
import { AssignedIdPair, DataProviderOptions, DataProviderStateActions, PendingRequest, RequestType } from '../types'
import { DataProviderFileHandler } from './DataProviderFileHandler'
import DataProviderUtils from './DataProviderUtils'

export class DataProviderSyncManager {
  private dataStorage: DataStorage
  private core: DataProviderCore
  private upSyncWaitList: ((value: boolean) => void)[] = []

  private autoSyncTried = false
  private upSyncBusy = false

  constructor(dataProvider: DataProviderCore, dataStorage: DataStorage) {
    this.core = dataProvider
    this.dataStorage = dataStorage
  }

  public Init() {
    this.dataStorage.RegisterResource(DataProviderSettings.PendingRequestsResourceKey, { id: ['id'] })
    this.dataStorage.RegisterResource(DataProviderSettings.IdPairResourceKey, { id: ['id'] })
  }

  public GetPendingRequests(id?: string) {
    return this.dataStorage.Get<PendingRequest>(DataProviderSettings.PendingRequestsResourceKey, id ? { id } : {}, result =>
      result.sort({ timestamp: 1 })
    )
  }

  public async PendingRequestsExits() {
    const pendingRequestsCount = await this.dataStorage.Count(DataProviderSettings.PendingRequestsResourceKey)
    this.core.DispatchState(DataProviderStateActions.UpdateDataToSync, { pendingDataToSync: pendingRequestsCount })

    if (pendingRequestsCount > 0) {
      return true
    }

    this.core.DispatchState(DataProviderStateActions.ClearError)
    return false
  }

  public GetResourceBySyncType(syncType: string) {
    return this.core.resources
      .GetAll()
      .find(r => !!r.syncOptions?.syncType && r.syncOptions.syncType.find(st => StringUtils.compareStrings(st, syncType)))
  }

  /**
   * Checks whether any pending requests are yet to be synced
   */
  public async CheckPendingUpSync() {
    //if upSync is not already active and there are no pending requests then return true
    if (!this.upSyncBusy && !(await this.PendingRequestsExits())) return true
    //if upSync is already active wait for it to be done and return the result
    if (this.upSyncBusy) return await this.WaitForUpSyncDone()

    if (!this.core.options.autoSync || !this.core.state.isOnline || this.autoSyncTried) return false
    try {
      this.autoSyncTried = true
      await this.ProcessPendingRequests(true)
      return !(await this.PendingRequestsExits())
    } catch (exception) {
      /* empty */
    }
    return false
  }

  private WaitForUpSyncDone() {
    return new Promise<boolean>(resolve => {
      if (!this.upSyncBusy) {
        resolve(true)
        return
      }
      this.upSyncWaitList.push(resolve)
    })
  }

  public getResourceIds<T = string>(resource: string) {
    const resourceIds = this.dataStorage.GetStorageByKey(resource)?.dataIds
    if (!resourceIds?.length) throw new Error(`Resource '${resource}' has no valid id configuration`)
    return resourceIds as T extends string ? string[] : (keyof T)[]
  }

  public comparePayloads<T extends object>(resourceIds: (keyof T)[], payloadA: T, payloadB: T) {
    for (const key of resourceIds) {
      if (payloadA[key] !== payloadB[key]) return false
    }
    return true
  }

  public async AddRequestToPendingRequests<TData extends object, TGetRequest = void>(
    type: RequestType,
    resource: string,
    apiResource: string,
    payload: TData,
    options?: DataProviderOptions<TData, TGetRequest>
  ) {
    try {
      this.core.SetOnline(false)
      //if merge pending requests is active, check first if data was created offline and if a merge of pending requests is possible
      if (options?.syncOptions?.mergePendingRequests && type !== 'POST') {
        try {
          const isOfflineData = this.IsOfflineData(payload)
          if (isOfflineData) {
            const resourceIds = this.getResourceIds<TData>(resource)
            const pendingRequests = (await this.dataStorage.Get<PendingRequest>(DataProviderSettings.PendingRequestsResourceKey)).filter(q =>
              this.comparePayloads(resourceIds, q.payload, payload)
            )
            // if pendingRequests length is > 1 then it means previous merges have failed
            if (pendingRequests.length === 1) {
              const targetRequest = pendingRequests[0]
              if (type === 'DELETE') {
                await this.dataStorage.Delete(DataProviderSettings.PendingRequestsResourceKey, [targetRequest])
                return
              } else {
                if (type === 'PATCH') {
                  targetRequest.payload = { ...targetRequest.payload, ...payload }
                } else if (type === 'PUT') {
                  targetRequest.payload = payload
                }
                await this.dataStorage.Update(DataProviderSettings.PendingRequestsResourceKey, [targetRequest])
                return
              }
            }
          }
        } catch (e) {
          console.error('DataProviderSyncManager AddRequestToPendingRequests() MergeError:', e)
        }
      }

      // create new pending request
      await this.dataStorage.Create(DataProviderSettings.PendingRequestsResourceKey, [
        {
          id: Utils.getUid(),
          type,
          timestamp: Date.now(),
          resource,
          apiResource,
          payload,
          options: { noLocalStorage: options?.post?.noLocalStorage, saveDataOnDevice: options?.saveDataOnDevice as string[] },
        } satisfies PendingRequest,
      ])
    } catch (exception) {
      console.error('DataProvider: AddRequestToStack Error', exception)
    }
  }

  public async ProcessPendingRequests(auto?: boolean, requestsToProcess?: PendingRequest[]) {
    if (this.upSyncBusy) {
      await this.WaitForUpSyncDone()
    }

    if (!(await this.core.CheckApiAlive())) throw new Error('Failed to connect to api')

    let upSyncResult = false
    let failedRequestCount = 0
    try {
      this.upSyncBusy = true
      let requests = await this.GetPendingRequests()
      if (requestsToProcess) requests = requests.filter(r => requestsToProcess.find(rtp => rtp.id === r.id))

      const failedRequests: PendingRequest[] = []
      const changedResources: string[] = []
      for (const request of requests) {
        const result = await this.ProcessPendingRequest(request)
        if (!changedResources.includes(request.resource)) changedResources.push(request.resource)
        if (!result.ok) failedRequests.push({ ...request, error: result.error })
      }
      if (failedRequests.length) {
        failedRequestCount = failedRequests.length
        if (auto && !requestsToProcess?.length) this.core.DispatchState(DataProviderStateActions.UpSyncFailed, { failedRequests })
      } else if (!(await this.PendingRequestsExits())) {
        await this.postSyncCleanup(changedResources)

        this.autoSyncTried = false
        upSyncResult = true
      }
    } catch (exception) {
      console.error('Error at ProcessPendingRequests()', exception)
    } finally {
      this.upSyncBusy = false
      this.ResolveWailList(upSyncResult)
    }
    return { upSyncResult, failedRequestCount }
  }

  public async postSyncCleanup(changedResources?: string[]) {
    if (changedResources?.length) await this.CheckDBForRemnants(changedResources)
    await this.dataStorage.DeleteAll(DataProviderSettings.IdPairResourceKey)
    await this.checkSyncWipe()
    await DataProviderFileHandler.deleteAllFiles()
  }

  private async ProcessPendingRequest<T extends object = object>(request: PendingRequest) {
    try {
      const idPairs = await this.dataStorage.Get<AssignedIdPair>(DataProviderSettings.IdPairResourceKey)
      const payload = DataProviderUtils.replaceTemporaryIds(
        request.payload,
        idPairs,
        DataProviderSettings.tempIdIdentifier,
        DataProviderSettings.tempIdIdentifierNumber
      ) as T
      await this.core.ManageGetFileSync([payload], { saveDataOnDevice: request.options?.saveDataOnDevice as (keyof T)[] })
      const resourceIds = this.getResourceIds<T>(request.resource)
      const payloadId = DataProviderUtils.createIdFrom(payload, ...resourceIds)
      if (DataProviderCore.debug) {
        console.debug('Processing pending request', request.type, request.resource, request.apiResource)
      }
      const options: DataProviderOptions<T> = { id: resourceIds as (keyof T)[] }
      const apiResult = (await this.core.ApiSend<T>(request.type, request.apiResource, payload, options)) as object | string | number
      //update local db entry
      if (!request.options?.noLocalStorage) {
        // parse ids from api result and
        if (payloadId) {
          const resultId = DataProviderUtils.createIdFrom(apiResult, ...resourceIds)
          if (resultId) {
            await this.dataStorage.UpdateEntry(request.resource, payloadId, { ...payload, ...resultId })
            // create tempId - erpId pair entry if id was temp-id
            for (const payloadIdKey of Object.keys(payloadId)) {
              if (
                DataProviderUtils.idIsTemporaryId(
                  payloadId[payloadIdKey],
                  DataProviderSettings.tempIdIdentifier,
                  DataProviderSettings.tempIdIdentifierNumber
                )
              ) {
                const payloadKeyValue = request.payload[payloadIdKey as keyof object]
                if (payloadKeyValue) {
                  await this.dataStorage.Create(DataProviderSettings.IdPairResourceKey, [
                    { id: payloadKeyValue, erpId: resultId[payloadIdKey] } as AssignedIdPair,
                  ])
                }
              }
            }
          }
        }

        // delete pending request from db
        await this.dataStorage.Delete(DataProviderSettings.PendingRequestsResourceKey, [request])
      }

      return { ok: true }
    } catch (exception) {
      console.error('DataProvider: ProcessPendingRequest() Error', request.type, request.resource, exception)

      const error = DataProviderUtils.extractErrorMessageFromException(exception)
      try {
        await this.dataStorage.Update(DataProviderSettings.PendingRequestsResourceKey, [{ id: request.id, error }], 'patch')
      } catch (updateException) {
        console.error('ProcessRequestStack Error updating failed pendingRequest', updateException)
      }
      return { ok: false, error }
    }
  }

  public IsOfflineData(object: object) {
    // TODO not working for ergo
    if (!object) return false
    return JSON.stringify(object).includes(DataProviderSettings.tempIdIdentifier)
  }

  private async CheckDBForRemnants(resourcesToCheck: string[]) {
    for (const resourceKey of resourcesToCheck) {
      try {
        const data = await this.core.dataStorage.Get<object>(resourceKey, { id: new RegExp(DataProviderSettings.tempIdIdentifier) })
        if (!data.length) continue
        console.warn(
          'DataProviderSyncManager: Deleting',
          data.length,
          'remnants from',
          resourceKey,
          '! This should not happen and probably indicating a bug'
        )
        await this.core.dataStorage.Delete(resourceKey, data)
      } catch (_ignore) {
        continue
      }
    }
  }

  private ResolveWailList(result: boolean) {
    if (!this.upSyncWaitList?.length) return
    this.upSyncWaitList.forEach(callback => callback(result))
  }

  public async DeleteFailedPendingRequest(requestToDelete: PendingRequest) {
    const requests = await this.GetPendingRequests(requestToDelete.id)
    if (!requests?.length) return
    await this.dataStorage.Delete(DataProviderSettings.PendingRequestsResourceKey, [...requests])
    //called to update states
    await this.PendingRequestsExits()
  }

  public async checkSyncWipe() {
    const resourcesToWipe = this.core.resources.GetAll().filter(r => r.syncOptions?.clearOnSync)
    await Promise.all(resourcesToWipe.map(r => this.dataStorage.DeleteAll(r.resource)))
  }

  /**
   * Init Synchronization, before calling Api
   */
  public async InitSync(reSync?: boolean) {
    if (await this.PendingRequestsExits()) {
      throw new Error('Before Sync can be done any pending requests need to be either processed or deleted')
    }
    if (reSync) {
      await this.dataStorage.DeleteAll()
    } else {
      await this.checkSyncWipe()
    }
  }

  /**
   * Returns info for given resource
   * @returns count of records and size in bytes
   */
  public async GetStorageInfo(resource: string | undefined) {
    if (!resource) return { count: 0, size: 0 }
    const count = await this.dataStorage.Count(resource)
    if (count <= 0) return { count, size: 0 }
    const size = await this.dataStorage.GetStorageSize(resource)
    return { count, size }
  }
}
