/* eslint-disable react-native/no-inline-styles */

import { useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { ConfigProvider } from 'antd'
import deDE from 'antd/locale/de_DE'
import itIT from 'antd/locale/it_IT'
import dayjs from 'dayjs'
import React, { createRef, memo, useEffect } from 'react'
import { DnDSource } from 'react-big-scheduler-stch'
import { useRecoilState, useRecoilValue } from 'recoil'

import { PlanningTime, TravelTime } from '../../apis/types/apiResponseTypes'
import DndActivityListCard from '../../cards/activity/DndActivityListCard'
import DndExtraActivityListCard from '../../cards/activity/DndExtraActivityListCard'
import Planner from '../../components/planner/Planner'
import PlannerEvent from '../../components/planner/PlannerEvent'
import PlannerPopover from '../../components/planner/PlannerPopover'
import PlannerResourceHeader from '../../components/planner/PlannerResourceHeader'
import { DnDType, PlannerRef } from '../../components/planner/types'
import withDnDContext from '../../components/planner/withDnDContext'
import {
  PLANNER_CREATE_EVENT_KEY,
  PLANNER_CREATE_TRAVEL_TIME_KEY,
  PLANNER_NEXT_EVENT_KEY,
  PLANNER_PICK_EVENT_KEY,
  PLANNER_PREVIOUS_EVENT_KEY,
} from '../../constants/EmitterKeys'
import usePlanner from '../../hooks/usePlanner'
import usePlannerFunctions from '../../hooks/usePlannerFunctions'
import useUserSettings from '../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../types'
import { plannerUtils } from '../../utils/plannerUtils'
import { plannerSelectedEmployeeAtom, plannerViewAtom } from '../../utils/stateManager'
import PlannerSideView from './PlannerSideView'

const dndSource = new DnDSource(
  props => {
    // @ts-ignore boh
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return props.activity
  },
  // @ts-ignore ok like this
  DndActivityListCard,
  true,
  DnDType.ACTIVITY
)

const extraDndSource = new DnDSource(
  props => {
    // @ts-ignore boh
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return props.activity
  },
  // @ts-ignore ok like this
  DndExtraActivityListCard,
  true,
  DnDType.TRAVEL_TIME
)

function PlannerView() {
  const { language } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { userSettings } = useUserSettings()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const plannerRef = createRef<PlannerRef>()

  const selectedEmployeeIds = useRecoilValue(plannerSelectedEmployeeAtom(sessionKey))
  const [plannerView, setPlannerView] = useRecoilState(plannerViewAtom(sessionKey))

  const { resources } = usePlanner()
  const { onMove, onMoveEnd, onMoveStart, onNewEvent, onNewTravelTime, onRemove } = usePlannerFunctions()

  useEvent({ key: PLANNER_NEXT_EVENT_KEY }, () => plannerRef.current?.next())
  useEvent({ key: PLANNER_PREVIOUS_EVENT_KEY }, () => plannerRef.current?.previous())
  useEvent<Date>({ key: PLANNER_PICK_EVENT_KEY }, date => plannerRef.current?.pick(date))
  useEvent<PlanningTime>({ key: PLANNER_CREATE_EVENT_KEY }, newEvent => {
    if (!plannerView) return

    plannerRef.current?.add(plannerUtils.createEventsFromPlanningTimes([newEvent], plannerView.view))
  })
  useEvent<TravelTime>({ key: PLANNER_CREATE_TRAVEL_TIME_KEY }, newTravelTime => {
    if (!plannerView) return

    plannerRef.current?.add(plannerUtils.createEventsFromTravelTime(newTravelTime, plannerView.view))
  })

  useEffect(() => {
    if (resources.length === 0 || selectedEmployeeIds === undefined) return

    const filteredResources = resources.filter(resource => selectedEmployeeIds.includes(resource.id))

    plannerRef.current?.updateResources(filteredResources)
  }, [resources, selectedEmployeeIds])

  return (
    <ConfigProvider
      locale={language === 'de' ? deDE : language === 'it' ? itIT : undefined}
      theme={{
        token: {
          colorPrimary: theme.primary,
          colorText: theme.text.default,
          colorTextPlaceholder: theme.text.placeholder,
        },
        components: {
          DatePicker: {
            colorBgElevated: colorScheme === 'dark' ? theme.background.default : '#ffffff',
            colorIcon: colorScheme === 'dark' ? theme.text.default : 'rgba(0, 0, 0, 0.45)',
            colorTextDisabled: theme.text.placeholder,
            controlItemBgActive: colorScheme === 'dark' ? '#43A973' : '#EEFAF4',
          },
        },
      }}>
      <Planner
        key={colorScheme}
        ref={plannerRef}
        onMove={(event, slotId, slotName, start, end) => onMove(event, slotId, slotName, start, end)}
        onMoveEnd={(event, end) => onMoveEnd(event, end)}
        onMoveStart={(event, start) => onMoveStart(event, start)}
        onRefresh={() => null}
        onDateChange={setPlannerView}
        dndSources={[dndSource, extraDndSource]}
        style={{ width: '70%' }}
        renderItem={(event, _, isStart, isEnd, mustAddCssClass, mustBeHeight) => (
          <PlannerEvent event={event} isStart={isStart} isEnd={isEnd} mustAddCssClass={mustAddCssClass} mustBeHeight={mustBeHeight} />
        )}
        renderItemPopover={(eventItem, _title, start, end) => (
          <PlannerPopover
            end={end}
            eventItem={eventItem}
            start={start}
            onDuplicate={() => {
              if (plannerView === undefined || userSettings === undefined) return

              onNewEvent(
                eventItem.activityId as string,
                eventItem.ticketId as string,
                eventItem.resourceId,
                dayjs(start).format('YYYY-MM-DD HH:mm:ss'),
                dayjs(end).format('YYYY-MM-DD HH:mm:ss'),
                plannerView.view,
                userSettings.planningCalendarWorkTimeFrom * 60,
                userSettings.planningCalendarWorkTimeUntil * 60
              )
            }}
            onDelete={() => {
              plannerRef.current?.remove(eventItem)
              onRemove(eventItem)
            }}
          />
        )}
        renderResourceHeader={resourceName => <PlannerResourceHeader resourceName={resourceName} />}
      />
      <PlannerSideView
        dndSource={dndSource}
        extraDndSource={extraDndSource}
        onNewEvent={(activity, slotId, start, end) => {
          if (plannerView === undefined || userSettings === undefined) return

          onNewEvent(
            activity.id,
            activity.ticketId,
            slotId,
            start,
            end,
            plannerView.view,
            userSettings.planningCalendarWorkTimeFrom * 60,
            userSettings.planningCalendarWorkTimeUntil * 60
          )
        }}
        onNewTravelTime={(slotId, start, end) => {
          if (plannerView === undefined || userSettings === undefined) return

          onNewTravelTime(
            slotId,
            start,
            end,
            plannerView.view,
            userSettings.planningCalendarWorkTimeFrom * 60,
            userSettings.planningCalendarWorkTimeUntil * 60
          )
        }}
      />
    </ConfigProvider>
  )
}

export default memo(withDnDContext(PlannerView))
