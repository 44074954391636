import { IM, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated, { AnimatedStyle } from 'react-native-reanimated'

import useAnimation from '../hooks/useAnimation'
import { ThemeColorExpanded } from '../types'

interface Props {
  color?: string
  size?: number
  style?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
}

const LoadingIcon = memo(function LoadingIcon({ color, size, style }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { animatedStyles } = useAnimation()

  return (
    <Animated.View style={[style, styles.container, animatedStyles]}>
      <IM.Icon icon={['fal', 'circle-notch']} color={color ?? theme.text.placeholder} size={size} />
    </Animated.View>
  )
})

export default LoadingIcon

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
})
