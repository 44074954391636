import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'

interface Props {
  title: string
  buttonTitle?: string
  showButton: boolean
  icon: IconProp
  iconSize?: number
  onPress: () => void
}

const EditOrCreateHeader = ({ title, showButton, icon, iconSize, buttonTitle, onPress }: Props) => {
  const { i18n } = useLanguage()

  return (
    <IM.View style={styles.container}>
      <IM.Text style={[styles.contentText, styles.title]}>{title}</IM.Text>
      {showButton && (
        <Pressable onPress={onPress} hitSlop={{ top: 10, bottom: 8 }}>
          <TextWithIcon alignIcon="right" icon={icon} iconSize={iconSize} color={styles.contentText.color} style={styles.title}>
            {buttonTitle ?? i18n.t('EDIT')}
          </TextWithIcon>
        </Pressable>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-between' },
  contentText: {
    color: IMStyle.palette.white,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeMedium,
  },
})

export default EditOrCreateHeader
