import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, Language, StringUtils, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import { differenceInHours } from 'date-fns'
import React, { memo, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import TimeUtils from '../../../utils/TimeUtils'
import PressableIcon from '../../Infominds/PressableIcon'

export type SyncIconProps = {
  showSyncInfo?: boolean
}

const SyncIcon = memo(function SyncIcon({ showSyncInfo }: SyncIconProps) {
  const { i18n, language } = useLanguage()
  const navigation = useNavigation()
  const dataProviderState = useDataProvider()
  const syncInfo = useMemo<{ text?: string; color?: string; busy?: boolean } | undefined>(() => {
    if (!showSyncInfo) return undefined

    if (dataProviderState.syncState === 'error') {
      return { text: i18n.t('SYNC_ERROR_HEADER'), color: '#FF5858' }
    }
    if (dataProviderState.syncState === 'down-sync' || dataProviderState.syncState === 'up-sync') {
      return { busy: true }
    }
    if (dataProviderState.lastSyncDate) {
      const lastSync = formatLastSyncInterval(dataProviderState.lastSyncDate, language)
      if (lastSync) {
        return {
          text: StringUtils.stringValueReplacer(i18n.t('LAST_SYNC_HEADER'), lastSync),
        }
      }
    }

    return undefined
  }, [showSyncInfo, dataProviderState.lastSyncDate, dataProviderState.syncState])

  const { icon, color, size } = useMemo<{
    icon: IconProp
    color: string
    size?: number
  }>(() => {
    if (dataProviderState.syncState === 'down-sync') return { icon: ['fal', 'cloud-arrow-down'], color: IMStyle.palette.white, size: 24 }
    if (dataProviderState.syncState === 'up-sync') return { icon: ['fal', 'cloud-arrow-up'], color: IMStyle.palette.white, size: 24 }
    if (dataProviderState.syncState === 'error') return { icon: ['fal', 'cloud-slash'], color: IMStyle.palette.white }
    if (!dataProviderState.isOnline) return { icon: ['fal', 'cloud-slash'], color: IMStyle.palette.red }
    if (dataProviderState.failedRequests?.length) return { icon: ['fal', 'cloud-exclamation'], color: IMStyle.palette.yellow }
    if (dataProviderState.pendingDataToSync) return { icon: ['fal', 'cloud-arrow-up'], color: IMStyle.palette.yellow }
    return { icon: ['fal', 'cloud-check'], color: IMStyle.palette.white, size: 24 }
  }, [dataProviderState])

  if (Platform.OS === 'web' || !dataProviderState.enabled) return <></>

  return (
    <IM.View style={[IMLayout.flex.row]}>
      {syncInfo && (
        <IM.View style={styles.center}>
          <IM.Text style={{ color: syncInfo.color ?? IMStyle.palette.white }}>{syncInfo.text}</IM.Text>
        </IM.View>
      )}
      <IM.View style={[styles.center, styles.iconView]}>
        <PressableIcon
          icon={icon}
          color={syncInfo?.color ?? color}
          size={size ?? 22}
          onPress={() => navigation.navigate('BottomTab', { screen: 'SynchronizationStack', params: { screen: 'Synchronization' } })}
        />
      </IM.View>
    </IM.View>
  )
})

function formatLastSyncInterval(lastSyncDateTimeStamp: number | undefined, language: Language) {
  if (!lastSyncDateTimeStamp) return ''
  const lastSyncDate = new Date(lastSyncDateTimeStamp)
  const differenceInHoursSinceLastSync = differenceInHours(new Date(), lastSyncDate)
  if (differenceInHoursSinceLastSync <= 0) return '< 1h'
  if (differenceInHoursSinceLastSync <= 48) return `${differenceInHoursSinceLastSync}h`
  return TimeUtils.format(lastSyncDate.toISOString(), language)
}

export default SyncIcon

const styles = StyleSheet.create({
  iconView: {
    width: 40,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
