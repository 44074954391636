import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, useEvent, Utils } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useMemo } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, SectionList as RNSectionList, SectionListProps } from 'react-native'

import CONSTANTS from '../constants/Constants'
import { SECTION_LIST_CLOSE_TO_END_EVENT_KEY } from '../constants/EmitterKeys'
import { FiniteLoadList, InfiniteLoadingType, InfiniteLoadList, ListSection } from '../types'
import appUtils from '../utils/appUtils'
import { AnimatedButtonHideEvent } from './Infominds/AnimatedButton'
import RefreshControl from './Infominds/RefreshControl'
import NoEntry from './NoEntry'
import SkeletonCard from './skeleton/SkeletonCard'

type Props<T> = (FiniteLoadList | InfiniteLoadList) & {
  sections: ListSection<T>[]
  loading: InfiniteLoadingType
  noDataIcon?: IconProp
  noDataMessage: string
  errorMessage?: string
  noDataSection?: string
  loadingSection?: string
  skeletonElements?: number
  skeletonComponent?: React.ReactNode
  skeletonTopSpacing?: boolean
  closeEndThreshold?: number
  hideSectionTitle?: boolean
  hideButtonId?: string
} & Pick<
    SectionListProps<T, ListSection<T>>,
    'keyboardShouldPersistTaps' | 'contentContainerStyle' | 'renderItem' | 'keyExtractor' | 'onScroll' | 'onRefresh'
  >

function List<T extends { id: string }>(
  {
    sections,
    loading,
    noDataIcon = ['fal', 'binary-slash'],
    noDataSection,
    loadingSection,
    noDataMessage,
    skeletonElements = CONSTANTS.skeletonCards,
    skeletonComponent = <SkeletonCard size="small" />,
    skeletonTopSpacing,
    allDataLoaded,
    errorMessage,
    hideSectionTitle,
    keyboardShouldPersistTaps = 'handled',
    contentContainerStyle,
    closeEndThreshold,
    hideButtonId,
    onRefresh,
    ...others
  }: Props<T>,
  ref: ForwardedRef<RNSectionList<T, ListSection<T>>>
) {
  const { keyExtractor, onScroll, onLoadMore } = others
  const { emit } = useEvent<AnimatedButtonHideEvent>({ key: SECTION_LIST_CLOSE_TO_END_EVENT_KEY })

  const showSkeleton = loading === 'reloading'

  useEffect(() => {
    emit({ hide: false, id: hideButtonId ?? '' })
  }, [])

  const renderSection = (title?: string) => {
    if (!title) return <></>

    return (
      <IM.View spacing="all">
        <IM.Text>{title}</IM.Text>
      </IM.View>
    )
  }

  const renderNoData = () => {
    return (
      <>
        {(loading === false || loading === 'catched') && (
          <>
            {!hideSectionTitle && renderSection(noDataSection)}
            <NoEntry description={noDataMessage} icon={noDataIcon} error={loading === 'catched'} errorMessage={errorMessage} />
          </>
        )}
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        {showSkeleton && (
          <>
            {!hideSectionTitle && renderSection(loadingSection)}
            <IM.View spacing={['bottom', 'horizontal']}>
              {Array(skeletonElements)
                .fill(0)
                .map((_, index) => {
                  return (
                    <IM.View spacing={[skeletonTopSpacing && index === 0 ? 'top' : 'none', 'bottom']} key={`SkeletonDataPassword-${index}`}>
                      {skeletonComponent}
                    </IM.View>
                  )
                })}
            </IM.View>
          </>
        )}
        {loading === 'loadMore' && !allDataLoaded && <IM.View spacing={['bottom', 'horizontal']}>{skeletonComponent}</IM.View>}
      </>
    )
  }

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()

    onScroll?.(e)
    if (closeEndThreshold === undefined) return

    if (appUtils.closeToEndDetector(e, closeEndThreshold, loading === false && allDataLoaded !== false)) {
      emit({ hide: true, id: hideButtonId ?? '' })
    } else {
      emit({ hide: false, id: hideButtonId ?? '' })
    }
  }

  const initialNumToRender = useMemo(() => {
    if (Platform.OS === 'web') {
      return Utils.sum(sections, item => item.data.length)
    }

    return undefined
  }, [sections])

  return (
    <RNSectionList
      ref={ref}
      initialNumToRender={initialNumToRender} // bug in react-native shows only first 10 items in web (the error was in AccessGroupSelector)
      scrollEnabled={!showSkeleton}
      sections={sections}
      renderSectionHeader={({ section }) => renderSection(section.title)}
      ListFooterComponent={renderFooter}
      ListEmptyComponent={renderNoData}
      keyExtractor={keyExtractor ?? (item => item.id)}
      stickySectionHeadersEnabled={false}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      refreshControl={onRefresh ? <RefreshControl refreshing={false} onRefresh={onRefresh} /> : undefined}
      contentContainerStyle={[{ marginHorizontal: IMLayout.horizontalMargin }, contentContainerStyle]}
      onEndReachedThreshold={0.5}
      onEndReached={onLoadMore}
      onScroll={handleScroll}
      {...others}
    />
  )
}

const SectionList = forwardRef(List) as <T>(props: Props<T> & { ref?: ForwardedRef<RNSectionList<T, ListSection<T>>> }) => ReturnType<typeof List>

export default SectionList
