import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import type { Ticket } from '../../apis/types/apiResponseTypes'
import Pressable from '../../components/Infominds/Pressable'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import Text from '../../components/Text'
import { LoadingType, ThemeColorExpanded } from '../../types'
import DetailInfo from '../common/DetailInfo'
import TicketDescription from './components/TicketDescription'
import TicketIcon from './components/TicketIcon'
import TicketTitle from './components/TicketTitle'

type Props = {
  ticket: Ticket
  height: number
  spacing?: SpacingProps
  borderless?: boolean
  limitDescriptionToLines?: number
  forceLayout?: false | 'small' | 'medium' | 'large'
  isSelected?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  index: number
  total: number
  hasCoordinates?: boolean
  timeRunning?: boolean
  loading: LoadingType
}

const TicketMapCard = memo(function TicketMapCard({
  ticket,
  spacing,
  height,
  onPress,
  borderless,
  style,
  index,
  total,
  hasCoordinates,
  timeRunning = false,
  loading,
}: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.Card
      noContentSpacing
      borderless={borderless}
      spacing={spacing}
      style={[{ backgroundColor: theme.card.background, height, borderRadius: IMLayout.borderRadius }, IMLayout.shadow, style]}>
      <Pressable style={styles.pressable} onPress={onPress}>
        {loading === false ? (
          <>
            <IM.View>
              <IM.View style={IMLayout.flex.row} spacing="bottom">
                <TicketIcon
                  activityType={ticket.activityType}
                  priorityColorHex={ticket.priorityColorHex}
                  spacing="right"
                  enablePriorityIndicator
                  unReadMessages={ticket.countOfExternalComments}
                />
                <TicketTitle style={IMLayout.flex.f1} ticket={ticket} type="carousel" view="normal" timeRunning={timeRunning} />
              </IM.View>
              <DetailInfo object={ticket} />
              <TicketDescription ticket={ticket} numberOfLines={1} spacing="top" />
            </IM.View>
            <IM.View style={[IMLayout.flex.row]}>
              <IM.View
                style={[
                  styles.counter,
                  {
                    backgroundColor: theme.chip.background,
                  },
                ]}>
                <Text secondary={colorScheme === 'light'} style={{ fontSize: IMStyle.typography.fontSizeRegular - 2 }}>
                  {index}/{total}
                </Text>
              </IM.View>
              {hasCoordinates === false && (
                <IM.View style={styles.message}>
                  <TextWithIcon icon={['fal', 'location-dot-slash']} iconColor={theme.general.error} secondary>
                    {i18n.t('MISSING_COORDINATES')}
                  </TextWithIcon>
                </IM.View>
              )}
            </IM.View>
          </>
        ) : (
          <IM.View style={IMLayout.flex.f1}>
            <SkeletonText width="60%" height={30} spacing="bottom" />
            <SkeletonText width="80%" height={30} spacing="vertical" />
            <SkeletonText width="100%" height={30} spacing="vertical" />
            <SkeletonText width="80%" height={30} spacing="vertical" />
            <SkeletonText width="60%" height={30} spacing="top" />
          </IM.View>
        )}
      </Pressable>
    </IM.Card>
  )
})

export default TicketMapCard

const styles = StyleSheet.create({
  counter: { borderRadius: 25, paddingVertical: 3, paddingHorizontal: 10, alignSelf: 'flex-start', justifyContent: 'flex-end' },
  pressable: {
    padding: 12,
    height: '100%',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  message: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: IMLayout.horizontalMargin * 3,
  },
})
