import { DropdownItem, IM, IMStyle, Language, useAlert, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import useSynchronization from '../../dataProvider/hooks/useSynchronization'
import Text from '../Text'

export type LanguageSelectorRef = { close: () => void }

export const languages: DropdownItem<Language>[] = [
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
  { label: 'English', value: 'en' },
]

interface Props {
  offline?: boolean
  onReLogIn: () => void
}

const LanguageSelector = ({ onReLogIn, offline }: Props) => {
  const { alert } = useAlert()
  const { updateToken } = useAuthentication()
  const { i18n, language, setLanguageAsync } = useLanguage()
  const [selected, setSelected] = useState(language)
  const { deleteLocalData } = useSynchronization()

  const originalLanguage = useRef(language)

  useEffect(() => {
    if (originalLanguage.current !== selected) {
      alert(i18n.t('WARNING'), i18n.t('CHANGE_LANGUAGE_WARNING'), [
        {
          text: i18n.t('CANCEL'),
          style: 'default',
          onPress: () => {
            return setSelected(originalLanguage.current)
          },
        },
        {
          text: i18n.t('RESTART_APP'),
          style: 'destructive',
          onPress: () => {
            deleteLocalData()
              .catch(console.error)
              .finally(() => {
                setLanguageAsync(selected)
                  .then(() => {
                    updateToken(undefined, selected)
                      .then(() => {
                        setSelected(selected)
                        originalLanguage.current = selected
                        onReLogIn()
                      })
                      .catch(err => console.error('Failed language re-auth:', err))
                  })
                  .catch(console.error)
              })
              .catch(console.error)
          },
        },
      ])
    }
  }, [selected])

  return (
    <>
      {offline ? (
        <IM.View style={styles.offlineMessage}>
          <Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{languages.find(el => el.value === selected)?.label}</Text>
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <Text secondary style={{ fontSize: IMStyle.typography.fontSizeRegular - 1, textAlign: 'right' }}>
            {i18n.t('LANGUAGE_OFFLINE')}
          </Text>
        </IM.View>
      ) : (
        <IM.Dropdown
          value={selected}
          data={languages}
          onChange={({ value }) => setSelected(value)}
          containerStyle={dropdownStyle.noShadow}
          style={dropdownStyle.dropdown}
          selectedTextProps={{ numberOfLines: 1 }}
        />
      )}
    </>
  )
}

export default LanguageSelector

const styles = StyleSheet.create({
  offlineMessage: { alignItems: 'flex-end', paddingBottom: 10 },
})

export const dropdownStyle = StyleSheet.create({
  dropdown: { minWidth: 200, maxWidth: 260 },
  noShadow: { ...Platform.select({ web: { boxShadow: 'none' }, default: { shadowColor: 'transparent', elevation: 0 } }) },
})
