import { useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Platform } from 'react-native'

import { getApi } from '../../apis/apiCalls'
import useRequest from '../../components/Infominds/hooks/useRequest'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import SerialNumberSelectionHeader from '../../components/screen/headers/SerialNumberSelectionHeader'
import { Screen } from '../../components/screen/Screen'
import { REFRESH_TICKET_ARTICLES_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ADD_TICKET_ARTICLE } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import TicketSerialNumberCreationModal from '../../modals/tickets/TicketSerialNumberCreationModal'
import { TicketStackParamList, TicketStackScreenProps } from '../../navigation/types'
import { ModalScreenProps, ModalScreenRef, TicketAddSNParams } from '../../types'
import TicketAddSerialNumberView from '../../views/ticket/TicketAddSerialNumberView'

function TicketAddSerialNumberScreen(
  { route, controller }: Omit<TicketStackScreenProps<'TicketAddSerialNumber'>, 'navigation'> & ModalScreenProps<TicketAddSNParams>,
  ref: ForwardedRef<ModalScreenRef>
) {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const { client } = useDataProvider()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const createSNController = useModalController<TicketStackParamList['TicketSerialNumberCreation']>()
  const { isSmallDevice } = useDimensions()
  const [destinationFilter, setDestinationFilter] = useState(true)
  const [uploadStarted, setUploadStarted] = useState(false)
  const [goBackLoading, setGoBackLoading] = useState(false)

  const { emit } = useEvent({ key: REFRESH_TICKET_ARTICLES_EVENT_KEY })

  const { handleGoBack } = useEditOrCreateScreenBackManager({
    title: '',
    description: '',
    controller,
  })

  const { request, loading } = useRequest(getApi(client).addTicketArticle, { id: REQUEST_ADD_TICKET_ARTICLE })

  useEffect(() => {
    if (uploadStarted && loading === false) {
      setUploadStarted(false)
      emit()
      handleGoBack()
    }

    if (uploadStarted && (loading === 'catched' || loading === 'aborted')) {
      setUploadStarted(false)
      setGoBackLoading(false)
    }
  }, [uploadStarted, loading])

  const handleAdd = () => {
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('TicketSerialNumberCreation', { customerId: route.params.customerId, shippingAddressId: route.params.shippingAddressId })
      : createSNController.show({ customerId: route.params.customerId, shippingAddressId: route.params.shippingAddressId })
  }

  const isModal = controller !== undefined

  return (
    <>
      <SearchProvider>
        <Screen transparent={isModal}>
          <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack} isModal={isModal} goBackLoading={goBackLoading}>
            <SerialNumberSelectionHeader
              title={i18n.t('SERIAL_NUMBERS')}
              hideDestinationFilter={route.params.shippingAddressId === undefined}
              destinationFilterEnabled={destinationFilter}
              onAdd={handleAdd}
              onDestinationFilterPress={() => setDestinationFilter(prev => !prev)}
            />
          </Screen.Header>
          <Screen.DetailHeader>
            <SearchDetailHeaderContainer />
          </Screen.DetailHeader>
          <Screen.Content>
            <TicketAddSerialNumberView
              customerId={route.params.customerId}
              ticketId={route.params.ticketId}
              shippingAddressId={route.params.shippingAddressId}
              destinationFilter={destinationFilter}
              onChange={(serialnumberId, articleId) => {
                setGoBackLoading(true)
                setUploadStarted(true)
                request({
                  ticketId: route.params.ticketId,
                  serialnumberId,
                  articleId,
                })
              }}
            />
          </Screen.Content>
        </Screen>
      </SearchProvider>
      <TicketSerialNumberCreationModal controller={createSNController} />
    </>
  )
}

export default forwardRef(TicketAddSerialNumberScreen)
