import { IM, IMLayout, IMStyle, SpacingProps, useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { memo, useEffect } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../../apis/apiCalls'
import ArticleDetailsAndGraphic from '../../../components/ArticleDetailsAndGraphic'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import InfoboxArticleItem from '../../../components/InfoboxArticleItem'
import DynamicView from '../../../components/Infominds/DynamicView'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import SkeletonText from '../../../components/skeleton/SkeletonText'
import { REQUEST_ARTICLE_INFOBOX_FILES } from '../../../constants/Keys'

interface Props {
  articleId: string
  graphicId?: string
  spacing?: SpacingProps
}

const TicketArticleDetailCard = memo(function TicketArticleDetailCard({ spacing, graphicId, articleId }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()

  const { item, loadItem, loading } = useControlledLoader(api.getArticleInfoboxFiles, { id: REQUEST_ARTICLE_INFOBOX_FILES })

  useEffect(() => {
    articleId && loadItem({ infoboxTyp: 'Article', id: articleId })
  }, [articleId])

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('ARTICLE_DETAIL')} />} spacing={spacing}>
      <DynamicView separatorVertical>
        <ArticleDetailsAndGraphic articleId={articleId} graphicId={graphicId} />
        <IM.View
          // eslint-disable-next-line react-native/no-inline-styles
          style={[IMLayout.flex.f6, { marginTop: isSmallDevice ? 2 * IMLayout.horizontalMargin : 0 }]}
          spacing={isSmallDevice ? 'none' : 'horizontal'}>
          <IM.Text style={[{ fontSize: IMStyle.typography.fontSizeMedium, fontWeight: IMStyle.typography.fontWeightBold }, styles.title]}>
            {i18n.t('INFOBOX')}
          </IM.Text>
          {loading === false ? (
            <>
              {item?.length === 0 ? (
                <IM.Text secondary>{i18n.t('NO_FILES')}</IM.Text>
              ) : (
                <>
                  {item
                    ?.sort((a, b) => a.filename.localeCompare(b.filename))
                    .map(elem => {
                      return <InfoboxArticleItem key={elem.id} file={elem} />
                    })}
                </>
              )}
            </>
          ) : (
            <SkeletonText height={17} />
          )}
        </IM.View>
      </DynamicView>
    </IM.Card>
  )
})

export default TicketArticleDetailCard

const styles = StyleSheet.create({
  title: {
    marginBottom: 4,
  },
})
