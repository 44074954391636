import { IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Destination } from '../../apis/types/apiResponseTypes'
import CustomerDestinationCard from '../../cards/customer/CustomerDestinationCard'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_CUSTOMER_DESTINATIONS } from '../../constants/Keys'
import { ListSection } from '../../types'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import SelectInput from './selectInput/SelectInput'

interface Props {
  customerId: string | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Destination | undefined) => void
  disableFastInput?: boolean
}

export default function DestinationSelector({ customerId, onChange, disableFastInput, ...props }: Props) {
  const { i18n } = useLanguage()

  const noSelectionEntry: Destination = {
    id: CONSTANTS.noSelectionId,
    customerId: '',
    inactive: false,
    description: i18n.t('NO_SELECTION'),
  }

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(noSelectionEntry)

  const oldCustomerId = useRef(customerId)
  const {
    item: data,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.getCustomerDestinations, { chuckSize: CONSTANTS.destinationsChuckSize, id: REQUEST_CUSTOMER_DESTINATIONS })

  useEffect(() => {
    if (oldCustomerId.current !== customerId) {
      setSelected(noSelectionEntry)
    }

    oldCustomerId.current = customerId

    if (!customerId) return
    refresh(search)
  }, [search, customerId])

  const refresh = (text?: string) => customerId && loadItem({ customerId, searchtext: text })

  const render = ({ item }: SectionListRenderItemInfo<Destination, ListSection<Destination>>, onPress?: () => void) => {
    const isLast = allDataLoaded && data.length > 0 && item.id === data[data.length - 1].id
    const isFirst = item.id === CONSTANTS.noSelectionId
    const marginBottom = isLast ? 3 * IMLayout.verticalMargin : 0

    return (
      <CustomerDestinationCard
        disableIcon={isFirst}
        destination={item}
        selected={item.id === selected.id}
        onPress={onPress}
        spacing={['horizontal', isFirst ? 'vertical' : isLast ? 'none' : 'bottom']}
        hideButtons
        style={{ marginBottom }}
      />
    )
  }

  const handleOnChange = (newValue: Destination | undefined) => {
    setSelected(newValue ?? noSelectionEntry)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  return (
    <SelectInput
      data={search !== '' ? data : data ? [noSelectionEntry, ...data] : [noSelectionEntry]}
      value={selected.id === CONSTANTS.noSelectionId ? undefined : selected}
      loading={customerId === undefined ? false : loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={i18n.t('DESTINATION')}
      screenTitle={i18n.t('DESTINATIONS')}
      noDataMessage={i18n.t('NO_DESTINATION_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.description}
      allDataLoaded={allDataLoaded}
      onLoadMore={loadMore}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      disableBackdropOpacity
      {...props}
    />
  )
}
