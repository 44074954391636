import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'

import { Gender } from '../../types'
import appUtils from '../../utils/appUtils'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

type GenderData = { id: Gender; value: string }

interface Props {
  value: Gender | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Gender) => void
}

const handleGender = (value: Gender | undefined) => {
  return value === Gender.None ? undefined : value
}

export default function GenderSelector({ value, onChange, ...props }: Props) {
  const { i18n } = useLanguage()

  const [search, setSearch] = useState('')
  const [gender, setGender] = useState<Gender | undefined>(handleGender(value))

  const genders: GenderData[] = [
    {
      id: Gender.None,
      value: i18n.t('NO_SELECTION'),
    },
    {
      id: Gender.Male,
      value: i18n.t('MALE'),
    },
    {
      id: Gender.Female,
      value: i18n.t('FEMALE'),
    },
    {
      id: Gender.Neuter,
      value: i18n.t('NEUTER'),
    },
  ]

  const handleOnChange = (newGender: GenderData | undefined) => {
    setGender(handleGender(newGender?.id))
    onChange?.(newGender?.id ?? Gender.None)
  }

  return (
    <SelectInput
      data={appUtils.filter(genders, search, ['value'])}
      value={genders.find(elem => elem.id === gender)}
      title={i18n.t('GENDER')}
      loading={false}
      screenTitle={i18n.t('GENDER')}
      noDataMessage={i18n.t('NO_INVOICE_TYPE_FOUND')}
      renderItem={({ item }, onPress) => <RenderInvoiceType item={item} selectedId={gender} onPress={onPress} />}
      renderSelectedString={el => el.value}
      onChange={handleOnChange}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      {...props}
    />
  )
}

export const RenderInvoiceType = ({ item, selectedId, onPress }: { item: GenderData; selectedId: string | undefined; onPress?: () => void }) => {
  return <RadioPressable onPress={() => onPress?.()} selected={item.id === selectedId} text={item.value} />
}
