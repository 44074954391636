import { useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { createRef, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { getApi } from '../../apis/apiCalls'
import { PostTicketRequest } from '../../apis/types/apiRequestTypes'
import { SerialNumber, SerialNumberBase, Ticket } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ActivityTypeSelector from '../../components/selectors/ActivityTypeSelector'
import ContactSelector from '../../components/selectors/ContactSelector'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import DestinationSelector from '../../components/selectors/DestinationSelector'
import EmployeeSelector from '../../components/selectors/EmployeeSelector'
import RegistrySelector from '../../components/selectors/RegistrySelector'
import SerialNumberMultiSelector from '../../components/selectors/SerialNumberMultiSelector'
import TeamSelector from '../../components/selectors/TeamSelector'
import TicketPrioritySelector from '../../components/selectors/TicketPrioritySelector'
import TicketStateSelector from '../../components/selectors/TicketStateSelector'
import TicketCreationAddFieldGroup from '../../components/TicketCreationAddFieldGroup'
import TicketCreationClassificationsGroup from '../../components/TicketCreationClassificationsGroup'
import { REFRESH_TICKET_LIST_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_POST_ADD_FIELDS, REQUEST_POST_CLASSIFICATION } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useUserSettings from '../../hooks/useUserSettings'
import { AdditionalFieldTextInputRef, ClassificationsTextInputRef, ClassificationValue, EditOrCreateViewRef, UploadStatus } from '../../types'
import appUtils from '../../utils/appUtils'

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

const TicketCreationView = ({ onDone, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const alert = useAlert()
  const { i18n } = useLanguage()
  const { client } = useDataProvider()
  const { userSettings } = useUserSettings()

  const {
    request: create,
    loading: loadingCreate,
    error: createError,
    response: creationResponse,
  } = useRequest(getApi(client).createTicket, { disableAlert: true })
  const {
    request: createClassification,
    loading: loadingClassification,
    error: errorClassification,
  } = useRequest(getApi(client).createTicketClassification, { id: REQUEST_POST_CLASSIFICATION, disableAlert: true })
  const {
    request: createAddFields,
    loading: loadingAddFields,
    error: errorAddFields,
  } = useRequest(getApi(client).createTicketAdditionalField, { id: REQUEST_POST_ADD_FIELDS, disableAlert: true })

  const prevCustomerId = useRef<string>()
  const classificationRef = createRef<ClassificationsTextInputRef>()
  const addFieldRef = createRef<AdditionalFieldTextInputRef>()

  const [state, setState] = useState<PostTicketRequest>()
  const [defaultValues, setDefaultValues] = useState<Partial<Pick<PostTicketRequest, 'employeeId' | 'activityTypeId' | 'registerId' | 'stateId'>>>({})
  const [selectedAddressId, setSelectedAddressId] = useState<string>()
  const [selectedSN, setSelectedSN] = useState<SerialNumberBase[]>([])
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')
  const [addFieldsError, setAddFieldsError] = useState(false)

  const emitTicketCreation = useEvent({ key: REFRESH_TICKET_LIST_EVENT_KEY })

  useEffect(() => {
    if (state === undefined || JSON.stringify(state) === '{}') return updateUploadStatus('done')

    if (state?.customerId !== prevCustomerId.current) {
      setSelectedSN([])
      setState(prev => ({ ...prev, contactId: undefined, shippingAddressId: undefined, serialNumberId: undefined }))
    }

    prevCustomerId.current = state?.customerId

    let uploadStatus: UploadStatus = 'done'

    if (
      state.customerId === undefined ||
      state.description === undefined ||
      (state.registerId === undefined && defaultValues.registerId === undefined) ||
      (state.employeeId === undefined && defaultValues.employeeId === undefined) ||
      (state.activityTypeId === undefined && defaultValues.activityTypeId === undefined) ||
      (state.stateId === undefined && defaultValues.stateId === undefined) ||
      addFieldsError
    ) {
      uploadStatus = 'mandatoryMissing'
    } else {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, defaultValues, addFieldsError])

  useEffect(() => {
    if (waitingUpload === 'waiting') return

    if (loadingCreate === 'catched') {
      alert.alert(i18n.t('API_CATCH_TITLE'), appUtils.getBackendErrorMessage(createError))
      updateUploadStatus('waiting')

      return
    }

    if (waitingUpload === 'uploading' && loadingCreate === false && creationResponse) {
      const classificationValue: ClassificationValue[] = []

      classificationRef.current?.getState().forEach(el => classificationValue.push({ classificationId: el.classificationId }))

      createClassification({ fkId: creationResponse.ticketId, classifications: classificationValue })
      addFieldRef.current && createAddFields({ fkId: creationResponse.documentId, additionalfields: addFieldRef.current.getState() })
    }
  }, [loadingCreate, waitingUpload, createError, creationResponse])

  useEffect(() => {
    if (waitingUpload === 'waiting') return

    if (loadingAddFields === 'catched') {
      alert.alert(i18n.t('API_CATCH_TITLE'), i18n.t('ERROR_ADD_FIELD') + '\\r\\n' + appUtils.getBackendErrorMessage(errorAddFields))
      updateUploadStatus('waiting')
    }

    if (loadingClassification === 'catched') {
      alert.alert(i18n.t('API_CATCH_TITLE'), i18n.t('ERROR_CLASSIFICATION') + '\\r\\n' + appUtils.getBackendErrorMessage(errorClassification))
      updateUploadStatus('waiting')
    }

    if (waitingUpload === 'uploading') completeUploadProcedure()
  }, [loadingAddFields, loadingClassification, errorClassification, errorAddFields])

  const completeUploadProcedure = () => {
    updateUploadStatus('done')
    emitTicketCreation.emit()
    onDone?.()
  }

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    const toUpload = state

    if (state.employeeId === undefined) {
      toUpload.employeeId = defaultValues.employeeId
    }

    if (state.stateId === undefined) {
      toUpload.stateId = defaultValues.stateId
    }

    if (state.registerId === undefined) {
      toUpload.registerId = defaultValues.registerId
    }

    if (state.activityTypeId === undefined) {
      toUpload.activityTypeId = defaultValues.activityTypeId
    }

    if (state.employeeId) {
      create(state as Ticket)
    } else {
      create({ ...(state as Ticket), employeeId: userSettings?.employeeId })
    }
  }

  const handleChangeText = (newVal: Partial<PostTicketRequest>) => {
    if (waitingUpload === 'uploading') return
    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
  }

  return (
    <ScrollViewForm>
      <RegistrySelector
        onChange={val => handleChangeText({ registerId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, registerId: id }))}
        {...commonProps}
      />
      <CustomerSelector
        onChange={val => {
          setSelectedAddressId(val?.addressId)
          handleChangeText({ customerId: val === undefined ? undefined : val.id })
        }}
        {...commonProps}
      />
      <ContactSelector
        addressId={selectedAddressId}
        onChange={val => handleChangeText({ contactId: val === undefined ? undefined : val.id })}
        {...commonProps}
        editable={selectedAddressId !== undefined}
        disableFastInput
      />
      <DestinationSelector
        customerId={state?.customerId}
        onChange={val => handleChangeText({ shippingAddressId: val === undefined ? undefined : val.id })}
        {...commonProps}
        editable={state?.customerId !== undefined}
        disableFastInput
      />
      <TextInput
        title={i18n.t('DESCRIPTION') + ' *'}
        value={state?.description ?? ''}
        onChangeText={text => handleChangeText({ description: text === '' ? undefined : text })}
        multiline
        fixMultilineHeight
        {...commonProps}
      />
      <SerialNumberMultiSelector
        customerId={state?.customerId ?? ''}
        values={selectedSN as SerialNumber[]}
        editable={state?.customerId !== undefined}
        shippingAddressId={state?.shippingAddressId}
        onChange={value => {
          const clone = cloneDeep(selectedSN)
          const alreadyPresent = clone.find(el => el.id === value.id) !== undefined

          if (alreadyPresent) {
            const newSNArray = clone.filter(el => el.id !== value.id)
            setSelectedSN(clone.filter(el => el.id !== value.id))
            handleChangeText({ serialnumberIds: value ? newSNArray.map(el => el.id) : undefined })
          } else {
            setSelectedSN(prev => [...prev, { number: value.number, id: value.id }])
            state && handleChangeText({ serialnumberIds: state.serialnumberIds ? [...state.serialnumberIds, value.id] : [value.id] })
          }
        }}
        {...commonProps}
      />
      <ActivityTypeSelector
        onChange={val => handleChangeText({ activityTypeId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, activityTypeId: id }))}
        mandatory
        {...commonProps}
      />
      <TicketStateSelector
        onChange={val => handleChangeText({ stateId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, stateId: id }))}
        mandatory
        {...commonProps}
      />
      <TicketPrioritySelector onChange={val => handleChangeText({ priorityId: val === undefined ? undefined : val.id })} {...commonProps} />
      <EmployeeSelector
        onChange={val => handleChangeText({ employeeId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, employeeId: id }))}
        mandatory
        disableBackdropOpacity
        {...commonProps}
      />
      <TeamSelector onChange={val => handleChangeText({ teamId: val === undefined ? undefined : val.id })} {...commonProps} />
      <TextInput
        title={i18n.t('TECHNICAL_DESCRIPTION')}
        value={state?.technicalDescription ?? ''}
        onChangeText={text => handleChangeText({ technicalDescription: text === '' ? undefined : text })}
        multiline
        fixMultilineHeight
        {...commonProps}
      />
      <TextInput
        title={i18n.t('CUSTOMER_DESCRIPTION')}
        value={state?.customerDescription ?? ''}
        onChangeText={text => handleChangeText({ customerDescription: text === '' ? undefined : text })}
        multiline
        fixMultilineHeight
        {...commonProps}
      />
      <TicketCreationAddFieldGroup ref={addFieldRef} onChange={() => setState(prev => cloneDeep(prev))} onError={setAddFieldsError} />
      <TicketCreationClassificationsGroup ref={classificationRef} />
    </ScrollViewForm>
  )
}

export default forwardRef(TicketCreationView)
