import { IM, IMLayout, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import React, { useEffect, useMemo } from 'react'
import { Animated, Platform } from 'react-native'
import { useSetRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { SMTicketType, Ticket } from '../../apis/types/apiResponseTypes'
import { AnimatedButtonHideEvent } from '../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { MasterDetailProp } from '../../components/MasterDetail/contexts/MasterDetailContext'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import MasterDetail from '../../components/MasterDetail/MasterDetails'
import NoEntry from '../../components/NoEntry'
import useScreen from '../../components/screen/hooks/useScreen'
import useSearch from '../../components/screen/hooks/useSearch'
import { TicketScreenViewType } from '../../components/screen/types'
import { ADD_TICKET_BUTTON_ID } from '../../constants/ButtonIds'
import { REFRESH_TICKET_LIST_EVENT_KEY, SECTION_LIST_CLOSE_TO_END_EVENT_KEY } from '../../constants/EmitterKeys'
import { FilterType, GroupType, OrderType } from '../../contexts/FilterContext'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import TicketCreationModal from '../../modals/tickets/TicketCreationModal'
import { TicketStackParamList } from '../../navigation/types'
import { FilterElement } from '../../types'
import appUtils from '../../utils/appUtils'
import { ticketFilterEnableAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import CalendarView from './calendar/CalendarView'
import TicketDetailView from './TicketDetailView'
import TicketList from './TicketList'
import TicketListMapView from './TicketListMapView'

const ticketListButtonAnimationValue = new Animated.Value(0)
const ticketDetailAnimationValue = new Animated.Value(0)

interface Props {
  splitViewEnabled: boolean
}

export default function TicketView({ splitViewEnabled }: Props) {
  const { ticketView } = useScreen()
  const { theme } = useTheme()
  const { search } = useSearch()
  const { i18n, language } = useLanguage()
  const { userSettings } = useUserSettings()
  const controller = useModalController()
  const { isSmallDevice, isPortrait } = useLayout()
  const mdNavigation = useMasterDetail<NavigationProp<TicketStackParamList>>()
  const { detailParams } = useMasterDetail<MasterDetailProp<TicketStackParamList, 'TicketDetail'>>()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const { filters, groups, orders, initFilters } = useFilter()

  const enableFilter = useSetRecoilState(ticketFilterEnableAtom)

  const emitter = useEvent<AnimatedButtonHideEvent>({ key: SECTION_LIST_CLOSE_TO_END_EVENT_KEY })
  useEvent({ key: REFRESH_TICKET_LIST_EVENT_KEY }, () => load())

  const { item: tickets, loadItem: loadTickets, loading } = useControlledLoader(api.getTicket)

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    mdNavigation.setDetailParams(undefined)
  }, [splitViewEnabled])

  useEffect(() => {
    if (loading === false) {
      const activities: FilterElement[] = []
      const assistance: FilterElement[] = []
      const team: FilterElement[] = []
      const zone: FilterElement[] = []

      tickets?.forEach(ticket => {
        ticket.activityType && activities.push({ id: ticket.activityType, active: false })
        ticket.area && zone.push({ id: ticket.area, active: false })
        ticket.team && team.push({ id: ticket.team, active: false })
        ticket.maintenanceType && assistance.push({ id: ticket.maintenanceType, active: false })
      })

      initFilters(activities, assistance, zone, team)
      enableFilter(true)
    } else {
      enableFilter(false)
    }
  }, [loading, language])

  function load() {
    loadTickets({ state: 'Editing', smTicketType: SMTicketType.InElaboration })
  }

  const filteredTickets: (Ticket | string)[] | undefined = useMemo(() => {
    let ticketClone = cloneDeep(tickets)

    // Ticket search
    ticketClone = ticketClone
      ? appUtils.filter(ticketClone, search, [
          'code',
          'customer',
          'customerId',
          'articleDescription',
          'serialnumberLocation',
          'serialnumber',
          'serialnumberManufacturerNumber',
          'description',
          'shippingAddress',
          'location',
        ])
      : []

    // Ticket grouping
    let ticketGrouped: { [key: string]: Ticket[] } = { undefined: ticketClone }
    const activeGroups = groups.find(group => group.active)
    switch (activeGroups?.data.id) {
      case GroupType.Assistance: {
        ticketGrouped = groupBy(ticketClone, el => el.maintenanceType)
        ticketGrouped = ticketUtils.replaceUndefinedSection(ticketGrouped, i18n.t('NO_ASSISTANCE_TYPE'))

        break
      }
      case GroupType.Customer: {
        ticketGrouped = groupBy(ticketClone, el => el.customer)
        ticketGrouped = ticketUtils.replaceUndefinedSection(ticketGrouped, i18n.t('NO_CUSTOMER'))

        break
      }
      case GroupType.Zone: {
        ticketGrouped = groupBy(ticketClone, el => el.area)
        ticketGrouped = ticketUtils.replaceUndefinedSection(ticketGrouped, i18n.t('NO_ZONE'))

        break
      }
    }

    const activeOrder = orders.find(order => order.active)
    switch (activeOrder?.data.id) {
      case OrderType.DateAscending: {
        Object.keys(ticketGrouped).forEach(key => {
          ticketGrouped[key] = ticketUtils.sortDateString(ticketGrouped[key], 'dateTimeForOrder', 'asc', (a, b) => a.code.localeCompare(b.code))
        })

        break
      }
      case OrderType.DateDescending: {
        Object.keys(ticketGrouped).forEach(key => {
          ticketGrouped[key] = ticketUtils.sortDateString(ticketGrouped[key], 'dateTimeForOrder', 'desc', (a, b) => {
            return b.code.localeCompare(a.code)
          })
        })

        break
      }
      case OrderType.PlanningAscending: {
        Object.keys(ticketGrouped).forEach(key => {
          ticketGrouped[key] = ticketUtils.sortDateString(ticketGrouped[key], 'planDateTimeForOrder', 'asc', (a, b) => a.code.localeCompare(b.code))
        })

        break
      }
      case OrderType.PlanningDescending: {
        Object.keys(ticketGrouped).forEach(key => {
          ticketGrouped[key] = ticketUtils.sortDateString(ticketGrouped[key], 'planDateTimeForOrder', 'desc', (a, b) => {
            return b.code.localeCompare(a.code)
          })
        })

        break
      }
      case OrderType.Priority: {
        Object.keys(ticketGrouped).forEach(key =>
          ticketGrouped[key].sort((a, b) => {
            return b.priority - a.priority
          })
        )

        break
      }
    }

    const activeFilters = ticketUtils.activeFilters(filters)
    activeFilters.forEach(filter => {
      switch (filter.id) {
        case FilterType.Teams: {
          Object.keys(ticketGrouped).forEach(key =>
            Object.assign(ticketGrouped, {
              ...ticketGrouped,
              [key]: ticketGrouped[key].filter(clone => (clone.team ? filter.elements.find(el => el.id === clone.team) !== undefined : false)),
            })
          )
          break
        }
        case FilterType.Activity: {
          Object.keys(ticketGrouped).forEach(key =>
            Object.assign(ticketGrouped, {
              ...ticketGrouped,
              [key]: ticketGrouped[key].filter(clone =>
                clone.activityType ? filter.elements.find(el => el.id === clone.activityType) !== undefined : false
              ),
            })
          )
          break
        }
        case FilterType.Assistance: {
          Object.keys(ticketGrouped).forEach(key =>
            Object.assign(ticketGrouped, {
              ...ticketGrouped,
              [key]: ticketGrouped[key].filter(clone =>
                clone.maintenanceType ? filter.elements.find(el => el.id === clone.maintenanceType) !== undefined : false
              ),
            })
          )
          break
        }
        case FilterType.Zone: {
          Object.keys(ticketGrouped).forEach(key =>
            Object.assign(ticketGrouped, {
              ...ticketGrouped,
              [key]: ticketGrouped[key].filter(clone => (clone.area ? filter.elements.find(el => el.id === clone.area) !== undefined : false)),
            })
          )

          break
        }
      }
    })

    const data: (string | Ticket)[] = []
    const keys = Object.keys(ticketGrouped)

    keys.forEach(key => {
      if (ticketGrouped[key].length !== 0) {
        if (key !== 'undefined') {
          data.push(key)
          data.push(...ticketGrouped[key])
        } else {
          data.push(...ticketGrouped[key])
        }
      }
    })

    return data
  }, [filters, groups, orders, tickets, search])

  const handleTicketCreation = () =>
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') ? navigation.navigate('TicketCreation') : controller.show()

  const common = {
    tickets: filteredTickets,
    loading,
    onCreate: userSettings?.allowCreateTicket ? handleTicketCreation : undefined,
    reloadTickets: load,
  }

  return (
    <>
      {ticketView === TicketScreenViewType.LIST && (
        <MasterDetail>
          <MasterDetail.Master>
            <TicketList
              onTicketPress={tck => mdNavigation.navigate('TicketDetail', { ticketId: tck.id, ticketCode: tck.code })}
              type="normal"
              showSmallDeviceCards={mdNavigation.active}
              view="normal"
              selectedTicketId={splitViewEnabled ? detailParams?.ticketId : undefined}
              buttonAnimationValue={ticketListButtonAnimationValue}
              {...common}
            />
          </MasterDetail.Master>
          <MasterDetail.Detail>
            <IM.View style={[IMLayout.flex.f1, { backgroundColor: theme.background.default }]}>
              {detailParams ? (
                <TicketDetailView
                  animatedValue={ticketDetailAnimationValue}
                  ticketId={detailParams.ticketId}
                  ticketCode={detailParams.ticketCode}
                  isPast={false}
                  forceLayout={isPortrait ? 'small' : 'large'}
                />
              ) : (
                <NoEntry description={i18n.t('NO_TICKET_SELECTED')} />
              )}
            </IM.View>
          </MasterDetail.Detail>
        </MasterDetail>
      )}
      {ticketView === TicketScreenViewType.LOCATION && (
        <TicketListMapView
          hideAddButton={hide => emitter.emit({ id: ADD_TICKET_BUTTON_ID, hide })}
          buttonAnimationValue={ticketListButtonAnimationValue}
          {...common}
        />
      )}
      {ticketView === TicketScreenViewType.CALENDAR && <CalendarView />}
      <TicketCreationModal controller={controller} />
    </>
  )
}
