import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import * as React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'

interface Props {
  direction?: 'horizontal' | 'vertical'
  type?: 'primary' | 'secondary'
  color?: string
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const Separator = React.memo(function Separator({ direction = 'horizontal', type = 'secondary', color, spacing, style }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View
      spacingType="margin"
      spacing={direction === 'horizontal' ? (spacing ?? 'vertical') : (spacing ?? 'horizontal')}
      style={[
        direction === 'horizontal' && styles.separatorHorizontal,
        direction === 'vertical' && styles.separatorVertical,
        direction === 'horizontal' && {
          borderBottomWidth: StyleSheet.hairlineWidth + (type === 'primary' ? 0.7 : 0),
          borderBottomColor: color ?? theme.separator,
        },
        direction === 'vertical' && {
          borderLeftWidth: StyleSheet.hairlineWidth + (type === 'primary' ? 0.7 : 0),
          borderLeftColor: color ?? theme.separator,
        },
        style,
      ]}
    />
  )
})

export default Separator

const styles = StyleSheet.create({
  separatorVertical: {
    height: '100%',
    borderRadius: 100,
  },
  separatorHorizontal: {
    width: '100%',
    borderRadius: 100,
  },
})
