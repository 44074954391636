import { ApiClient } from '@infominds/react-api'
import { Storage, useAlert, useLanguage } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'

import { STORAGE_KEY_DATA_PROVIDER_STATE } from '../../constants/Keys'
import { DataProviderCore } from '../DataProviderCore'
import { DataStorage } from '../DataStorage'
import { dataProviderCore } from '../InitDataProvider'
import { DataProviderState, DataProviderStateAction, DataProviderStateActions } from '../types'
import { DataProviderSyncManager } from '../utils/DataProviderSyncManager'

export type DataProviderContextType = {
  client: ApiClient
  dispatch: (action: DataProviderStateAction) => void
  dataProvider: DataProviderCore
  dataStorage: DataStorage
  dataSyncManager: DataProviderSyncManager
} & DataProviderState

export const DataProviderContext = createContext<DataProviderContextType | null>(null)

export type DataProviderContextProviderProps = PropsWithChildren<{
  /**
   * Automatic up-sync when back online
   * @default false
   */
  autoSync?: boolean
}>

export function DataProviderContextProvider({ children, autoSync = false }: DataProviderContextProviderProps) {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const [init, setInit] = useState(false)
  const [state, setState] = useState<DataProviderState>({ isOnline: true, syncState: 'none' })

  const providerStateStorage = Storage(STORAGE_KEY_DATA_PROVIDER_STATE)

  useEffect(() => {
    if (init === true) return

    providerStateStorage
      .load()
      .then(loadedState => {
        if (loadedState) {
          setState(loadedState)
        }

        dataProviderCore.Init(loadedState as DataProviderState, dispatchState, { autoSync })
      })
      .catch(console.error)
      .finally(() => setInit(true))
  }, [init, providerStateStorage])

  useEffect(() => {
    if (!state.failedRequests?.length) return

    alert.alert(i18n.t('ERROR'), i18n.t('DATA_SYNC_ERROR'), undefined, state.failedRequests?.length)
  }, [state.failedRequests])

  function dispatchState(action: DataProviderStateAction) {
    setState(prevState => {
      let newState: DataProviderState = { ...prevState }
      const { type, payload } = action

      switch (type) {
        case DataProviderStateActions.ClearError:
          if (!newState.failedRequests?.length) return newState
          newState = {
            ...newState,
            failedRequests: [],
          }
          break
        case DataProviderStateActions.UpdateOnlineState:
          if (payload?.forcedOfflineMode !== undefined) {
            newState.forcedOfflineMode = payload.forcedOfflineMode
          }
          if (newState.forcedOfflineMode) {
            newState.isOnline = false
          } else if (payload?.isOnline !== undefined) {
            newState.isOnline = !!payload.isOnline
          }
          newState = {
            ...newState,
          }
          break
        case DataProviderStateActions.UpdateDataToSync:
          newState = {
            ...newState,
            pendingDataToSync: payload?.pendingDataToSync,
          }
          break
        case DataProviderStateActions.UpSyncFailed:
          if (!payload?.failedRequests?.length) return newState
          newState = {
            ...newState,
            failedRequests: payload?.failedRequests ?? [],
          }
          break
        case DataProviderStateActions.Enable:
          if (payload?.enabled) {
            newState = {
              ...newState,
              enabled: true,
            }
          } else {
            newState = {
              ...newState,
              enabled: false,
              forcedOfflineMode: false,
              isOnline: true,
              lastSyncDate: undefined,
            }
          }
          break
        case DataProviderStateActions.SyncComplete:
          newState = {
            ...newState,
            lastSyncDate: new Date().getTime(),
          }
          break
        case DataProviderStateActions.UpdateSyncState:
          newState = {
            ...newState,
            syncState: payload?.syncState,
          }
          break
        default:
          break
      }

      providerStateStorage.save(newState).catch(err => console.error('Can not save data provider state', err))
      dataProviderCore.state = newState
      return newState
    })
  }

  return (
    <DataProviderContext.Provider
      value={{
        ...state,
        client: dataProviderCore.client,
        dispatch: dispatchState,
        dataProvider: dataProviderCore,
        dataStorage: dataProviderCore.dataStorage,
        dataSyncManager: dataProviderCore.syncManager,
      }}>
      {init && children}
    </DataProviderContext.Provider>
  )
}
