import ImageResizer from '@bam.tech/react-native-image-resizer'
import { IM, IMLayout, useDimensions, useTheme } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Image, Platform } from 'react-native'
import ReactNativeBlobUtil from 'react-native-blob-util'
import Signature, { SignatureViewRef } from 'react-native-signature-canvas'

import CONSTANTS from '../constants/Constants'
import useSignature from '../hooks/useSignature'
import { SignaturePadRef, ThemeColorExpanded } from '../types'
import { utils } from '../utils/utils'

interface Props {
  signature: string | null
  width: number | undefined
  height: number | undefined
  disabled?: boolean
  onOk: (base64: string, penColor: string) => void
}

const SignaturePad = ({ signature, width, height, disabled, onOk }: Props, ref: ForwardedRef<SignaturePadRef>) => {
  useImperativeHandle(ref, () => ({
    clearSignature: () => innerRef.current?.clearSignature(),
    readSignatures: () => innerRef.current?.readSignature(),
  }))

  const { isSmallDevice } = useDimensions()
  const { setScrollEnabled } = useSignature()
  const { theme } = useTheme<ThemeColorExpanded>()

  const penColor = theme.text.default

  const [uuid, setUuid] = useState(utils.generateUuid())

  const timerId = useRef<NodeJS.Timeout>()
  const innerRef = createRef<SignatureViewRef>()
  const begin = useRef(false)

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [disabled])

  const onEnd = () => {
    begin.current = false

    timerId.current && clearTimeout(timerId.current)
    timerId.current = setTimeout(() => {
      if (begin.current === false) {
        setScrollEnabled(true)
      }
    }, CONSTANTS.signatureTimeout)
  }

  const onBegin = () => {
    begin.current = true
    setScrollEnabled(false)
  }

  const handleOK = (newSign: string) => {
    if (isSmallDevice) {
      rotateAndSave(newSign)
    } else {
      onOk(newSign, penColor)
    }
  }

  const style = `
    .m-signature-pad--footer {
        display: none;
    }

    .m-signature-pad {
        position: absolute;
        width: ${width ?? 0}px;
        height: ${height ?? 0}px;
        margin-top: 0px;
        margin-left: 0px;
        border: 0px;
        background-color: ${disabled ? theme.inputBox.background.disabled : theme.inputBox.background.active};
        border-radius: ${IMLayout.borderRadius}px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    }  

    body {
        background-color: ${theme.card.background};
        font-family: Helvetica, Sans-Serif;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .m-signature-pad--body {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        border-radius: ${IMLayout.borderRadius}px;
        border: 1px solid ${disabled ? theme.inputBox.border.disabled : theme.inputBox.border.active};
    }
  `

  const rotateAndSave = (srcBase64: string) => {
    rotate(srcBase64)
  }

  const rotate = (src: string) => {
    // TODO: not working on iOS
    if (Platform.OS === 'android') {
      ImageResizer.createResizedImage(src, width ?? 0, height ?? 0, 'PNG', 100, 90, undefined)
        .then(response => {
          ReactNativeBlobUtil.fs
            .readFile(response.uri, 'base64')
            .then(val => {
              onOk('data:image/png;base64,' + (val as string), penColor)
            })
            .catch(err => console.error('Failed reading file', err))
        })
        .catch(err => console.error('Failed rotating file', err))
    } else {
      onOk(src, penColor)
    }
  }

  return (
    <>
      {signature ? (
        <Image
          resizeMode="contain"
          style={{ width: width, height: isSmallDevice ? (Platform.OS === 'ios' ? height : (height ?? 0) * 0.4) : height }}
          source={{ uri: signature }}
        />
      ) : (
        <IM.View style={{ height }}>
          {width && height && (
            <IM.View pointerEvents={disabled ? 'none' : 'auto'} style={IMLayout.flex.f1}>
              <Signature
                key={uuid}
                ref={innerRef}
                onOK={handleOK}
                webStyle={style}
                onBegin={onBegin}
                onEnd={onEnd}
                penColor={penColor}
                loadingOverlayStyle={{ backgroundColor: theme.card.background }}
              />
            </IM.View>
          )}
        </IM.View>
      )}
    </>
  )
}

export default forwardRef(SignaturePad)
