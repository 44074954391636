import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { Platform, Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'

export type CardBasicProps = {
  selected?: boolean
  children?: ReactNode
  onPress?: () => void
  onLongPress?: () => void
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  borderless?: boolean
  spacing?: SpacingProps
}

export default function CardBasic(props: CardBasicProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View
      style={
        Platform.OS === 'ios' &&
        !props.borderless && [
          IMLayout.shadow,
          {
            backgroundColor: theme.background.default,
            borderRadius: IMLayout.borderRadius,
          },
        ]
      }>
      <IM.View
        style={[
          styles.baseCard,
          Platform.OS !== 'ios' && !props.borderless && IMLayout.shadow,
          props.selected && { ...styles.selected, borderColor: theme.card.highlight },
          !props.borderless && { backgroundColor: theme.card.background },
          props.style,
        ]}
        spacing={props.spacing}>
        {/* workaround in web TouchableOpacity disabled property is not working*/}
        {(Platform.OS === 'web' && props.onPress === undefined && props.onLongPress === undefined) || props.disabled ? (
          <>{props.children}</>
        ) : (
          <Pressable
            onPress={props.onPress}
            onLongPress={props.onLongPress}
            disabled={(props.onPress === undefined && props.onLongPress === undefined) || props.disabled}
            style={({ pressed }) => [
              {
                backgroundColor: pressed ? theme.pressable.highlight : 'transparent',
              },
            ]}>
            {props.children}
          </Pressable>
        )}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  baseCard: {
    borderRadius: IMLayout.borderRadius,
    minHeight: IMLayout.borderRadius * 2,
    minWidth: IMLayout.borderRadius * 2,
    flex: 1,
    overflow: 'hidden',
  },
  selected: {
    borderWidth: 1,
  },
})
