import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { Fragment } from 'react'
import { StyleSheet } from 'react-native'

import { FilterType, PlanDateFilterType } from '../../contexts/FilterContext'
import { FormProvider } from '../../contexts/FormContext'
import { Filter, ThemeColorExpanded } from '../../types'
import DateInput, { convertIsoDateFromInputFormat } from '../input/DateInput'

type Props = {
  filter: Filter<FilterType>
  onPress: (id: string, date: string | undefined) => void
}

const FilterDate = ({ filter, onPress }: Props) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const noFilter = () => (
    <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeRegular - 1 }}>
      {i18n.t('NO_TICKET_FILTER')}
    </IM.Text>
  )

  const from = filter.elements.find(el => el.id === PlanDateFilterType.PlanDateFrom)?.value
  const to = filter.elements.find(el => el.id === PlanDateFilterType.PlanDateTo)?.value

  return (
    <IM.View key={filter.id}>
      <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular - 1 }}>{filter.name}</IM.Text>
      <IM.View style={styles.filterContainer}>
        <FormProvider>
          <IM.View style={styles.inputContainer}>
            {filter.elements.length === 0 ? (
              <>{noFilter()}</>
            ) : (
              filter.elements.map((el, index) => {
                return (
                  <Fragment key={el.id}>
                    <IM.View style={IMLayout.flex.f1}>
                      <DateInput
                        key={el.id}
                        value={el.value ? convertIsoDateFromInputFormat(el.value) : undefined}
                        minimumDate={el.id === PlanDateFilterType.PlanDateTo && from ? new Date(from) : undefined}
                        maximumDate={el.id === PlanDateFilterType.PlanDateFrom && to ? new Date(to) : undefined}
                        onChangeDate={date => onPress(el.id, date?.toISOString())}
                        style={styles.date}
                      />
                    </IM.View>
                    {index === 0 && <IM.Icon icon={['fal', 'arrow-right']} color={theme.text.detail} size={18} style={styles.icon} />}
                  </Fragment>
                )
              })
            )}
          </IM.View>
        </FormProvider>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  filterContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 4,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  date: {
    padding: 10,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: 10,
  },
})

export default FilterDate
