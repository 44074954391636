import { IM, IMLayout, StringUtils, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { mediaUtils } from '@infominds/react-native-media'
import React, { memo, useState } from 'react'
import { StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'

import { File } from '../apis/types/apiResponseTypes'
import { ThemeColorExpanded } from '../types'
import ticketUtils from '../utils/TicketUtils'
import Pressable from './Infominds/Pressable'
import BaseTextInputSpinningIcon from './input/baseTextInput/BaseTextInputSpinningIcon'

interface Props {
  file: File
}

const InfoboxArticleItem = memo(function InfoboxArticleItem({ file }: Props) {
  const { i18n } = useLanguage()
  const { url, bearerToken } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [download, setDownload] = useState(false)

  const value = ticketUtils.getInfoboxIcon(file.extension, theme)

  if (!url || !bearerToken) return <></>

  return (
    <Pressable
      onPress={() => {
        setDownload(true)
        mediaUtils.download(
          file.id,
          file.filename,
          {
            url: id => `${url}/api/common/infoboxfileonly?infoboxTyp=Article&infoboxFileId=${id}`,
            headers: { Authorization: bearerToken },
          },
          {
            cancel: i18n.t('CANCEL'),
            description: StringUtils.stringValueReplacer(i18n.t('ARCHIVE_PERM_DESCRIPTION'), DeviceInfo.getApplicationName()),
            settings: i18n.t('TAB_SETTINGS'),
            title: i18n.t('ARCHIVE_PERM_TITLE'),
          },
          () => setDownload(false)
        )
      }}
      disabled={download}>
      <IM.View style={styles.file}>
        <IM.View style={[IMLayout.flex.f8, styles.textContainer]}>
          <IM.View style={styles.iconContainer}>
            <IM.Icon icon={value.icon} color={value.color} size={value.size} />
          </IM.View>
          <IM.View style={IMLayout.flex.f12}>
            <IM.Text>{file.filename}</IM.Text>
          </IM.View>
        </IM.View>

        <IM.View style={styles.icon}>
          {download ? <BaseTextInputSpinningIcon loading style={styles.spinner} /> : <IM.Icon icon={['fal', 'arrow-down-to-bracket']} />}
        </IM.View>
      </IM.View>
    </Pressable>
  )
})

export default InfoboxArticleItem

const styles = StyleSheet.create({
  file: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: { flex: 1, alignItems: 'flex-end', justifyContent: 'center' },
  iconContainer: { paddingRight: 10 },
  spinner: {
    paddingHorizontal: 0,
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  title: {
    marginBottom: 4,
  },
})
