import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Team } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_TEAM } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: string | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Team | undefined) => void
}

export default function TeamSelector({ id, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const { company } = useAuthentication()

  const [selectedId, setSelectedId] = useState(id ?? CONSTANTS.noSelectionId)
  const [search, setSearch] = useState('')

  const { item: data, loadItem, loading } = useControlledLoader(api.getTeam, { id: REQUEST_TEAM })
  const noSelectionEntry: Team = { id: CONSTANTS.noSelectionId, description: i18n.t('NO_SELECTION'), code: '' }

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadItem({ companyId: company })

  const handleOnChange = (newValue: Team | undefined) => {
    setSelectedId(newValue?.id ?? CONSTANTS.noSelectionId)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  const formatString = (item: Team) => (item.code === '' ? item.description : `${item.description} (${item.code})`)

  const render = ({ item }: SectionListRenderItemInfo<Team, ListSection<Team>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.id === selectedId} text={formatString(item)} />
  }

  return (
    <SelectInput
      data={appUtils.filter(data ? [noSelectionEntry, ...data] : [noSelectionEntry], search, ['code'])}
      value={data?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={refresh}
      title={i18n.t('TEAM')}
      screenTitle={i18n.t('TEAMS')}
      noDataMessage={i18n.t('NO_TEAM_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={formatString}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      disableBackdropOpacity
      {...props}
    />
  )
}
