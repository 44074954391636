import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import AccessCredentials from '../../components/AccessCredentials'
import AccessInfo from '../../components/AccessInfo'
import Card from '../../components/card/Card'
import { MasterDetailProp } from '../../components/MasterDetail/contexts/MasterDetailContext'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import NoEntry from '../../components/NoEntry'
import { AccessDataStackParamList } from '../../navigation/types'

export default function AccessDataDetailView() {
  const { detailParams: params } = useMasterDetail<MasterDetailProp<AccessDataStackParamList, 'AccessDataDetail'>>()
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  return (
    <IM.View style={[styles.container, { backgroundColor: theme.background.default }]}>
      {params ? (
        <IM.ScrollView spacing="all" contentContainerStyle={{ margin: IMLayout.horizontalMargin }}>
          <Card>
            <Card.Content disableDivider>
              <AccessCredentials
                groupAccessGranted={params.groupAccessGranted}
                levelAccessGranted={params.levelAccessGranted}
                color={params.color}
                data={params.accessData}
                open
              />
            </Card.Content>
            <Card.Content>
              <AccessInfo
                data={params.accessData}
                groupAccessGranted={params.groupAccessGranted}
                levelAccessGranted={params.levelAccessGranted}
                open
                showEmailField
              />
            </Card.Content>
          </Card>
        </IM.ScrollView>
      ) : (
        <NoEntry description={i18n.t('NO_ACCESS_DATA_SELECTED')} />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, zIndex: 2 },
})
