import { IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'

import { ThemeColorExpanded } from '../types'
import AnimatedButton, { AnimatedButtonProps } from './Infominds/AnimatedButton'

interface Props {
  error?: boolean
  text?: string
}

export default function DoneButton({ disabled, onPress, error, text, ...props }: AnimatedButtonProps & Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  return (
    <AnimatedButton
      {...props}
      disabled={disabled}
      icon={['fal', 'check']}
      iconSize={21}
      onPress={onPress}
      text={text ?? i18n.t('DONE')}
      color={disabled ? theme.text.placeholder : IMStyle.palette.white}
      style={{
        backgroundColor: disabled
          ? colorScheme === 'light'
            ? Color(theme.background.default).darken(0.03).toString()
            : theme.header.main.background
          : error
            ? theme.general.error
            : theme.general.info,
      }}
    />
  )
}
