import { IM, IMLayout, SpacingProps, useDimensions, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { Image, StyleSheet } from 'react-native'

import { TicketArticle } from '../../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import DynamicView from '../../../components/Infominds/DynamicView'
import Pressable from '../../../components/Infominds/Pressable'
import NavigationIcon from '../../../components/NavigationIcon'
import TextWithHeader from '../../../components/TextWithHeader'
import CONSTANTS from '../../../constants/Constants'
import useUserSettings from '../../../hooks/useUserSettings'
import EditArticleLocationModal from '../../../modals/EditArticleLocationModal'
import TimeUtils from '../../../utils/TimeUtils'

interface Props {
  article: TicketArticle
  spacing?: SpacingProps
}

const TicketArticleInfoCard = memo(function TicketArticleInfoCard({ spacing, article }: Props) {
  const { userSettings } = useUserSettings()
  const controller = useModalController()
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()

  const date = useMemo(
    () => (article.serialnumberOutgoingDate ? TimeUtils.format(article.serialnumberOutgoingDate, language) : '-'),
    [article, language]
  )

  const image = () => (
    <>
      {article.thumbImageAsPNG && (
        <IM.View style={[!isSmallDevice && styles.marginRight]} spacing={isSmallDevice ? 'bottom' : 'none'} spacingType="margin">
          <Image source={{ uri: `data:image/png;base64,${article.thumbImageAsPNG}` }} style={styles.image} />
        </IM.View>
      )}
    </>
  )

  return (
    <>
      <IM.Card head={<CardLeftTitle text={i18n.t('INFORMATION')} />} spacing={spacing}>
        <IM.View style={!isSmallDevice && IMLayout.flex.row}>
          {image()}
          <DynamicView style={[IMLayout.flex.f1, styles.item]} flexLimitSmallDevice={4} flexLimitLargeDevice={6}>
            <TextWithHeader
              header={i18n.t('SN_MANUFACTURER')}
              text={article.serialnumberNumberManufactor ?? '-'}
              style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginRight]}
              spacing="bottom"
            />
            <TextWithHeader
              header={i18n.t('ARTICLE')}
              text={article.articleCode}
              style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginLeft]}
              spacing="bottom"
            />
            <TextWithHeader
              header={i18n.t('MODEL')}
              text={article.articleModel ?? '-'}
              style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginRight]}
              spacing="bottom"
            />
            {userSettings?.allowChangingSerialNumberLocation ? (
              <Pressable
                containerStyle={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginLeft]}
                style={[IMLayout.flex.row, styles.location]}
                spacing="bottom"
                onPress={() => controller.show()}>
                <TextWithHeader header={i18n.t('LOCATION')} text={article.serialnumberLocation ?? '-'} />
                <NavigationIcon />
              </Pressable>
            ) : (
              <TextWithHeader
                header={i18n.t('LOCATION')}
                text={article.serialnumberLocation ?? '-'}
                style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginLeft]}
              />
            )}
            <TextWithHeader
              header={i18n.t('OUT_GOING')}
              text={date}
              style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginRight]}
              spacing="bottom"
            />
            <IM.View style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginLeft]} />
            <TextWithHeader
              header={i18n.t('DESCRIPTION')}
              text={article.articleDescription ?? '-'}
              style={[IMLayout.flex.f12, !isSmallDevice && styles.marginLeft]}
            />
          </DynamicView>
        </IM.View>
      </IM.Card>
      <EditArticleLocationModal controller={controller} article={article} />
    </>
  )
})

export default TicketArticleInfoCard

const styles = StyleSheet.create({
  image: {
    width: 90,
    height: 90,
    resizeMode: 'contain',
    borderRadius: IMLayout.borderRadius,
  },
  item: { justifyContent: 'center' },
  marginHorizontal: {
    marginHorizontal: CONSTANTS.margin,
  },
  marginRight: { marginRight: CONSTANTS.margin },
  marginLeft: { marginLeft: CONSTANTS.margin },
  location: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    paddingRight: 12,
  },
})
