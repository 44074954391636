import { IM, IMLayout, IMStyle, useEvent, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../apis/apiCalls'
import { TicketActivity } from '../apis/types/apiResponseTypes'
import { EDIT_ACTIVITY_TIME_EVENT_KEY } from '../constants/EmitterKeys'
import { REQUEST_ACTIVITY_OPEN_TIMES } from '../constants/Keys'
import useLayout, { ForceLayoutType } from '../hooks/useLayout'
import useUserSettings from '../hooks/useUserSettings'
import { TicketStackParamList } from '../navigation/types'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'
import ticketUtils from '../utils/TicketUtils'
import Chip from './Chip'
import EmployeeBadge from './EmployeeBadge'
import Error from './Error'
import DynamicView from './Infominds/DynamicView'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import Pressable from './Infominds/Pressable'
import Separator from './Infominds/Separator'
import NavigationIcon from './NavigationIcon'
import SkeletonCard from './skeleton/SkeletonCard'

interface Props {
  activity: TicketActivity
  ticketCode: string
  index: number
  isLast: boolean
  onlyRead?: boolean
  disableLastSeparator?: boolean
  forceLayout: ForceLayoutType | undefined
}

export default function ActivityComponent({ activity, ticketCode, index, isLast, onlyRead, disableLastSeparator, forceLayout }: Props) {
  const { userSettings } = useUserSettings()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { theme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const { item: times, loadItem: getTimes, loading } = useControlledLoader(api.getActivityTime, { id: REQUEST_ACTIVITY_OPEN_TIMES + activity.id })

  useEvent<string | undefined>({ key: EDIT_ACTIVITY_TIME_EVENT_KEY }, id => activity.id === id && refresh())

  const marginBottom = isSmallDevice ? 4 : 0
  const marginTop = isSmallDevice ? 4 : 0

  useEffect(() => {
    refresh()
  }, [userSettings])

  const refresh = () => userSettings && getTimes({ employeeId: userSettings.employeeId, openTime: true })

  const activityIcon = () => (
    <IM.Icon
      icon={ticketUtils.getTicketIconByActivity(activity.activityTypeType)}
      size={isSmallDevice ? 30 : 26}
      style={{ marginRight: IMLayout.horizontalMargin * 2 }}
    />
  )

  const activityName = () => (
    <IM.View style={{ marginBottom }}>
      <IM.Text style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightMedium }]}>{activity.code}</IM.Text>
    </IM.View>
  )

  const stateBadge = () => <Chip value={activity.stateDescription} />

  const employeeBadge = () => {
    return (
      <IM.View style={{ marginTop }}>
        {activity.employee && activity.employeeId && <EmployeeBadge name={activity.employee} id={activity.employeeId} showName />}
      </IM.View>
    )
  }

  const activityDescription = () => {
    return (
      <IM.View style={{ marginTop }}>
        <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightLight }} numberOfLines={3}>
          {activity.technicalDescription}
        </IM.Text>
      </IM.View>
    )
  }

  const activityEditable = useMemo(() => (userSettings ? ticketUtils.isActivityEditable(activity, userSettings) : false), [activity, userSettings])

  const openTimes = times ? times.length !== 0 && times[0].activityId === activity.id : true

  return (
    <IM.View style={styles.container}>
      {index !== 0 && <Separator spacing="none" />}
      <IM.Card
        borderless
        noContentSpacing
        style={[styles.align, IMLayout.flex.f1]}
        buttons={{
          buttons:
            onlyRead === false && userSettings && userSettings.startsTheActivityTimeAsSoonAsTheActivityIsOpened === false
              ? [
                  {
                    icon: ['fal', 'stopwatch'],
                    onPress:
                      activityEditable && !openTimes
                        ? () => {
                            navigation.navigate('TicketActivityStack', {
                              screen: 'Activity',
                              params: {
                                activityId: activity.id,
                                ticketId: activity.ticketId,
                                documentId: activity.ticketDocumentId,
                                activityCode: activity.code,
                                start: true,
                                ticketCode,
                              },
                            })
                          }
                        : undefined,
                    disableBottomBorder: isLast ? false : true,
                    disableTopBorder: index === 0 ? false : true,
                    style: { marginBottom: 0 },
                    ...appUtils.getCardButtonColor(!openTimes && activityEditable, theme),
                  },
                ]
              : [],
          style: { ...Platform.select({ web: { height: '100%' } }) },
        }}>
        {loading === false ? (
          <Pressable
            style={styles.pressable}
            containerStyle={styles.pressableContainer}
            spacing={['vertical', 'right']}
            onPress={() =>
              onlyRead
                ? navigation.navigate('TicketActivityStack', {
                    screen: 'ActivityPast',
                    params: {
                      activityId: activity.id,
                      ticketId: activity.ticketId,
                      documentId: activity.ticketDocumentId,
                      activityCode: activity.code,
                      start: false,
                      ticketCode,
                    },
                  })
                : navigation.navigate('TicketActivityStack', {
                    screen: 'Activity',
                    params: {
                      activityId: activity.id,
                      ticketId: activity.ticketId,
                      documentId: activity.ticketDocumentId,
                      activityCode: activity.code,
                      start: false,
                      ticketCode,
                    },
                  })
            }>
            <IM.View style={IMLayout.flex.f1}>
              {isSmallDevice ? (
                <>
                  <IM.View style={IMLayout.flex.row}>
                    <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
                      {activityIcon()}
                      <IM.View style={IMLayout.flex.f1}>
                        {activityName()}
                        {stateBadge()}
                      </IM.View>
                    </IM.View>
                    <NavigationIcon />
                  </IM.View>
                  {employeeBadge()}
                  {activityDescription()}
                </>
              ) : (
                <DynamicView style={[IMLayout.flex.f1, styles.justify]} forceLayout={forceLayout}>
                  <IM.View style={[IMLayout.flex.f2, styles.justify, { marginRight: IMLayout.horizontalMargin }]}>{activityName()}</IM.View>
                  <IM.View style={[IMLayout.flex.f2, styles.justify, { marginRight: IMLayout.horizontalMargin }]}>{employeeBadge()}</IM.View>
                  <IM.View style={[styles.justify, { marginHorizontal: 2 * IMLayout.horizontalMargin }]}>{activityIcon()}</IM.View>
                  {/* eslint-disable-next-line react-native/no-inline-styles */}
                  <IM.View style={[styles.justify, { marginRight: 2 * IMLayout.horizontalMargin, width: 120 }]}>{stateBadge()}</IM.View>
                  <IM.View style={[IMLayout.flex.f4, styles.justify, { marginRight: IMLayout.horizontalMargin }]}>{activityDescription()}</IM.View>
                  <NavigationIcon style={styles.justify} />
                </DynamicView>
              )}
            </IM.View>
          </Pressable>
        ) : (
          <IM.View style={styles.skeletonContainer}>{loading === 'catched' ? <Error /> : <SkeletonCard />}</IM.View>
        )}
        {isLast && disableLastSeparator !== true && <Separator spacing="none" />}
      </IM.Card>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  align: { alignItems: 'center' },
  container: { marginLeft: 8, flex: 1 },
  justify: { justifyContent: 'center' },
  pressable: { flexDirection: 'row', flex: 1 },
  pressableContainer: { height: '100%' },
  skeletonContainer: { marginHorizontal: 6, marginVertical: 8 },
})
