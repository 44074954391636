import { IMLayout, SpacingProps, useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, SectionListRenderItemInfo, StyleProp, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Destination, Ticket } from '../../apis/types/apiResponseTypes'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import CONSTANTS from '../../constants/Constants'
import { EDIT_DESTINATION_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_CUSTOMER_DESTINATIONS, REQUEST_POST_DESTINATION_ID_CARD } from '../../constants/Keys'
import DestinationEditOrCreateModal from '../../modals/customer/DestinationEditOrCreateModal'
import ScreenSelectModal from '../../modals/ScreenSelectModal'
import { TicketStackParamList } from '../../navigation/types'
import { DestinationEditOrCreateParams, ListSection } from '../../types'
import CustomerDestinationCard from './CustomerDestinationCard'

type Props = {
  ticketId: string
  customerId: string
  addressId: string | undefined
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  changeable?: boolean
  onChangedAddressId: (addressId: string | undefined) => void
}

export default function CustomerDestinationIdCard({ ticketId, addressId, customerId, spacing, changeable, style, onChangedAddressId }: Props) {
  const cardElemLoaded = useRef(false)
  const newAddressId = useRef(addressId)

  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const editOrCreateModalController = useModalController<DestinationEditOrCreateParams>()

  const { item, loadItem, allDataLoaded, loadMore, loading } = useInfiniteLoader(api.getCustomerDestinations, {
    chuckSize: CONSTANTS.destinationsChuckSize,
    id: REQUEST_CUSTOMER_DESTINATIONS,
  })
  const { request, loading: loadingEdit } = useRequest(api.editTicket, { id: REQUEST_POST_DESTINATION_ID_CARD })

  const noSelectionEntry: Destination = {
    id: CONSTANTS.noSelectionId,
    customerId: '',
    inactive: false,
    description: i18n.t('NO_SELECTION'),
  }

  const [error, setError] = useState(false)
  const [search, setSearch] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [destination, setDestination] = useState<Destination | undefined>(undefined)

  useEvent({ key: EDIT_DESTINATION_DATA_EVENT_KEY }, () => {
    refresh(undefined, true)
  })

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    loadingEdit === 'catched' && setError(true)
    loadingEdit === false && onChangedAddressId(newAddressId.current === CONSTANTS.noSelectionId ? undefined : newAddressId.current)
  }, [loadingEdit])

  useEffect(() => {
    if (!addressId) cardElemLoaded.current = true

    if (cardElemLoaded.current === false) {
      if (loading === false) {
        cardElemLoaded.current = true
        setDestination(item.find(el => el.id === addressId))
      }
    }
  }, [loading, item])

  const refresh = (searchStr?: string, refreshState = false) => {
    if (isVisible) {
      loadItem({ id: undefined, customerId: customerId, searchtext: searchStr })
    } else {
      if (refreshState) cardElemLoaded.current = false
      addressId && loadItem({ id: addressId, customerId: customerId, searchtext: searchStr })
    }
  }

  const renderItem = ({ item: renderDestination }: SectionListRenderItemInfo<Destination, ListSection<Destination>>, onPress?: () => void) => {
    const isFirst = item && item.length > 0 && renderDestination.id === noSelectionEntry.id
    const isLast = item && item.length > 0 && renderDestination.id === item[item.length - 1].id

    return (
      <CustomerDestinationCard
        destination={renderDestination}
        onPress={onPress}
        hideButtons
        selected={error ? false : destination === undefined ? renderDestination.id === noSelectionEntry.id : renderDestination.id === destination.id}
        spacing={['horizontal', isFirst ? 'vertical' : isLast ? 'none' : 'bottom']}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginBottom: isLast ? 3 * IMLayout.verticalMargin : 0 }}
      />
    )
  }

  const handleChangeContact = (newDestination: Destination | undefined) => {
    if (!newDestination) return

    newAddressId.current = newDestination.id
    setError(false)
    request({ id: ticketId, shippingAddressId: newDestination.id === noSelectionEntry.id ? '' : newDestination.id } as Ticket)
    setDestination(newDestination.id === noSelectionEntry.id ? undefined : newDestination)
  }

  return (
    <>
      <CustomerDestinationCard
        destination={destination}
        leftText={i18n.t('DESTINATION')}
        disableIcon
        enableChange={changeable}
        disabled={destination === undefined || destination.id === noSelectionEntry.id}
        loading={loadingEdit === false ? !isVisible && addressId !== undefined && loading : loadingEdit}
        onChange={() => setIsVisible(true)}
        onPress={
          destination !== undefined
            ? () => {
                const data: DestinationEditOrCreateParams = { destination: destination, customerId: destination.customerId }

                isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                  ? navigation.navigate('TicketCustomerStack', { screen: 'CustomerDetailDestinationEditOrCreate', params: data })
                  : editOrCreateModalController.show(data)
              }
            : undefined
        }
        style={style}
        spacing={spacing}
      />
      <ScreenSelectModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        loading={loading}
        screenTitle={i18n.t('DESTINATIONS')}
        noDataMessage={i18n.t('NO_DESTINATION_FOUND')}
        data={search === '' ? [noSelectionEntry, ...item] : item}
        allDataLoaded={allDataLoaded}
        deferredTimeout={CONSTANTS.searchDeferredTimeout}
        onSearchChange={text => {
          setSearch(text)
          refresh(text)
        }}
        onLoadMore={loadMore}
        onChange={handleChangeContact}
        refresh={refresh}
        renderItem={renderItem}
      />
      <DestinationEditOrCreateModal controller={editOrCreateModalController} />
    </>
  )
}
